var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ldBlueprint, initLDLocalState, generateItptFromCompInfo, gdsfpLD, VisualKeysDict } from '@metaexplorer/core';
import { Component } from 'react';
import { GoogleWebAuthAPI, gwaTestCfg, EVENT_GOOGLE_WEB_AUTH } from '../apis/GoogleWebAuthAPI';
import React from 'react';
export const GoogleWebAuthenticatorName = "google-api/WebAuthenticator";
let allMyInputKeys = [];
let ownKVLs = [];
export const GWebAuthenticatorCfg = {
    subItptOf: null,
    nameSelf: GoogleWebAuthenticatorName,
    canInterpretType: "http://my-domain.com/my-class",
    ownKVLs: ownKVLs,
    inKeys: allMyInputKeys,
    crudSkills: "cRUd"
};
let PureGWebAuthenticator = class PureGWebAuthenticator extends Component {
    constructor(props) {
        super(props);
        //generates child react components
        this.renderInputContainer = generateItptFromCompInfo.bind(this, VisualKeysDict.inputContainer);
        this.cfg = this.constructor["cfg"];
        const ldState = initLDLocalState(this.cfg, props, [], []);
        this.googleAPI = GoogleWebAuthAPI.getSingleton();
        this.googleAPI.addEventListener(EVENT_GOOGLE_WEB_AUTH, (event) => {
            this.setState({ ...this.state, googleState: this.googleAPI.getState() });
            if (event.newState === "signedIn") {
                //let test = new GSheetsRetriever();
            }
        });
        this.state = { ...ldState, googleState: this.googleAPI.getState() };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        let rvLD = gdsfpLD(nextProps, prevState, [], []);
        if (!rvLD) {
            return null;
        }
        let rvNew = { ...rvLD };
        return { ...prevState, ...rvNew };
    }
    render() {
        const { googleState } = this.state;
        const isLoggedIn = googleState.generalState === "signedIn";
        const isInitial = googleState.generalState === "initial";
        const canReSignIn = googleState.generalState === "notSignedIn";
        return React.createElement("div", null,
            React.createElement("h3", null, "authenticate to google"),
            canReSignIn ?
                React.createElement("button", { onClick: () => {
                        this.googleAPI.reSignIn();
                    } }, "sign in")
                : null,
            isInitial ?
                React.createElement("button", { onClick: () => {
                        this.googleAPI.initClient(gwaTestCfg);
                    } }, "init google Web API")
                : null,
            isLoggedIn ?
                React.createElement("button", { onClick: () => {
                        this.googleAPI.signOut();
                    } }, "sign out")
                : null);
    }
};
PureGWebAuthenticator = __decorate([
    ldBlueprint(GWebAuthenticatorCfg)
], PureGWebAuthenticator);
export { PureGWebAuthenticator };
