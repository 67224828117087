var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ldBlueprint, NavBarInputContainerBpCfg, AbstractNavBarInputContainer, classNamesLD, VisualKeysDict } from '@metaexplorer/core';
import { AppBar, Toolbar } from '@material-ui/core';
import React from 'react';
let MDNavBarInputContainer = class MDNavBarInputContainer extends AbstractNavBarInputContainer {
    render() {
        const { compInfos, localValues } = this.state;
        const hasPrimaryContent = compInfos.has(VisualKeysDict.primaryItpt);
        return React.createElement(React.Fragment, null,
            React.createElement(AppBar, { position: "static", className: classNamesLD(null, localValues) },
                React.createElement(Toolbar, null, hasPrimaryContent
                    ? React.createElement(React.Fragment, null, this.renderSub(VisualKeysDict.primaryItpt))
                    : null)),
            React.createElement("div", { className: "bottom-nav-topfree mdscrollbar" }, this.renderSub(VisualKeysDict.inputContainer)));
        /*<>
            <AppBar className={classNamesLD("full-navbar", localValues)}>
                <Navigation type='horizontal'>
                    {hasPrimaryContent
                        ? <>{this.renderSub(VisualKeysDict.primaryItpt)}</>
                        : null}
                </Navigation>
            </AppBar>
            <div className="bottom-nav-topfree mdscrollbar">
                {this.renderSub(VisualKeysDict.inputContainer)}
            </div>
        </>;*/
    }
};
MDNavBarInputContainer = __decorate([
    ldBlueprint(NavBarInputContainerBpCfg)
], MDNavBarInputContainer);
export { MDNavBarInputContainer };
