var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ldBlueprint } from '../../ldaccess/ldBlueprint';
import { UserDefDict } from '../../ldaccess/UserDefDict';
import { VisualKeysDict } from '../visualcomposition/visualDict';
import { initLDLocalState, generateItptFromCompInfo, gdsfpLD } from '../generic/generatorFns';
import { Component } from 'react';
import { LDDict } from '../../ldaccess/LDDict';
import { isReactComponent } from '../../components/reactUtils/reactUtilFns';
import React from 'react';
export const CSSWrapperName = "metaexplorer.io/layout/CSSWrapper";
let cfgIntrprtKeys = [VisualKeysDict.inputContainer, VisualKeysDict.cssClassName];
let ownKVLs = [
    {
        key: VisualKeysDict.inputContainer,
        value: undefined,
        ldType: UserDefDict.intrprtrClassType
    },
    {
        key: VisualKeysDict.cssClassName,
        value: undefined,
        ldType: LDDict.Text
    }
];
let bpCfg = {
    subItptOf: null,
    nameSelf: CSSWrapperName,
    ownKVLs: ownKVLs,
    inKeys: cfgIntrprtKeys,
    crudSkills: "cRud"
};
let PureCSSWrapper = class PureCSSWrapper extends Component {
    constructor(props) {
        super(props);
        this.renderInputContainer = generateItptFromCompInfo.bind(this, VisualKeysDict.inputContainer);
        this.cfg = this.constructor["cfg"];
        const ldState = initLDLocalState(this.cfg, props, [VisualKeysDict.inputContainer], [VisualKeysDict.cssClassName], [false], [true]);
        this.state = {
            ...ldState,
        };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        let rvLD = gdsfpLD(nextProps, prevState, [VisualKeysDict.inputContainer], [VisualKeysDict.cssClassName], null, [false], [true]);
        if (!rvLD) {
            return null;
        }
        let rvNew = { ...rvLD };
        return {
            ...rvNew
        };
    }
    render() {
        const { localValues, compInfos } = this.state;
        const cssClassNames = localValues.get(VisualKeysDict.cssClassName);
        if (!compInfos.get(VisualKeysDict.inputContainer) && !!cssClassNames) {
            const cssClassStrings = cssClassNames.join(" ");
            return React.createElement("div", { className: cssClassStrings });
        }
        let renderFreeResult = this.renderInputContainer();
        if (isReactComponent(renderFreeResult)) {
            if (!!renderFreeResult && !!cssClassNames /*&& renderFreeResult.hasOwnProperty("className")*/) {
                /*renderFreeResult["className"] = cssClassName;*/
                const cssClassStrings = cssClassNames.join(" ");
                return React.createElement("div", { className: cssClassStrings }, renderFreeResult);
            }
        }
        return React.createElement(React.Fragment, null, renderFreeResult);
    }
};
PureCSSWrapper = __decorate([
    ldBlueprint(bpCfg)
], PureCSSWrapper);
export { PureCSSWrapper };
