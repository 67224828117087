import { DefaultPortLabel } from "@projectstorm/react-diagrams";
import { map } from "lodash";
// import { DECLARATION_MODEL } from "../node-editor-consts";
import React from "react";
/**
 * @author Jonathan Schneider
 */
export class DeclarationNodeWidget extends React.Component {
    constructor(props) {
        super(
        // DECLARATION_MODEL,
        props);
        this.state = {};
    }
    generatePort(port) {
        return React.createElement(DefaultPortLabel, { engine: this.props.engine, port: port, key: port.getID() });
        //return <GeneralDataTypePortSelector model={port} key={port.id} />;
    }
    render() {
        const className = `basic-node ${this.props.node.isSelected() ? 'selected' : ''}`;
        return (React.createElement("div", { className: className, style: { background: this.props.node.getColor() } },
            React.createElement("div", { className: "title" },
                React.createElement("div", { className: "name" }, this.props.node.getNameSelf())),
            React.createElement("div", { className: "ports" },
                React.createElement("div", { className: "in" }, map(this.props.node.getInPorts(), this.generatePort.bind(this))),
                React.createElement("div", { className: "out" }, map(this.props.node.getOutPorts(), this.generatePort.bind(this))))));
    }
}
