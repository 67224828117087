var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ldBlueprint, TopNavW5ChoicesBpCfg, classNamesLD } from "@metaexplorer/core";
import { MDBottomNavigation } from "./MDBottomNavigation";
import { Route } from "react-router";
import { BottomNavigation } from "@material-ui/core";
import React from "react";
let MDTopNavigation = class MDTopNavigation extends MDBottomNavigation {
    render() {
        const { numTabs, isGenerateAtPositions, localValues, iconEnabledURLs, iconDisabledURLs, labels, routes, tabIdx } = this.state;
        let tabs = [];
        let cleanedTabIdx = tabIdx;
        for (let idx = 0; idx < numTabs; idx++) {
            const isGen = isGenerateAtPositions[idx];
            if (!isGen) {
                cleanedTabIdx++;
                continue;
            }
            let newTab = this.generateTab(iconEnabledURLs[idx], iconDisabledURLs[idx], routes[idx], cleanedTabIdx === idx, "t-" + idx, labels[idx]);
            tabs.push(newTab);
        }
        return React.createElement("div", { className: classNamesLD("top-nav", localValues) },
            React.createElement(BottomNavigation, { value: tabIdx, onChange: (event, newValue) => {
                    this.onTabChanged(newValue);
                }, showLabels: true }, tabs),
            React.createElement("div", { className: "top-nav-bottomfree mdscrollbar" },
                tabs.length > 0 ? this.generateRedirect(tabIdx) : null,
                React.createElement(Route, { component: this.renderInputContainer }),
                this.props.children));
    }
};
MDTopNavigation = __decorate([
    ldBlueprint(TopNavW5ChoicesBpCfg)
], MDTopNavigation);
export { MDTopNavigation };
