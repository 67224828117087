import { connect } from "react-redux";
import { DefaultItptRetriever, DEFAULT_ITPT_RETRIEVER_NAME } from "../defaults/DefaultItptRetriever";
import { mapStateToProps, mapDispatchToProps } from "../appstate/reduxFns";
export class ReduxItptRetriever extends DefaultItptRetriever {
    constructor(name = DEFAULT_ITPT_RETRIEVER_NAME) {
        super(name);
        //maps by nameSelf of the Itpt
        this.connectedItpts = new Map();
    }
    searchForObjItpt(term, crudSkills) {
        let searchResult = super.searchForObjItpt(term, crudSkills);
        if (searchResult) {
            return this.connectedItpts.get(searchResult.cfg.nameSelf);
        }
        return null;
    }
    searchForObjItptAndDerive(term, crudSkills, newLDTokenStr) {
        let prevItpt = this.getDerivedItpt(newLDTokenStr);
        let searchResult = super.searchForObjItpt(term, crudSkills);
        if (searchResult && (!prevItpt || prevItpt.cfg.nameSelf !== searchResult.cfg.nameSelf)) {
            this.setDerivedItpt(newLDTokenStr, searchResult);
        }
        return null;
    }
    searchForKVItpt(term, crudSkills) {
        throw new Error("Method not implemented.");
    }
    addItpt(typeName, intrprtr, crudSkills, tags) {
        super.addItpt(typeName, intrprtr, crudSkills, tags);
        let intrprtrAsLDBP = intrprtr;
        let nameSelf = intrprtrAsLDBP.cfg.nameSelf;
        let connItpt = connect(mapStateToProps, mapDispatchToProps)(intrprtr);
        this.connectedItpts.set(nameSelf, connItpt);
    }
    setDerivedItpt(ldTokenVal, itpt) {
        super.setDerivedItpt(ldTokenVal, itpt);
        if (itpt === null)
            return;
        let intrprtrAsLDBP = itpt;
        let nameSelf = intrprtrAsLDBP.cfg.nameSelf;
        let connItpt = connect(mapStateToProps, mapDispatchToProps)(itpt);
        this.connectedItpts.set(nameSelf, connItpt);
    }
    getDerivedItpt(ldTokenVal) {
        let searchResult = super.getDerivedItpt(ldTokenVal);
        if (searchResult) {
            return this.connectedItpts.get(searchResult.cfg.nameSelf);
        }
        return null;
    }
    getItptByNameSelf(nameSelf) {
        let searchResult = super.getItptByNameSelf(nameSelf);
        if (searchResult) {
            return this.connectedItpts.get(searchResult.cfg.nameSelf);
        }
        return null;
    }
    getItptList() {
        return super.getItptList();
    }
    /**
     * gets itpt that is not connected to Redux
     * @param nameSelf the nameSelf-property in the BlueprintConfig of the itpt
     */
    getUnconnectedByNameSelf(nameSelf) {
        let searchResult = super.getItptByNameSelf(nameSelf);
        if (searchResult) {
            return searchResult;
        }
        else {
            return null;
        }
    }
    hasConnectedByNameSelf(nameSelf) {
        return this.connectedItpts.has(nameSelf);
    }
}
