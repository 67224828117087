import { ActionsObservable, ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
export const IMG_UPLOAD_REQUEST = 'metaexplorer.io/IMG_UPLOAD_REQUEST';
export const IMG_UPLOAD_RESULT = 'metaexplorer.io/IMG_UPLOAD_RESULT';
export const IMG_UPLOAD_ERROR = 'metaexplorer.io/IMG_UPLOAD_ERROR';
//Action factories
export const uploadImgRequestAction = (fileList, targetUrl) => ({
    type: IMG_UPLOAD_REQUEST,
    imgUL: fileList,
    targetUrl: targetUrl
});
export const uploadImgResultAction = (imgULpayload) => ({
    type: IMG_UPLOAD_RESULT,
    imgULpayload
});
export const loadImgFailure = (message) => ({
    type: IMG_UPLOAD_ERROR,
    message
});
//for the loading-indicating part of the state
export const isUploadingImgReducer = function isUploadingImg(state = false, action) {
    switch (action.type) {
        case IMG_UPLOAD_REQUEST:
            return true;
        case IMG_UPLOAD_RESULT:
        case IMG_UPLOAD_ERROR:
            return false;
        default:
            return state;
    }
};
export const uploadImageEpic = (action$, store, { imgULAPI }) => {
    return action$.pipe(ofType(IMG_UPLOAD_REQUEST), mergeMap((action) => imgULAPI.postNewImage(action.imgUL, action.targetUrl)
        .map((response) => uploadImgResultAction(response))
        .catch((error) => ActionsObservable.of(loadImgFailure('An error occured during image uploading: ${error.message}')))));
};
