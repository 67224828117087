var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ldBlueprint } from '../ldaccess/ldBlueprint';
import { UserDefDict } from '../ldaccess/UserDefDict';
import { Component, Fragment } from 'react';
import { LDDict } from '../ldaccess/LDDict';
import { VisualKeysDict } from './visualcomposition/visualDict';
import { gdsfpLD, initLDLocalState, generateItptFromCompInfo } from './generic/generatorFns';
import React from 'react';
export const projCfgName = "ProjectConfiguration";
let cfgIntrprtKeys = [VisualKeysDict.inputContainer, UserDefDict.configItpt];
let inKeys = [...cfgIntrprtKeys, VisualKeysDict.cssClassName];
let ownKVLs = [
    {
        key: VisualKeysDict.inputContainer,
        value: undefined,
        ldType: UserDefDict.intrprtrClassType
    },
    {
        key: UserDefDict.configItpt,
        value: undefined,
        ldType: UserDefDict.intrprtrClassType
    },
    {
        key: VisualKeysDict.cssClassName,
        value: undefined,
        ldType: LDDict.Text
    },
];
let bpCfg = {
    subItptOf: null,
    nameSelf: projCfgName,
    ownKVLs: ownKVLs,
    inKeys: inKeys,
    crudSkills: "cRud"
};
let PureProjectConfig = class PureProjectConfig extends Component {
    constructor(props) {
        super(props);
        this.renderInputContainer = generateItptFromCompInfo.bind(this);
        this.cfg = this.constructor["cfg"];
        const ldState = initLDLocalState(this.cfg, props, [VisualKeysDict.inputContainer], [UserDefDict.configItpt, VisualKeysDict.cssClassName]);
        this.state = {
            ...ldState,
        };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        let rvLD = gdsfpLD(nextProps, prevState, [VisualKeysDict.inputContainer], [UserDefDict.configItpt, VisualKeysDict.cssClassName], null, [true]);
        if (!rvLD) {
            return null;
        }
        let rvNew = { ...rvLD };
        return {
            ...rvNew
        };
    }
    render() {
        //let renderFreeResult: JSX.Element = this.renderInputContainer();
        let listSections = [];
        const sectionElems = this.state.compInfos.get(VisualKeysDict.inputContainer);
        const { routes } = this.props;
        if (Array.isArray(sectionElems)) {
            sectionElems.forEach((elem, displayIdx) => {
                listSections.push(this.renderInputContainer(VisualKeysDict.inputContainer, routes, displayIdx));
            });
        }
        else {
            listSections.push(this.renderInputContainer(VisualKeysDict.inputContainer, routes));
        }
        if (!listSections || listSections.length === 0)
            return null;
        const cssClassName = this.state.localValues.get(VisualKeysDict.cssClassName);
        if (!!cssClassName) {
            return React.createElement("div", { className: cssClassName }, listSections.map((listSection, idx) => {
                if (!listSection)
                    return null;
                return React.createElement(Fragment, { key: "frag" + idx }, listSection);
            }));
        }
        return React.createElement(React.Fragment, null, listSections.map((listSection, idx) => {
            if (!listSection)
                return null;
            return React.createElement(Fragment, { key: "frag" + idx }, listSection);
        }));
    }
};
PureProjectConfig = __decorate([
    ldBlueprint(bpCfg)
], PureProjectConfig);
export { PureProjectConfig };
