var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ldBlueprint, NavProcessAtomBpCfg, AbstractNavProcessAtom, classNamesLD, VisualKeysDict, generateItptFromCompInfo } from '@metaexplorer/core';
import { AppBar, Toolbar, IconButton, Typography, Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React from 'react';
let MDNavProcessAtom = class MDNavProcessAtom extends AbstractNavProcessAtom {
    constructor() {
        super(...arguments);
        this.renderSub = generateItptFromCompInfo.bind(this);
    }
    renderCore(headerText, cancelTxt, confirmTxt, isHideBottom) {
        const { localValues } = this.state;
        return React.createElement("div", { className: "bottom-nav" },
            React.createElement(AppBar, { position: "static", className: classNamesLD(null, localValues) },
                React.createElement(Toolbar, null,
                    React.createElement(IconButton, { edge: "start", color: "inherit", "aria-label": headerText ? headerText : "cancel", onClick: () => this.onCancelClick() },
                        React.createElement(ArrowBackIcon, null)),
                    React.createElement(Typography, { variant: "h6" }, headerText ? headerText : "cancel"))),
            React.createElement("div", { className: "bottom-nav-topfree mdscrollbar" }, this.renderSub(VisualKeysDict.inputContainer)),
            isHideBottom ? null :
                React.createElement("div", { className: "bottom-nav-tabs flex-container" },
                    React.createElement(Button, { className: "flex-filler", onClick: () => this.onCancelClick() }, cancelTxt ? cancelTxt : "cancel"),
                    React.createElement(Button, { className: "flex-filler", onClick: () => this.onConfirmClick() }, confirmTxt ? confirmTxt : "confirm")));
    }
};
MDNavProcessAtom = __decorate([
    ldBlueprint(NavProcessAtomBpCfg)
], MDNavProcessAtom);
export { MDNavProcessAtom };
