import { DefaultPortLabel } from "@projectstorm/react-diagrams";
import { Component, createFactory } from "react";
import { map } from "lodash";
import React from "react";
import { ITPTSummary } from "../../panels/ITPTSummary";
export class OutputInfoNodeWidget extends Component {
    generatePort(port) {
        return React.createElement(DefaultPortLabel, { engine: this.props.engine, port: port, key: port.getID() });
    }
    render() {
        const { node } = this.props;
        const projectName = node.getItptProjName();
        const userName = node.getItptUserName();
        const blockName = node.getItptBlockName();
        const nameObj = {
            blockName,
            concatTitle: '',
            projectName,
            userName
        };
        const className = `basic-node ${this.props.node.isSelected() ? 'selected' : ''}`;
        return (React.createElement("div", { className: className, style: { background: node.getColor() } },
            React.createElement("div", { className: "title" },
                React.createElement("div", { className: "name" }, node.getNameSelf())),
            React.createElement("div", { className: "onboarding-form ports" },
                React.createElement("div", { className: "dense-form" },
                    React.createElement("div", { style: { marginLeft: "-2em" }, className: "in" }, map(node.getInPorts(), this.generatePort.bind(this))),
                    React.createElement(ITPTSummary, Object.assign({}, nameObj))))));
    }
}
export var OutputInfoNodeWidgetFactory = createFactory(OutputInfoNodeWidget);
