import { GeneralDataTypeNodeModel } from "./GeneralDataTypeNodeModel";
import { GENERALDATATYPE_MODEL } from "../node-editor-consts";
import { GeneralDataTypeNodeWidget } from "./GeneralDataTypeWidget";
import * as React from "react";
import { AbstractReactFactory } from "@projectstorm/react-canvas-core";
export class GeneralDataTypeNodeFactory extends AbstractReactFactory {
    constructor() {
        super(GENERALDATATYPE_MODEL);
    }
    getNewInstance() {
        return GeneralDataTypeNodeModel.fromVars();
    }
    generateReactWidget(event) {
        return React.createElement(GeneralDataTypeNodeWidget, { node: event.model, engine: this.engine });
    }
    generateModel(event) {
        return this.getNewInstance();
    }
}
