var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { UserDefDict } from "../../ldaccess/UserDefDict";
import { ldBlueprint } from "../../ldaccess/ldBlueprint";
import { Component } from "react";
import { connect } from "react-redux";
import { mapStateToProps, mapDispatchToProps } from "../../appstate/reduxFns";
import { ldOptionsDeepCopy, isObjPropertyRef } from "../../ldaccess/ldUtils";
import { isRouteSame } from "../reactUtils/compUtilFns";
import { appItptMatcherFn } from "../../appconfig/appItptMatcher";
import { linearLDTokenStr } from "../../ldaccess/ildtoken";
import { isReactComponent } from "../reactUtils/reactUtilFns";
import { LDError } from "../../appstate/LDError";
import React from "react";
export const COMP_BASE_CONTAINER = "metaexplorer.io/baseContainer";
let cfgType = UserDefDict.itptContainerObjType;
let cfgIntrprtKeys = [];
let ownKVLs = [];
let bpCfg = {
    subItptOf: null,
    canInterpretType: cfgType,
    nameSelf: COMP_BASE_CONTAINER,
    ownKVLs: ownKVLs,
    inKeys: cfgIntrprtKeys,
    crudSkills: "cRud"
};
let PureBaseContainerRewrite = class PureBaseContainerRewrite extends Component {
    constructor(props) {
        super(props);
        this.cfg = this.constructor["cfg"];
        this.state = {
            hasError: false,
            errorMsg: '',
            nameSelf: this.cfg.nameSelf,
            routes: null,
            compInfos: new Map(),
            localLDTypes: new Map(),
            localValues: new Map(),
            inKeys: this.cfg.inKeys
        };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        if (!nextProps.ldOptions || !nextProps.ldOptions.resource ||
            nextProps.ldOptions.resource.kvStores.length === 0)
            return null;
        const ldOptions = nextProps.ldOptions;
        if (ldOptions.isLoading)
            return null;
        let inKeys = prevState.inKeys;
        let ldTokenString = ldOptions.ldToken.get();
        let retriever = ldOptions.visualInfo.retriever;
        let interpretedBy = ldOptions.visualInfo.interpretedBy;
        // TODO: check if it can be simplified with gdsfpLD()
        let newreactCompInfos = new Map();
        let newLDTypes = new Map();
        let newValueMap = new Map();
        let isLDTypeSame = true;
        let isItptKey = false;
        let hasOutputKvMap = false;
        ldOptions.resource.kvStores.forEach((itm, idx, kvstores) => {
            if (!isObjPropertyRef(itm.value)) {
                newLDTypes.set(itm.key, itm.ldType);
                newValueMap.set(itm.key, itm.value);
            }
            if (inKeys.length > 0 && inKeys.findIndex((itptKey) => itptKey === itm.key) >= 0) {
                isItptKey = true;
                return;
            }
            if (itm.key === UserDefDict.outputKVMapKey) {
                hasOutputKvMap = true;
            }
            if (!isLDTypeSame)
                return;
            const prevStateLDType = prevState.localLDTypes.get(itm.key);
            if (prevStateLDType !== itm.ldType) {
                if (isObjPropertyRef(itm.value))
                    return;
                isLDTypeSame = false;
            }
        });
        if ((!interpretedBy
            || !isRouteSame(nextProps.routes, prevState.routes)
            || !isLDTypeSame)
            && !isItptKey && !(hasOutputKvMap && ldOptions.resource.kvStores.length === 1)) {
            //i.e. first time this ldOptions-Object gets interpreted, or itpt-change
            let newldOptions = ldOptionsDeepCopy(ldOptions);
            newldOptions.visualInfo.interpretedBy = prevState.nameSelf;
            nextProps.notifyLDOptionsLinearSplitChange(newldOptions);
            let routes = nextProps.routes;
            // if there's a reason to notify a state update, any old error shouldn't be displayed any more
            const hasError = false;
            const errorMsg = "";
            return { ...prevState, routes, localLDTypes: newLDTypes, localValues: newValueMap, hasError, errorMsg };
        }
        try {
            ldOptions.resource.kvStores.forEach((elem, idx) => {
                let elemKey = elem.key;
                if (elemKey === UserDefDict.outputKVMapKey) {
                    return;
                }
                let itpt = null;
                if (elem.ldType === UserDefDict.intrprtrClassType && elem.value && isObjPropertyRef(elem.value)) {
                    itpt = appItptMatcherFn().getItptRetriever(retriever).getDerivedItpt(elem.value.objRef);
                }
                else {
                    itpt = appItptMatcherFn().getItptRetriever(retriever).getDerivedItpt(linearLDTokenStr(ldTokenString, idx));
                }
                let newKey = elem.key; // "_" + idx;
                if (isReactComponent(itpt)) {
                    let ldTokenStringNew = linearLDTokenStr(ldTokenString, idx);
                    if (isItptKey && isObjPropertyRef(elem.value)) {
                        ldTokenStringNew = elem.value.objRef;
                    }
                    newreactCompInfos.set(newKey, { compClass: itpt, key: newKey, ldTokenString: ldTokenStringNew });
                    newLDTypes.set(newKey, itpt.cfg.canInterpretType);
                    newValueMap.set(newKey, elem.value);
                }
                else {
                    throw new LDError(`baseContainer got a non-visual component. It was looking for ${JSON.stringify(elem)} and got ${itpt}`);
                }
            });
        }
        catch (error) {
            const errorMsg = `${JSON.stringify(error)}`;
            return { ...prevState, hasError: true, errorMsg };
        }
        //compare localValues against each other and dispatch KVUpdate
        const changedValues = [];
        newValueMap.forEach((val, key) => {
            if (key !== UserDefDict.outputKVMapKey) {
                const prevHas = prevState.localValues.has(key);
                if (!prevHas || (prevHas && JSON.stringify(prevState.localValues.get(key)) !== JSON.stringify(val))) {
                    changedValues.push(key);
                }
            }
        });
        if (changedValues.length > 0) {
            const outputKvs = changedValues.map((key) => {
                return { key, value: newValueMap.get(key), ldType: newLDTypes.get(key) };
            });
            const newKVM = newValueMap.get(UserDefDict.outputKVMapKey);
            if (newKVM) {
                const outputKVMap = changedValues.reduce((prevVal, key) => {
                    if (newKVM[key]) {
                        prevVal[key] = newKVM[key];
                    }
                    return prevVal;
                }, {});
                nextProps.dispatchKvOutput(outputKvs, ldTokenString, outputKVMap);
            }
        }
        return { ...prevState, compInfos: newreactCompInfos, localValues: newValueMap, localLDTypes: newLDTypes };
    }
    componentDidCatch(error, info) {
        const errorMsg = `${JSON.stringify(info)}`;
        this.setState({ ...this.state, hasError: true, errorMsg });
    }
    render() {
        let { hasError, errorMsg } = this.state;
        if (!hasError) {
            let { routes } = this.props;
            routes = routes ? { ...routes } : null;
            let iterator = 0;
            let reactComps = [];
            this.state.compInfos.forEach((cInfoItmOrItms, key) => {
                let cInfoAsArray = [];
                if (Array.isArray(cInfoItmOrItms)) {
                    cInfoAsArray = cInfoItmOrItms;
                }
                else {
                    cInfoAsArray = [cInfoItmOrItms];
                }
                for (let i = 0; i < cInfoAsArray.length; i++) {
                    const cInfoItm = cInfoAsArray[i];
                    const GenericComp = cInfoItm.compClass;
                    reactComps[iterator] = React.createElement(GenericComp, { key: cInfoItm.key, routes: routes, ldTokenString: cInfoItm.ldTokenString });
                    iterator++;
                }
            });
            return React.createElement(React.Fragment, null, reactComps ? reactComps : null);
        }
        else {
            if (this.props.errorDisplay) {
                const GenericErrorDispl = this.props.errorDisplay;
                return React.createElement(GenericErrorDispl, { errorMsg: `${errorMsg}` });
            }
            return React.createElement("span", null,
                "error caught in baseContainer: ",
                `${errorMsg}`);
        }
    }
};
PureBaseContainerRewrite = __decorate([
    ldBlueprint(bpCfg)
], PureBaseContainerRewrite);
export { PureBaseContainerRewrite };
export const BaseContainerRewrite = connect(mapStateToProps, mapDispatchToProps)(PureBaseContainerRewrite);
