var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from 'react';
import { ldBlueprint, VisualKeysDict, initLDLocalState, gdsfpLD, LDDict } from '@metaexplorer/core';
import { Component } from 'react';
export const ImprintName = "metaexplorer.io/compliance/Imprint";
const schemaOrgName = "https://schema.org/name";
const schemaOrgAddressCountry = "https://schema.org/addressCountry";
const schemaOrgPostalCode = "https://schema.org/postalCode";
const schemaOrgAdressLocality = "https://schema.org/addressLocality";
const schemaOrgStreetAddress = "https://schema.org/streetAddress";
export const isImprintPersonNotOrganization = "isPersonNotOrganization";
let cfgIntrprtKeys = [VisualKeysDict.cssClassName, schemaOrgName, schemaOrgAddressCountry, schemaOrgPostalCode, schemaOrgAdressLocality, schemaOrgStreetAddress, isImprintPersonNotOrganization];
let ownKVLs = [
    {
        key: VisualKeysDict.cssClassName,
        value: undefined,
        ldType: LDDict.Text
    },
    {
        key: schemaOrgName,
        value: undefined,
        ldType: LDDict.Text
    },
    {
        key: schemaOrgAddressCountry,
        value: undefined,
        ldType: LDDict.Text
    },
    {
        key: schemaOrgPostalCode,
        value: undefined,
        ldType: LDDict.Text
    },
    {
        key: schemaOrgAdressLocality,
        value: undefined,
        ldType: LDDict.Text
    },
    {
        key: schemaOrgStreetAddress,
        value: undefined,
        ldType: LDDict.Text
    },
    {
        key: isImprintPersonNotOrganization,
        value: undefined,
        ldType: LDDict.Boolean
    },
];
let bpCfg = {
    subItptOf: null,
    nameSelf: ImprintName,
    ownKVLs: ownKVLs,
    inKeys: cfgIntrprtKeys,
    crudSkills: "cRud"
};
let PureImprint = class PureImprint extends Component {
    constructor(props) {
        super(props);
        this.cfg = this.constructor["cfg"];
        const ldState = initLDLocalState(this.cfg, props, [], cfgIntrprtKeys);
        this.state = {
            ...ldState,
        };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        let rvLD = gdsfpLD(nextProps, prevState, [], cfgIntrprtKeys);
        if (!rvLD) {
            return null;
        }
        let rvNew = { ...rvLD };
        return {
            ...rvNew
        };
    }
    render() {
        const { localValues } = this.state;
        const cssClassName = localValues.get(VisualKeysDict.cssClassName);
        const vschemaOrgName = localValues.get(schemaOrgName);
        const vschemaOrgAddressCountry = localValues.get(schemaOrgAddressCountry);
        const vschemaOrgPostalCode = localValues.get(schemaOrgPostalCode);
        const vschemaOrgAdressLocality = localValues.get(schemaOrgAdressLocality);
        const vschemaOrgStreetAddress = localValues.get(schemaOrgStreetAddress);
        return React.createElement("div", { className: cssClassName },
            React.createElement("p", null,
                React.createElement("span", { className: "imprint_imprint" }, "Imprint"),
                React.createElement("br", null),
                React.createElement("span", { className: "imprint_name" }, vschemaOrgName),
                React.createElement("br", null),
                React.createElement("span", { className: "imprint_street" }, vschemaOrgStreetAddress),
                React.createElement("br", null),
                React.createElement("span", { className: "imprint_zipcode" },
                    vschemaOrgPostalCode,
                    " "),
                React.createElement("span", { className: "imprint_locality" }, vschemaOrgAdressLocality),
                React.createElement("span", { className: "imprint_country" }, vschemaOrgAddressCountry)));
    }
};
PureImprint = __decorate([
    ldBlueprint(bpCfg)
], PureImprint);
export { PureImprint };
