import { LDDict } from '@metaexplorer/core';
import { Component } from 'react';
import React from 'react';
const baseDataTypes = [
    { value: LDDict.Boolean, label: 'Boolean', img: null },
    { value: LDDict.Integer, label: 'Integer', img: null },
    { value: LDDict.Double, label: 'Number', img: null },
    { value: LDDict.Text, label: 'Text', img: null },
    { value: LDDict.Date, label: 'Date', img: null },
    { value: LDDict.DateTime, label: 'DateTime', img: null },
];
export class BaseDataTypeDropDown extends Component {
    constructor(props) {
        super(props);
        this.handleChange = (value) => {
            this.setState({ selectedDataType: value });
            this.props.selectionChange(value);
        };
        this.state = { selectedDataType: 'vlBoolean' };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.selection) {
            if (nextProps.selection === prevState.selectedDataType)
                return null;
            return { selectedDataType: nextProps.selection };
        }
        return null;
    }
    render() {
        //return <div>DropDown</div>;
        return React.createElement("select", { name: "bdatatypes", className: "editor-selectbdt", value: this.state.selectedDataType, onChange: (event) => this.handleChange(event.currentTarget.value) }, baseDataTypes.map((val, idx) => React.createElement("option", { key: `option-${idx}`, value: val.value }, val.label)));
        /*
                return (
                    <Dropdown className={this.props.className}
                        onChange={this.handleChange}
                        source={baseDataTypes}
                        value={this.state.selectedDataType}
                    />
                );*/
    }
}
