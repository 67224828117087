var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ldBlueprint } from "../../ldaccess/ldBlueprint";
import { UserDefDict } from "../../ldaccess/UserDefDict";
import { isReactComponent } from "../../components/reactUtils/reactUtilFns";
import { isRouteSame } from "../../components/reactUtils/compUtilFns";
import { refMapBaseTokenStr } from "../../ldaccess/ildtoken";
import { ldOptionsDeepCopy } from "../../ldaccess/ldUtils";
import { Component } from "react";
import { appItptMatcherFn } from "../../appconfig/appItptMatcher";
import React from "react";
let canInterpretType = UserDefDict.intrprtrBPCfgRefMapType;
let cfgIntrprtKeys = [];
let ownKVLs = [];
let bpCfg = {
    subItptOf: null,
    canInterpretType: canInterpretType,
    nameSelf: UserDefDict.intrprtrBPCfgRefMapName,
    ownKVLs: ownKVLs,
    inKeys: cfgIntrprtKeys,
    crudSkills: "cRud"
};
let PureRefMapItpt = class PureRefMapItpt extends Component {
    constructor(props) {
        super(props);
        this.cfg = this.constructor["cfg"];
        this.state = {
            errorMsg: '',
            hasError: false,
            routes: null,
            compInfos: new Map(),
            localLDTypes: new Map(),
            localValues: new Map(),
            cfg: this.cfg
        };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        if (!nextProps.ldOptions || !nextProps.ldOptions.resource ||
            nextProps.ldOptions.resource.kvStores.length === 0)
            return null;
        const ldOptions = nextProps.ldOptions;
        if (ldOptions.isLoading)
            return null;
        let ldTokenString = ldOptions.ldToken.get();
        let retriever = ldOptions.visualInfo.retriever;
        let newreactCompInfos = new Map();
        if (!isRouteSame(nextProps.routes, prevState.routes)
            ||
                !nextProps.ldOptions.visualInfo.interpretedBy) {
            let newLDOptions = ldOptionsDeepCopy(nextProps.ldOptions);
            newLDOptions.visualInfo.interpretedBy = prevState.cfg.nameSelf;
            nextProps.notifyLDOptionsRefMapSplitChange(newLDOptions, prevState.cfg);
            let routes = nextProps.routes;
            return { ...prevState, routes };
        }
        let baseRMTkStr = refMapBaseTokenStr(ldTokenString);
        let BaseComp = appItptMatcherFn().getItptRetriever(retriever).getDerivedItpt(baseRMTkStr);
        if (BaseComp === null || BaseComp === undefined) {
            console.error("ItptReferenceMapType-component: itpt null or undefined: " + baseRMTkStr);
            return null;
        }
        if (isReactComponent(BaseComp)) {
            newreactCompInfos.set(baseRMTkStr, { compClass: BaseComp, key: baseRMTkStr, ldTokenString: baseRMTkStr });
        }
        else {
            return null;
        }
        return { ...prevState, compInfos: newreactCompInfos };
    }
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, errorMsg: error };
    }
    buildIntrprtrJSX(ldOptions, routes) {
        let { ldTokenString } = this.props;
        let { retriever } = ldOptions.visualInfo;
        let baseRMTkStr = refMapBaseTokenStr(ldTokenString);
        let BaseComp = appItptMatcherFn().getItptRetriever(retriever).getDerivedItpt(baseRMTkStr);
        if (BaseComp === null || BaseComp === undefined) {
            console.error("ItptReferenceMapType-component: itpt null or undefined");
            return null;
        }
        if (isReactComponent(BaseComp)) {
            return React.createElement(BaseComp, { routes: routes, ldTokenString: baseRMTkStr });
        }
        else {
            return null;
        }
    }
    render() {
        let { hasError, errorMsg } = this.state;
        if (!hasError) {
            let { routes } = this.props;
            routes = routes ? { ...routes } : null;
            let iterator = 0;
            let reactComps = [];
            this.state.compInfos.forEach((cInfoItmOrItms, key) => {
                let cInfoAsArray = [];
                if (Array.isArray(cInfoItmOrItms)) {
                    cInfoAsArray = cInfoItmOrItms;
                }
                else {
                    cInfoAsArray = [cInfoItmOrItms];
                }
                for (let i = 0; i < cInfoAsArray.length; i++) {
                    const cInfoItm = cInfoAsArray[i];
                    let GenericComp = cInfoItm.compClass;
                    reactComps[iterator] = React.createElement(GenericComp, { key: cInfoItm.key, routes: routes, ldTokenString: cInfoItm.ldTokenString });
                    iterator++;
                }
            });
            return React.createElement(React.Fragment, null, reactComps ? reactComps : null);
        }
        else {
            return React.createElement("span", null,
                "error caught in RefMap: ",
                errorMsg);
        }
    }
};
PureRefMapItpt = __decorate([
    ldBlueprint(bpCfg)
], PureRefMapItpt);
export { PureRefMapItpt };
