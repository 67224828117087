var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { SideFXDict } from "./../sidefx/SideFXDict";
import { LDDict } from "./../ldaccess/LDDict";
import { ldBlueprint } from "./../ldaccess/ldBlueprint";
import { LDRetrieverSuper } from "./../sidefx/LDRetrieverSuper";
import { ldRetrCfgIntrprtKeys } from "./LDRetrieverSuper-rewrite";
export const organizationRetrieverName = "metaexplorer.io/organizationRetriever";
let ownKVLs = [
    {
        key: SideFXDict.srvURL,
        value: undefined,
        ldType: LDDict.Text
    },
    {
        key: SideFXDict.identifier,
        value: undefined,
        ldType: LDDict.Text
    },
    {
        key: LDDict.name,
        value: undefined,
        ldType: LDDict.Text
    },
    {
        key: LDDict.address,
        value: undefined,
        ldType: LDDict.Text
    },
    {
        key: LDDict.description,
        value: undefined,
        ldType: LDDict.Text
    },
    {
        key: LDDict.image,
        value: undefined,
        ldType: undefined
    }
];
let bpCfg = {
    subItptOf: null,
    nameSelf: organizationRetrieverName,
    ownKVLs: ownKVLs,
    inKeys: ldRetrCfgIntrprtKeys,
    crudSkills: "cRud"
};
let OrganizationRetriever = class OrganizationRetriever extends LDRetrieverSuper {
};
OrganizationRetriever = __decorate([
    ldBlueprint(bpCfg)
], OrganizationRetriever);
export { OrganizationRetriever };
