var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ldBlueprint, VisualKeysDict, AbstractCompactInfoListElement, CompactInfoListElementCfg } from "@metaexplorer/core";
import { ListItem, ListItemText } from "@material-ui/core";
import React from "react";
let MDCompactInfoListElement = class MDCompactInfoListElement extends AbstractCompactInfoListElement {
    render() {
        const { localValues, compInfos } = this.state;
        const leftIconItpt = compInfos.has(VisualKeysDict.primaryItpt) && compInfos.get(VisualKeysDict.primaryItpt)
            ? this.renderSub(VisualKeysDict.primaryItpt) : null;
        const rightIconItpt = compInfos.has(VisualKeysDict.secondaryItpt) && compInfos.get(VisualKeysDict.secondaryItpt)
            ? this.renderSub(VisualKeysDict.secondaryItpt) : null;
        return React.createElement(ListItem, null,
            leftIconItpt,
            React.createElement(ListItemText, { primary: localValues.get(VisualKeysDict.headerTxt), secondary: localValues.get(VisualKeysDict.subHeaderTxt) }),
            rightIconItpt);
    }
};
MDCompactInfoListElement = __decorate([
    ldBlueprint(CompactInfoListElementCfg)
], MDCompactInfoListElement);
export { MDCompactInfoListElement };
