var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ldBlueprint, AbstractNavBarWActions, NavBarWActionsBpCfg, VisualKeysDict, classNamesLD } from '@metaexplorer/core';
import { AppBar, Toolbar, IconButton, Typography, Popover, makeStyles } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SearchIcon from '@material-ui/icons/Search';
import { createStyles } from '@material-ui/styles';
import React from 'react';
const useStyles = makeStyles((theme) => createStyles({
    root: {},
    searchbtn: {
    //marginRight: theme.spacing(2),
    },
    popoverbtn: {
    //marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));
const StyledAppBar = (props) => {
    const classes = useStyles(props);
    const { localValues, routeSendCancel, headerText, routeSendSearch, hasPopOverContent, onCancelClick, onTriggerOpenRightMenu, onTriggerSearchBtnClick } = props;
    return (React.createElement(React.Fragment, null,
        React.createElement(AppBar, { position: "static", className: `${classNamesLD(null, localValues)} ${classes.root}` },
            React.createElement(Toolbar, null,
                routeSendCancel
                    ? React.createElement(IconButton, { edge: "start", color: "inherit", "aria-label": "Menu", onClick: () => onCancelClick() },
                        React.createElement(ArrowBackIcon, null))
                    : null,
                React.createElement(Typography, { variant: "h6", className: classes.title }, headerText ? headerText : "Menu"),
                routeSendSearch
                    ? React.createElement(IconButton, { className: classes.searchbtn, onClick: (evt) => onTriggerSearchBtnClick(evt) },
                        React.createElement(SearchIcon, null))
                    : null,
                hasPopOverContent
                    ? React.createElement(IconButton, { edge: "end", className: classes.popoverbtn, "aria-label": "additional actions", color: "inherit", onClick: (evt) => onTriggerOpenRightMenu(evt) },
                        React.createElement(MoreVertIcon, null)) // TODO: interpret font icon
                    : null))));
};
let MDNavBarWActions = class MDNavBarWActions extends AbstractNavBarWActions {
    constructor(props) {
        super(props, { anchor: null });
    }
    handleClose() {
        this.setState({ ...this.state, isRightMenuOpen: false, anchor: null });
    }
    onTriggerOpenRightMenu(evt) {
        this.setState({ ...this.state, isRightMenuOpen: true, anchor: evt.currentTarget });
    }
    renderCore() {
        const { localValues, isRightMenuOpen, anchor, compInfos } = this.state;
        const headerText = localValues.get(VisualKeysDict.headerTxt);
        const hasPopOverContent = compInfos.has(VisualKeysDict.popOverContent);
        const id = isRightMenuOpen ? 'simple-popover' : null;
        let routeSendCancel = localValues.get(VisualKeysDict.routeSend_cancel);
        const routeSendSearch = localValues.get(VisualKeysDict.routeSend_search);
        const styledAppBarProps = {
            localValues,
            routeSendCancel,
            headerText,
            routeSendSearch,
            hasPopOverContent,
            onCancelClick: this.onCancelClick.bind(this),
            onTriggerOpenRightMenu: this.onTriggerOpenRightMenu.bind(this),
            onTriggerSearchBtnClick: this.onAppBarSearchBtnClick.bind(this)
        };
        return React.createElement(React.Fragment, null,
            React.createElement(StyledAppBar, Object.assign({}, styledAppBarProps)),
            React.createElement("div", { className: "bottom-nav-topfree mdscrollbar" }, this.renderSub(VisualKeysDict.inputContainer)),
            React.createElement(Popover, { id: id, open: isRightMenuOpen, anchorEl: anchor, onClose: () => this.handleClose(), anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                }, transformOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                } },
                React.createElement("div", { className: "menu-pop-over" }, this.renderSub(VisualKeysDict.popOverContent))));
    }
};
MDNavBarWActions = __decorate([
    ldBlueprint(NavBarWActionsBpCfg)
], MDNavBarWActions);
export { MDNavBarWActions };
