import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { DeclarationNodeWidget } from "./DeclarationNodeWidget";
import { DECLARATION_MODEL } from "../node-editor-consts";
import { DeclarationPartNodeModel } from "./DeclarationNodeModel";
import React from "react";
export class DeclarationWidgetFactory extends AbstractReactFactory {
    constructor() {
        super(DECLARATION_MODEL);
    }
    generateReactWidget(event) {
        return React.createElement(DeclarationNodeWidget, { engine: this.engine, node: event.model });
    }
    generateModel(event) {
        return this.getNewInstance();
    }
    getNewInstance() {
        return DeclarationPartNodeModel.fromVars();
    }
}
