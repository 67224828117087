import { ItptNodeModel } from "../_super/ItptNodeModel";
import { GENERALDATATYPE_MODEL } from "../node-editor-consts";
export class GeneralDataTypeNodeModel extends ItptNodeModel {
    static fromVars(nameSelf = "Untitled", subItptOf = null, canInterpretType = "", color = "rgb(222,222,222)", isCompound) {
        return new this({
            nameSelf,
            subItptOf,
            canInterpretType,
            color,
            isCompound
        });
    }
    constructor(options) {
        options.type = GENERALDATATYPE_MODEL;
        super(options);
    }
    onExploreBtnClicked() {
        const newItptName = this.getSubItptOf();
        this.fireEvent({
            itptName: newItptName
        }, 'onTriggerExplore');
    }
}
