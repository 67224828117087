var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from 'react';
import { LDDict, VisualKeysDict, gdsfpLD, initLDLocalState, ldBlueprint } from '@metaexplorer/core';
import { Component } from 'react';
import { Menu, Item } from "react-gooey-nav";
export var GooeyNavName = "metaexplorer.io/GooeyNav";
export const centralIcon = "centralIcon";
let cfgIntrprtKeys = [centralIcon, VisualKeysDict.iconName];
let ownKVLs = [
    {
        key: centralIcon,
        value: undefined,
        ldType: LDDict.Text
    },
    {
        key: VisualKeysDict.iconName,
        value: undefined,
        ldType: LDDict.Text
    }
];
let bpCfg = {
    subItptOf: null,
    nameSelf: GooeyNavName,
    ownKVLs: ownKVLs,
    inKeys: cfgIntrprtKeys,
    crudSkills: "cRud"
};
let PureGooeyNav = class PureGooeyNav extends Component {
    //private renderSub = generateItptFromCompInfo.bind(this);
    constructor(props) {
        super(props);
        this.cfg = this.constructor["cfg"];
        this.state = {
            ...initLDLocalState(this.cfg, props, [], [centralIcon, VisualKeysDict.iconName], [], [false, true])
        };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        let rvLD = gdsfpLD(nextProps, prevState, [], [centralIcon, VisualKeysDict.iconName], null, [false, true]);
        if (!rvLD) {
            return null;
        }
        return {
            ...prevState, ...rvLD
        };
    }
    render() {
        // const { localValues } = this.state;
        //const centralIconTxt = localValues.get(centralIcon);
        //const iconNames: string[] = localValues.get(VisualKeysDict.iconName);
        /*return <div className="flex-container" style={{ flexDirection: "column-reverse" }}>
            <div className="flex-filler" style={{ minHeight: "300px" }}>
                {iconNames ? <Menu orientation="bottom" >
                    {
                        iconNames.map((iconName) => {
                            return <Item title="Cool!">
                                <FontIcon value={iconName} />
                            </Item>;
                        })
                    }
                </Menu>
                    : null*/
        return React.createElement(Menu, { orientation: "bottom" },
            React.createElement(Item, { title: "c" }, "EN"),
            React.createElement(Item, { title: "c" }, "DE"));
        /*
            <Item title="a">
                <FontIcon value="translate" />
            </Item>
            <Item title="b">
                <FontIcon value="language" />
            </Item>
            <Item title="c">
                <FontIcon value="中文" />
            </Item>
            */
    }
};
PureGooeyNav = __decorate([
    ldBlueprint(bpCfg)
], PureGooeyNav);
export { PureGooeyNav };
