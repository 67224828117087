var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from "react";
import { ldBlueprint, } from "../../ldaccess/ldBlueprint";
import { UserDefDict } from "../../ldaccess/UserDefDict";
import { initLDLocalState, gdsfpLD } from "../generic/generatorFns";
import { Component } from "react";
import { LDDict } from "../../ldaccess/LDDict";
import { VisualKeysDict } from "../visualcomposition";
import { cssClassNamePropFromLocalValues } from "../../GeneralUtils";
let cfgIntrprtKeys = [
    UserDefDict.inputData,
    VisualKeysDict.cssClassName,
];
let ownKVLs = [
    {
        key: UserDefDict.inputData,
        value: undefined,
        ldType: LDDict.Text,
    },
    {
        key: VisualKeysDict.cssClassName,
        value: undefined,
        ldType: LDDict.Text,
    },
];
export const createTextBasedBpCfg = (nameSelf) => {
    return {
        subItptOf: null,
        nameSelf: nameSelf,
        ownKVLs: ownKVLs,
        inKeys: cfgIntrprtKeys,
        crudSkills: "cRud",
        canInterpretType: LDDict.Text,
    };
};
export class PureTextBasedComponent extends Component {
    constructor(props) {
        super(props);
        this.cfg = this.constructor["cfg"];
        const ldState = initLDLocalState(this.cfg, props, [], [UserDefDict.inputData, VisualKeysDict.cssClassName], [], [true, true]);
        this.state = {
            ...ldState,
        };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        let rvLD = gdsfpLD(nextProps, prevState, [], [UserDefDict.inputData, VisualKeysDict.cssClassName], null, [], [true, true]);
        if (!rvLD) {
            return null;
        }
        let rvNew = { ...rvLD };
        return {
            ...rvNew,
        };
    }
    render() {
        const { localValues } = this.state;
        const singleTextValue = localValues.get(UserDefDict.inputData);
        return (React.createElement("div", Object.assign({}, cssClassNamePropFromLocalValues(localValues)), singleTextValue ? singleTextValue : null));
    }
}
export const H1TextComponentName = "metaexplorer.io/basichtml/h1";
let PureH1TextComponent = class PureH1TextComponent extends PureTextBasedComponent {
    render() {
        const { localValues } = this.state;
        const singleTextValue = localValues.get(UserDefDict.inputData);
        return (React.createElement("h1", Object.assign({}, cssClassNamePropFromLocalValues(localValues)), singleTextValue ? singleTextValue : null));
    }
};
PureH1TextComponent = __decorate([
    ldBlueprint(createTextBasedBpCfg(H1TextComponentName))
], PureH1TextComponent);
export { PureH1TextComponent };
export const H2TextComponentName = "metaexplorer.io/basichtml/h2";
let PureH2TextComponent = class PureH2TextComponent extends PureTextBasedComponent {
    render() {
        const { localValues } = this.state;
        const singleTextValue = localValues.get(UserDefDict.inputData);
        return (React.createElement("h2", Object.assign({}, cssClassNamePropFromLocalValues(localValues)), singleTextValue ? singleTextValue : null));
    }
};
PureH2TextComponent = __decorate([
    ldBlueprint(createTextBasedBpCfg(H2TextComponentName))
], PureH2TextComponent);
export { PureH2TextComponent };
export const H3TextComponentName = "metaexplorer.io/basichtml/h3";
let PureH3TextComponent = class PureH3TextComponent extends PureTextBasedComponent {
    render() {
        const { localValues } = this.state;
        const singleTextValue = localValues.get(UserDefDict.inputData);
        return (React.createElement("h3", Object.assign({}, cssClassNamePropFromLocalValues(localValues)), singleTextValue ? singleTextValue : null));
    }
};
PureH3TextComponent = __decorate([
    ldBlueprint(createTextBasedBpCfg(H3TextComponentName))
], PureH3TextComponent);
export { PureH3TextComponent };
export const H4TextComponentName = "metaexplorer.io/basichtml/h4";
let PureH4TextComponent = class PureH4TextComponent extends PureTextBasedComponent {
    render() {
        const { localValues } = this.state;
        const singleTextValue = localValues.get(UserDefDict.inputData);
        return (React.createElement("h4", Object.assign({}, cssClassNamePropFromLocalValues(localValues)), singleTextValue ? singleTextValue : null));
    }
};
PureH4TextComponent = __decorate([
    ldBlueprint(createTextBasedBpCfg(H4TextComponentName))
], PureH4TextComponent);
export { PureH4TextComponent };
export const SpanTextComponentName = "metaexplorer.io/basichtml/span";
let PureSpanTextComponent = class PureSpanTextComponent extends PureTextBasedComponent {
    render() {
        const { localValues } = this.state;
        const singleTextValue = localValues.get(UserDefDict.inputData);
        return (React.createElement("span", Object.assign({}, cssClassNamePropFromLocalValues(localValues)), singleTextValue ? singleTextValue : null));
    }
};
PureSpanTextComponent = __decorate([
    ldBlueprint(createTextBasedBpCfg(SpanTextComponentName))
], PureSpanTextComponent);
export { PureSpanTextComponent };
export const BoldTextComponentName = "metaexplorer.io/basichtml/bold";
let PureBoldTextComponent = class PureBoldTextComponent extends PureTextBasedComponent {
    render() {
        const { localValues } = this.state;
        const singleTextValue = localValues.get(UserDefDict.inputData);
        return (React.createElement("b", Object.assign({}, cssClassNamePropFromLocalValues(localValues)), singleTextValue ? singleTextValue : null));
    }
};
PureBoldTextComponent = __decorate([
    ldBlueprint(createTextBasedBpCfg(BoldTextComponentName))
], PureBoldTextComponent);
export { PureBoldTextComponent };
export const ItalicsTextComponentName = "metaexplorer.io/basichtml/italics";
let PureItalicsTextComponent = class PureItalicsTextComponent extends PureTextBasedComponent {
    render() {
        const { localValues } = this.state;
        const singleTextValue = localValues.get(UserDefDict.inputData);
        return (React.createElement("i", Object.assign({}, cssClassNamePropFromLocalValues(localValues)), singleTextValue ? singleTextValue : null));
    }
};
PureItalicsTextComponent = __decorate([
    ldBlueprint(createTextBasedBpCfg(ItalicsTextComponentName))
], PureItalicsTextComponent);
export { PureItalicsTextComponent };
export const ParagraphTextComponentName = "metaexplorer.io/basichtml/paragraph";
let PureParagraphTextComponent = class PureParagraphTextComponent extends PureTextBasedComponent {
    render() {
        const { localValues } = this.state;
        const singleTextValue = localValues.get(UserDefDict.inputData);
        return (React.createElement("p", Object.assign({}, cssClassNamePropFromLocalValues(localValues)), singleTextValue ? singleTextValue : null));
    }
};
PureParagraphTextComponent = __decorate([
    ldBlueprint(createTextBasedBpCfg(ParagraphTextComponentName))
], PureParagraphTextComponent);
export { PureParagraphTextComponent };
