import { useState } from "react";
import React from "react";
import { DragContainer } from "metaexplorer-react-components";
export const EditorTrayItem = (props) => {
    const trayCssClass = props.isOpen ? "editor-tray-item opened" : "editor-tray-item";
    const btnEditCssClass = props.isOpen ? "editor-btn editor-btn-edit opened" : "editor-btn editor-btn-edit";
    //const btnPreviewCssClass = props.isOpen ? "preview-iconbtn opened" : "preview-iconbtn";
    const renderContent = () => {
        return (React.createElement("div", { onClick: 
            //	props.isCompoundBlock ? 
            () => props.onClick(), className: trayCssClass },
            props.data.label,
            React.createElement("button", { className: btnEditCssClass, onClick: (e) => {
                    e.stopPropagation();
                    props.onEditBtnPress(props.data);
                } }, "edit")));
    };
    /*
                    <button className={btnPreviewCssClass} onClick={(e) => {
                    e.stopPropagation();
                    props.onTriggerPreview(props.data);
                }} >preview</button>
    */
    return (React.createElement(React.Fragment, null, renderContent()));
};
export const DraggableEditorTrayItem = (props) => {
    const [isTrayOpen, setIsTrayOpen] = useState(false);
    function handleClick() {
        setIsTrayOpen(props.isCompoundBlock ? !isTrayOpen : false);
        if (!props.isCompoundBlock)
            props.onTriggerPreview(props.data);
    }
    //assigns part of the props to properties of a sub-element https://stackoverflow.com/a/39333479/1149845
    const dragContainerProps = (({ className, data, id, isWithDragHandle, dragOrigin, sourceBhv, targetBhv, type }) => ({ className, data, id, isWithDragHandle, dragOrigin, sourceBhv, targetBhv, type }))(props);
    const editorTrayItemProps = (({ isCompoundBlock, data, onEditBtnPress, onTriggerPreview: onTriggerPreview, isOpen, onClick }) => ({ isCompoundBlock, data, onEditBtnPress, onTriggerPreview, isOpen, onClick }))(props);
    editorTrayItemProps.isOpen = isTrayOpen;
    if (editorTrayItemProps.onClick) {
        editorTrayItemProps.onClick = () => {
            handleClick();
            props.onClick();
        };
    }
    else {
        editorTrayItemProps.onClick = handleClick;
    }
    return (React.createElement(DragContainer, Object.assign({}, dragContainerProps),
        React.createElement(EditorTrayItem, Object.assign({}, editorTrayItemProps))));
};
