import { ldBlueprint } from "../../ldaccess/ldBlueprint";
import { UserDefDict } from "../../ldaccess/UserDefDict";
import { VisualTypesDict } from "../../components/visualcomposition/visualDict";
import { AbstractDataTransformer } from "../../datatransformation/abstractDataTransformer";
import { itptKeysFromInputKvs } from "../../ldaccess/ldUtils";
import { LDUIDict } from "../../ldaccess";
const transfOutputKey = UserDefDict.outputData;
const DATATYPE_ASSEMBLER_CFG = {
    subItptOf: null,
    nameSelf: LDUIDict.DataTypeAssembler,
    ownKVLs: [],
    inKeys: [],
    crudSkills: "cRud"
};
class AbstractDataTypeAssembler extends AbstractDataTransformer {
    /**
     * this function assembles input fields to a single flat new datatype
     * @param inputParams
     * @param outputKvStores
     */
    mappingFunction(inputParams, outputKvStores) {
        let rv = [];
        const newOutputObj = {};
        inputParams.forEach((val) => {
            newOutputObj[val.key] = val.value;
        });
        //output var
        let outputValArr = [newOutputObj];
        const transfOutputKV = outputKvStores.get(transfOutputKey);
        transfOutputKV.value = outputValArr;
        rv = [transfOutputKV];
        return rv;
    }
}
export const DataTypeDisassembler = ldBlueprint(DATATYPE_ASSEMBLER_CFG)(AbstractDataTypeAssembler);
export function flatDataTypeAssemblerFactory(inputKvStores, nameSelf, outputKey, outputType) {
    const ActionCompOutputKVs = [
        {
            key: outputKey ? outputKey : transfOutputKey,
            value: undefined,
            ldType: outputType ? outputType : VisualTypesDict.compactInfoElement
        }
    ];
    const ownKVLs = [
        ...inputKvStores,
        ...ActionCompOutputKVs
    ];
    const inKeys = itptKeysFromInputKvs(inputKvStores);
    let bpCfg = {
        subItptOf: null,
        nameSelf,
        ownKVLs,
        inKeys,
        crudSkills: "cRUd"
    };
    let DataTypeAssemblerExt = class extends AbstractDataTypeAssembler {
        constructor() {
            super(...arguments);
            this.itptKeys = inKeys;
            this.outputKvStores = ActionCompOutputKVs;
        }
    };
    return ldBlueprint(bpCfg)(DataTypeAssemblerExt);
}
