import { Component } from 'react';
import { LDDict } from '../../../ldaccess/LDDict';
import { UserDefDict } from '../../../ldaccess/UserDefDict';
import { initLDLocalState, gdsfpLD } from '../../generic/generatorFns';
import { createLDUINSUrl } from '../../../ldaccess/ldUtils';
// TODO: drop file anim: https://css-tricks.com/examples/DragAndDropFileUploading/
export var SingleAudioSelectorStateEnum;
(function (SingleAudioSelectorStateEnum) {
    SingleAudioSelectorStateEnum[SingleAudioSelectorStateEnum["isSelectInputType"] = 2] = "isSelectInputType";
    SingleAudioSelectorStateEnum[SingleAudioSelectorStateEnum["isDragging"] = 3] = "isDragging";
    SingleAudioSelectorStateEnum[SingleAudioSelectorStateEnum["isRecording"] = 4] = "isRecording";
    SingleAudioSelectorStateEnum[SingleAudioSelectorStateEnum["isPreviewing"] = 5] = "isPreviewing";
    SingleAudioSelectorStateEnum[SingleAudioSelectorStateEnum["isError"] = 6] = "isError";
})(SingleAudioSelectorStateEnum || (SingleAudioSelectorStateEnum = {}));
const SingleAudioSelectorName = "metaexplorer.io/core/SingleAudioSelector";
let cfgType = createLDUINSUrl(LDDict.CreateAction, LDDict.result, LDDict.AudioObject);
let cfgIntrprtKeys = [];
const RESULT_KV = {
    key: LDDict.result,
    value: undefined,
    ldType: LDDict.URL
};
let ownKVLs = [
    RESULT_KV
];
export let SingleAudioSelectorBpCfg = {
    subItptOf: null,
    canInterpretType: cfgType,
    nameSelf: SingleAudioSelectorName,
    ownKVLs: ownKVLs,
    inKeys: cfgIntrprtKeys,
    crudSkills: "cRud"
};
export class AbstractSingleAudioSelector extends Component {
    constructor(props) {
        super(props);
        // tslint:disable-next-line:variable-name
        this._isMounted = false;
        this.consumeLDOptions = (ldOptions) => {
            //
        };
        this.cfg = this.constructor["cfg"];
        const baseState = {
            curStep: SingleAudioSelectorStateEnum.isSelectInputType,
            isMicAvailable: false,
            previewURL: null
        };
        this.state = {
            ...baseState,
            ...initLDLocalState(this.cfg, props, [], [UserDefDict.outputKVMapKey])
        };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        let rvLD = gdsfpLD(nextProps, prevState, [], [UserDefDict.outputKVMapKey]);
        if (rvLD === null || rvLD === prevState)
            return null;
        return { ...prevState, ...rvLD };
    }
    /**
     * called when the URL/blob of the audio URL changes, dispatches KV-Output
     */
    onResultChanged(url) {
        const outputKV = { ...RESULT_KV, value: url };
        const outputKVMap = this.state.localValues.get(UserDefDict.outputKVMapKey);
        if (!outputKVMap)
            return;
        this.props.dispatchKvOutput([outputKV], this.props.ldTokenString, outputKVMap);
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidMount() {
        this._isMounted = true;
        if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
            this.setState({ ...this.state, isMicAvailable: false });
            return;
        }
        else {
            navigator.mediaDevices.enumerateDevices()
                .then((devices) => {
                let vidInputList = [];
                devices.forEach((device) => {
                    if (device.kind === "audioinput")
                        vidInputList.push(device);
                });
                if (this._isMounted) {
                    if (vidInputList.length === 0) {
                        this.setState({ ...this.state, isMicAvailable: false });
                    }
                    else {
                        this.setState({ ...this.state, isMicAvailable: true });
                    }
                }
            });
        }
    }
    setStateToError() {
        this.setState({ ...this.state, curStep: SingleAudioSelectorStateEnum.isError });
    }
    startDrag() {
        if (this.state.curStep !== SingleAudioSelectorStateEnum.isPreviewing) {
            this.setState({ ...this.state, curStep: SingleAudioSelectorStateEnum.isDragging });
        }
    }
    onDropSuccess(audioFile, previewURL) {
        this.setState({ ...this.state, curStep: SingleAudioSelectorStateEnum.isPreviewing, previewURL: previewURL });
        this.onResultChanged(previewURL);
    }
    onDropFailure() {
        if (this.state.curStep !== SingleAudioSelectorStateEnum.isPreviewing) {
            this.setState({ ...this.state, curStep: SingleAudioSelectorStateEnum.isSelectInputType });
        }
    }
    startMic() {
        this.setState({ ...this.state, curStep: SingleAudioSelectorStateEnum.isRecording });
    }
    /**
     * revokes the previewURL and sets state.previewURL to null, it does not change state.curStep
     */
    destroyPreview() {
        if (this.state.previewURL) {
            window.URL.revokeObjectURL(this.state.previewURL);
            this.setState({ ...this.state, previewURL: null });
        }
    }
    render() {
        throw new Error("Method not implemented in abstract class");
    }
}
