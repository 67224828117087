export const LDNONVIS_SET = 'metaexplorer.io/LDNONVIS_SET';
export const LDNONVIS_DELETE = 'metaexplorer.io/LDNONVIS_DELETE';
/**
 * sets a non-visual itpt. If one exists with that alias and the cfg is same it will keep the old one
 * @param alias the key/alias under which the itpt is saved
 * @param itpt the itpt-object
 */
export const ldNonVisSETAction = (alias, itpt) => ({ type: LDNONVIS_SET, alias, itpt });
export const ldNonVisDeleteAction = (alias) => ({ type: LDNONVIS_DELETE, alias });
export const ldNonVisMapReducer = (state = {}, action) => {
    let alias = action.alias;
    let newState;
    switch (action.type) {
        case LDNONVIS_SET:
            if (state[alias] && action.intrprtr.constructor["cfg"] === state[alias].constructor["cfg"])
                newState = state;
            else
                newState = { ...state, [alias]: action.intrprtr };
            break;
        case LDNONVIS_DELETE:
            newState = { ...state };
            delete newState[alias];
            break;
        default:
            newState = { ...state };
            break;
    } //Object.assign({}, state, { [alias]: newLDCfg });
    return newState;
};
