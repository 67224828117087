var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LDRetrieverSuperRewrite, itptKeysFromInputKvs, ldBlueprint, LDDict, UserDefDict, SideFXDict } from "@metaexplorer/core";
import { EditorGlobalsLoadApi } from "./EditorGlobalsAPI";
export const EditorGlobalsPersisterName = "metaexplorer.io/v1/server/EditorGlobalsPersister";
export const EditorGlobalsPersisterType = "metaexplorer.io/v1/server/EditorGlobalsPersisterType";
const TRIGGER = "trigger";
let inputKVStores = [
    {
        key: SideFXDict.srvURL,
        value: undefined,
        ldType: LDDict.URL
    },
    {
        key: UserDefDict.inputData,
        value: undefined,
        ldType: undefined
    },
    {
        key: TRIGGER,
        value: undefined,
        ldType: undefined
    }
];
let outputKVStores = [
    {
        key: UserDefDict.outputData,
        value: undefined,
        ldType: undefined
    },
];
let ownKVLs = [...inputKVStores, ...outputKVStores];
let inKeys = itptKeysFromInputKvs(inputKVStores);
let bpCfg = {
    subItptOf: null,
    nameSelf: EditorGlobalsPersisterName,
    ownKVLs: ownKVLs,
    inKeys,
    crudSkills: "cRud",
    canInterpretType: EditorGlobalsPersisterType
};
let EditorGlobalsPersister = class EditorGlobalsPersister extends LDRetrieverSuperRewrite {
    constructor(parameters) {
        super(parameters, inKeys);
        this.apiCallOverride = () => new Promise((resolve, reject) => {
            let srvUrl = this.state.localValues.get(SideFXDict.srvURL);
            srvUrl = srvUrl ? srvUrl : '/api/globals';
            const postBody = this.state.localValues.get(UserDefDict.inputData);
            const promise = EditorGlobalsLoadApi.getEditorGlobalsLoadApiSingleton().setGlobalsAt(srvUrl, postBody);
            promise().then((response) => resolve(this.wrapOutputKv(response))).catch((reason) => resolve(this.wrapOutputKv({
                status: 'error',
                message: reason,
                statusPayload: "error"
            })));
        });
    }
    evalDirtyInput() {
        let triggerVal = this.state.localValues.get(TRIGGER);
        if (triggerVal && JSON.stringify(triggerVal) !== "{}") {
            super.evalDirtyInput();
        }
    }
    wrapOutputKv(inputBody) {
        return {
            [UserDefDict.responseWrapperKey]: inputBody
        };
    }
};
EditorGlobalsPersister = __decorate([
    ldBlueprint(bpCfg)
], EditorGlobalsPersister);
export { EditorGlobalsPersister };
