import React from 'react';
import { LDDict } from '../../../ldaccess/LDDict';
import { UserDefDict } from '../../../ldaccess/UserDefDict';
import { VisualKeysDict, VisualTypesDict } from '../../visualcomposition/visualDict';
import { initLDLocalState, gdsfpLD } from '../../generic/generatorFns';
import { Redirect } from 'react-router';
import { Component } from 'react';
import { cleanRouteString } from '../../routing/route-helper-fns';
import { ActionKeysDict, ActionTypesDict } from '../../../components/actions/ActionDict';
export const NavProcessAtomName = "metaexplorer.io/material-design/NavProcessAtom";
let cfgIntrprtKeys = [
    VisualKeysDict.inputContainer,
    VisualKeysDict.headerTxt,
    VisualKeysDict.cancelTxt,
    VisualKeysDict.confirmTxt,
    VisualKeysDict.routeSend_cancel,
    VisualKeysDict.routeSend_confirm,
    ActionKeysDict.action_confirm,
    VisualKeysDict.cssClassName
];
let ownKVLs = [
    {
        key: VisualKeysDict.inputContainer,
        value: undefined,
        ldType: UserDefDict.intrprtrClassType
    },
    {
        key: VisualKeysDict.headerTxt,
        value: undefined,
        ldType: LDDict.Text
    },
    {
        key: VisualKeysDict.cancelTxt,
        value: undefined,
        ldType: LDDict.Text
    },
    {
        key: VisualKeysDict.confirmTxt,
        value: undefined,
        ldType: LDDict.Text
    },
    {
        key: VisualKeysDict.routeSend_cancel,
        value: undefined,
        ldType: VisualTypesDict.route_added,
    },
    {
        key: VisualKeysDict.routeSend_confirm,
        value: undefined,
        ldType: VisualTypesDict.route_added,
    },
    {
        key: ActionKeysDict.action_confirm,
        value: undefined,
        ldType: ActionTypesDict.metaExplorerAction
    },
    {
        key: VisualKeysDict.cssClassName,
        value: undefined,
        ldType: LDDict.Text
    }
];
export const NavProcessAtomBpCfg = {
    subItptOf: null,
    nameSelf: NavProcessAtomName,
    ownKVLs: ownKVLs,
    inKeys: cfgIntrprtKeys,
    crudSkills: "cRud"
};
export class AbstractNavProcessAtom extends Component {
    constructor(props) {
        super(props);
        this.onCancelClick = () => {
            this.setState({
                ...this.state,
                isDoRedirectCancel: true
            });
        };
        this.onConfirmClick = () => {
            let confirmAction = this.state.localValues.get(ActionKeysDict.action_confirm);
            if (confirmAction) {
                this.props.dispatchLdAction(confirmAction.ldId, confirmAction.ldType, confirmAction.payload);
            }
            this.setState({
                ...this.state,
                isDoRedirectConfirm: true
            });
        };
        this.cfg = this.constructor["cfg"];
        let navBarStatePart = {
            isDoRedirectCancel: false,
            isDoRedirectConfirm: false
        };
        this.state = {
            ...navBarStatePart,
            ...initLDLocalState(this.cfg, props, [VisualKeysDict.inputContainer], [
                VisualKeysDict.headerTxt,
                VisualKeysDict.cancelTxt,
                VisualKeysDict.confirmTxt,
                VisualKeysDict.routeSend_cancel,
                VisualKeysDict.routeSend_confirm,
                ActionKeysDict.action_confirm,
                VisualKeysDict.cssClassName
            ])
        };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        let rvLD = gdsfpLD(nextProps, prevState, [VisualKeysDict.inputContainer], [
            VisualKeysDict.headerTxt,
            VisualKeysDict.cancelTxt,
            VisualKeysDict.confirmTxt,
            VisualKeysDict.routeSend_cancel,
            VisualKeysDict.routeSend_confirm,
            ActionKeysDict.action_confirm,
            VisualKeysDict.cssClassName
        ]);
        if (!rvLD) {
            return null;
        }
        return {
            ...prevState, ...rvLD
        };
    }
    render() {
        const { isDoRedirectCancel, isDoRedirectConfirm, localValues } = this.state;
        let routeSendCancel = localValues.get(VisualKeysDict.routeSend_cancel);
        let routeSendConfirm = localValues.get(VisualKeysDict.routeSend_confirm);
        const cancelTxt = localValues.get(VisualKeysDict.cancelTxt);
        const confirmTxt = localValues.get(VisualKeysDict.confirmTxt);
        const headerText = localValues.get(VisualKeysDict.headerTxt);
        if (isDoRedirectCancel && routeSendCancel) {
            routeSendCancel = cleanRouteString(routeSendCancel, this.props.routes);
            return React.createElement(Redirect, { to: routeSendCancel });
        }
        if (isDoRedirectConfirm && routeSendConfirm) {
            routeSendConfirm = cleanRouteString(routeSendConfirm, this.props.routes);
            return React.createElement(Redirect, { to: routeSendConfirm });
        }
        const isHideBottom = !routeSendConfirm
            && !cancelTxt && !confirmTxt;
        return this.renderCore(headerText, cancelTxt, confirmTxt, isHideBottom);
    }
    renderCore(headerText, cancelTxt, confirmTxt, isHideBottom) {
        throw new Error("Method not implemented in abstract class");
    }
}
