import React from "react";
const NEWITPT_NODE_CLASS = "newitpt-node";
const TXT_CREATENEW = "create a new compound block:";
const TXT_USERNAME = "... with a user name";
const TXT_PROJECTNAME = "... a project name";
const TXT_BLOCKNAME = "... and a block Name";
const TXT_BTN_CREATE = "create";
const TXT_SET_ALL_FIELDS = "*all fields must be set";
const TXT_HEADING_PLACEHOLDER = "...";
const TXT_HEADING_BLOCK_PLACEHOLDER = "{block}";
const TXT_HEADING_PROJECT_PLACEHOLDER = "{project}";
const TXT_HEADING_USERNAME_PLACEHOLDER = "{user}";
export const NewItptNode = (props) => {
    const [validationMap, setValidationMap] = React.useState({ isBlockValid: true, isProjectValid: true, isUserNameValid: true });
    const [newNameObj, setNewNameObj] = React.useState({
        blockName: '',
        projectName: '',
        userName: '',
        concatTitle: TXT_HEADING_PLACEHOLDER
    });
    const setBlockName = (val) => {
        updateTitle({ ...newNameObj, blockName: val });
    };
    const setProjectName = (val) => {
        updateTitle({ ...newNameObj, projectName: val });
    };
    const setUserName = (val) => {
        updateTitle({ ...newNameObj, userName: val });
    };
    const updateTitle = (val) => {
        if (!val.blockName && !val.projectName && !val.userName) {
            val.concatTitle = TXT_HEADING_PLACEHOLDER;
            setNewNameObj(val);
            return;
        }
        const blockName = val.blockName ? val.blockName : TXT_HEADING_BLOCK_PLACEHOLDER;
        const projectName = val.projectName ? val.projectName : TXT_HEADING_PROJECT_PLACEHOLDER;
        const userName = val.userName ? val.userName : TXT_HEADING_USERNAME_PLACEHOLDER;
        const concatTitle = [userName, projectName, blockName].join('/');
        val = { ...val, concatTitle };
        setNewNameObj(val);
    };
    const validateInput = (input) => {
        return !!input;
    };
    const handleCreateBtnClick = () => {
        const isAllValid = validateAll();
        if (!isAllValid)
            return;
        const trimmedObj = {};
        for (const key in newNameObj) {
            if (newNameObj.hasOwnProperty(key)) {
                const elem = newNameObj[key];
                trimmedObj[key] = elem.trim();
            }
        }
        props.onNewBtnClick(trimmedObj);
    };
    const validateAll = () => {
        const lValidationMap = {
            isBlockValid: validateInput(newNameObj.blockName),
            isProjectValid: validateInput(newNameObj.projectName),
            isUserNameValid: validateInput(newNameObj.userName)
        };
        let isMapValid = true;
        for (const key in lValidationMap) {
            if (lValidationMap.hasOwnProperty(key)) {
                const element = lValidationMap[key];
                if (!element)
                    isMapValid = false;
            }
        }
        setValidationMap(lValidationMap);
        return isMapValid;
    };
    return (React.createElement("div", { className: NEWITPT_NODE_CLASS },
        React.createElement("div", { className: 'title' },
            React.createElement("div", { className: 'name' }, newNameObj.concatTitle)),
        React.createElement("div", { className: 'description' }, TXT_CREATENEW),
        React.createElement("div", { className: 'body' },
            React.createElement("span", null, TXT_USERNAME),
            React.createElement("input", { className: validationMap.isUserNameValid ? '' : 'invalid', onChange: (ev) => {
                    setUserName(ev.target.value);
                    validateAll();
                }, value: newNameObj.userName === TXT_HEADING_USERNAME_PLACEHOLDER ? '' : newNameObj.userName }),
            React.createElement("span", null, TXT_PROJECTNAME),
            React.createElement("input", { className: validationMap.isProjectValid ? '' : 'invalid', onChange: (ev) => {
                    setProjectName(ev.target.value);
                    validateAll();
                }, value: newNameObj.projectName === TXT_HEADING_PROJECT_PLACEHOLDER ? '' : newNameObj.projectName }),
            React.createElement("span", null, TXT_BLOCKNAME),
            React.createElement("input", { className: validationMap.isBlockValid ? '' : 'invalid', onChange: (ev) => {
                    setBlockName(ev.target.value);
                    validateAll();
                }, value: newNameObj.blockName === TXT_HEADING_BLOCK_PLACEHOLDER ? '' : newNameObj.blockName })),
        React.createElement("small", { className: validationMap.isBlockValid && validationMap.isProjectValid && validationMap.isUserNameValid ? 'validationtxt' : 'validationtxt invalid' }, TXT_SET_ALL_FIELDS),
        React.createElement("button", { className: 'editor-btn editor-btn-confirm confirm-btn', onClick: (e) => {
                e.stopPropagation();
                handleCreateBtnClick();
            } }, TXT_BTN_CREATE)));
};
