var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from 'react';
import { ldBlueprint } from '../../ldaccess/ldBlueprint';
import { initLDLocalState, gdsfpLD } from '../generic/generatorFns';
import { Component } from 'react';
import { LDDict } from '../../ldaccess/LDDict';
import { LDUIDictVerbs, UserDefDict } from '../../ldaccess';
import { VisualKeysDict } from '../visualcomposition';
let cfgIntrprtKeys = [LDUIDictVerbs.htmlSrc, VisualKeysDict.cssClassName];
let ownKVLs = [
    {
        key: LDUIDictVerbs.htmlSrc,
        value: undefined,
        ldType: LDDict.Text
    },
    {
        key: VisualKeysDict.cssClassName,
        value: undefined,
        ldType: LDDict.Text
    }
];
export const CORE_IFRAME_NAME = "metaexplorer.io/basichtml/iframe";
const iframeBpCfg = {
    subItptOf: null,
    nameSelf: CORE_IFRAME_NAME,
    ownKVLs: ownKVLs,
    inKeys: cfgIntrprtKeys,
    crudSkills: "cRud",
    canInterpretType: CORE_IFRAME_NAME + UserDefDict.standardItptObjectTypeSuffix
};
export const CORE_IFRAME_CFG = { ...iframeBpCfg };
let PureIFrameComponent = class PureIFrameComponent extends Component {
    constructor(props) {
        super(props);
        this.cfg = this.constructor["cfg"];
        const ldState = initLDLocalState(this.cfg, props, [], cfgIntrprtKeys, [], [true]);
        this.state = {
            ...ldState,
        };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        let rvLD = gdsfpLD(nextProps, prevState, [], cfgIntrprtKeys, null, [], [true]);
        if (!rvLD) {
            return null;
        }
        let rvNew = { ...rvLD };
        return {
            ...rvNew
        };
    }
    render() {
        const { localValues } = this.state;
        const htmlSrc = localValues.get(LDUIDictVerbs.htmlSrc);
        const cssClassName = localValues.get(VisualKeysDict.cssClassName);
        return React.createElement("iframe", { src: htmlSrc, className: cssClassName });
    }
};
PureIFrameComponent = __decorate([
    ldBlueprint(CORE_IFRAME_CFG)
], PureIFrameComponent);
export { PureIFrameComponent };
