var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ldBlueprint } from "../../ldaccess/ldBlueprint";
import { LDDict } from "../../ldaccess/LDDict";
import { UserDefDict } from "../../ldaccess/UserDefDict";
import { AbstractDataTransformer } from "../../datatransformation/abstractDataTransformer";
import { ActionTypesDict } from "../../components/actions/ActionDict";
import { isObjPropertyRef } from "../../ldaccess/ldUtils";
export const payloadInputKey = UserDefDict.inputData;
const transfOutputKey = UserDefDict.outputData;
export const actionIdField = "actionId";
export const actionTypeField = "actionType";
export const ActionCompName = "ActionAssembler";
export const ActionCompKeys = [actionIdField, actionTypeField, payloadInputKey];
export const ActionCompOutputKVs = [
    {
        key: transfOutputKey,
        value: undefined,
        ldType: ActionTypesDict.metaExplorerAction
    }
];
const ownKVLs = [
    {
        key: actionIdField,
        value: undefined,
        ldType: LDDict.Text
    },
    {
        key: actionTypeField,
        value: undefined,
        ldType: LDDict.Text
    },
    {
        key: payloadInputKey,
        value: undefined,
        ldType: undefined
    },
    ...ActionCompOutputKVs
];
let bpCfg = {
    subItptOf: null,
    nameSelf: ActionCompName,
    ownKVLs: ownKVLs,
    inKeys: ActionCompKeys,
    crudSkills: "cRUd"
};
let ActionComp = class ActionComp extends AbstractDataTransformer {
    constructor(ldTkStr) {
        super(ldTkStr);
        this.itptKeys = ActionCompKeys;
        this.outputKvStores = ActionCompOutputKVs;
        let kvs = this.cfg.ownKVLs;
        //setting inputParams on first load, refresh output if necessary
        for (let inputidx = 0; inputidx < this.itptKeys.length; inputidx++) {
            const inputKey = this.itptKeys[inputidx];
            let param = kvs.find((val) => val.key === inputKey);
            if (param && param.value !== null && !isObjPropertyRef(param.value)
                && JSON.stringify(param) !== JSON.stringify(this.inputParams.get(inputKey))) {
                this.inputParams.set(inputKey, param);
            }
        }
        this.refreshOutput();
    }
    /**
     * this function produces an ldAction-typed value with id, type, and payload field
     * @param inputParams
     * @param outputKvStores
     */
    mappingFunction(inputParams, outputKvStores) {
        let rv = [];
        let payloadInputKv = inputParams.get(payloadInputKey);
        let idFieldKv = inputParams.get(actionIdField);
        let typeFieldKv = inputParams.get(actionTypeField);
        if (payloadInputKv && payloadInputKv.value && ((idFieldKv && idFieldKv.value) || (typeFieldKv && typeFieldKv.value))) {
            let payload = payloadInputKv.value;
            //source type constants
            const idFieldConst = idFieldKv ? idFieldKv.value : null;
            const typeFieldConst = typeFieldKv ? typeFieldKv.value : null;
            const newAction = {
                ldId: idFieldConst,
                ldType: typeFieldConst,
                payload: payload
            };
            //output var
            let outputValArr = [newAction];
            const transfOutputKV = outputKvStores.get(transfOutputKey);
            transfOutputKV.value = outputValArr;
            rv = [transfOutputKV];
        }
        return rv;
    }
};
ActionComp = __decorate([
    ldBlueprint(bpCfg)
], ActionComp);
export { ActionComp };
