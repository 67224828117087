import { useRef, useEffect, useState } from 'react';
/**
 * source: https://usehooks-typescript.com/react-hook/use-intersection-observer
 * @param param0
 **/
function useIntersectionObserver({ elementRef, threshold = 0.1, root = null, rootMargin = '0%', freezeOnceVisible = false, }) {
    const observer = useRef(null);
    const [entry, setEntry] = useState();
    const isClient = typeof window !== `undefined`;
    const hasIOSupport = isClient && !!window.IntersectionObserver;
    const noUpdate = entry && entry.isIntersecting && freezeOnceVisible;
    const IOOptions = { threshold, root, rootMargin };
    const updateEntry = ([entry]) => {
        setEntry(entry);
    };
    useEffect(() => {
        const node = elementRef && elementRef.current; // DOM Ref
        if (!hasIOSupport || noUpdate || !node) {
            return undefined;
        }
        // Delete the old observer before creating a new one
        if (observer.current)
            observer.current.disconnect();
        observer.current = new IntersectionObserver(updateEntry, IOOptions);
        // Ensure the rest of useEffect use the same observer
        const { current: currentObserver } = observer;
        currentObserver.observe(node);
        return () => {
            currentObserver.disconnect();
        };
    }, [elementRef, threshold, root, rootMargin, noUpdate]);
    return [!!(entry && entry.isIntersecting), entry];
}
export default useIntersectionObserver;
