import { OutputInfoNodeWidgetFactory } from "./OutputInfoNodeWidget";
import { OUTPUT_INFO_MODEL } from "../node-editor-consts";
import { OutputInfoPartNodeModel } from "./OutputInfoNodeModel";
import { AbstractReactFactory } from "@projectstorm/react-canvas-core";
import React from "react";
export class OutputInfoWidgetFactory extends AbstractReactFactory {
    constructor() {
        super(OUTPUT_INFO_MODEL);
    }
    generateReactWidget(event) {
        return React.createElement(OutputInfoNodeWidgetFactory, { node: event.model, engine: this.engine });
    }
    generateModel(event) {
        return this.getNewInstance();
    }
    getNewInstance() {
        return OutputInfoPartNodeModel.fromVars();
    }
}
