import { ActionsObservable, ofType } from "redux-observable";
import { mergeMap } from 'rxjs/operators';
//import "rxjs/Rx"; //TODO: re-check this line and only import what's needed
//source: https://mikebridge.github.io/articles/typescript-redux-observable-epic-test/
export const SCHEMA_LOAD_REQUEST = "schema/SCHEMA_LOAD_REQUEST";
export const SCHEMA_LOAD_RESULT = "schema/SCHEMA_LOAD_RESULT";
export const SCHEMA_LOAD_ERROR = "schema/SCHEMA_LOAD_ERROR";
export const loadSchema = (userid) => ({
    type: SCHEMA_LOAD_REQUEST,
    userid
});
export const loadSchemaResult = (results) => ({
    type: SCHEMA_LOAD_RESULT,
    results
});
export const loadSchemaFailure = (message) => ({
    type: SCHEMA_LOAD_ERROR,
    message
});
export const isLoadingSchemaReducer = function isLoading(state = false, action) {
    switch (action.type) {
        case SCHEMA_LOAD_REQUEST:
            return true;
        case SCHEMA_LOAD_RESULT:
        case SCHEMA_LOAD_ERROR:
            return false;
        default:
            return state;
    }
};
export const loadSchemaEpic = (action$, store, { getJSON }) => {
    return action$.pipe(ofType(SCHEMA_LOAD_REQUEST), mergeMap((action) => getJSON(`%PUBLIC_URL%/api/users`)
        .map((response) => loadSchemaResult(response))
        .catch((error) => ActionsObservable.of(loadSchemaFailure(`An error occurred: ${error.message}`)))));
};
