import { ldOptionsClientSideCreateAction, ldOptionsClientSideUpdateAction, dispatchKvUpdateAction, ldAction } from "../appstate/epicducks/ldOptions-duck";
import { UserDefDict } from "../ldaccess/UserDefDict";
import { ldOptionsDeepCopy } from "../ldaccess/ldUtils";
import { linearSplitRequestAction } from "./epicducks/linearSplit-duck";
import { refMapREQUESTAction, refMapSUCCESSAction } from "./epicducks/refMap-duck";
import { ITPT_REFMAP_BASE } from "../ldaccess/iitpt-retriever";
export const mapStateToPropsRoot = (state, ownProps) => {
    return { cfg: { ...state.appCfg }, isLoading: state.isLoading };
};
//final:
export const mapStateToProps = (state, ownProps) => {
    let tokenString = ownProps ? ownProps.ldTokenString : null;
    let ldOptionsLoc = tokenString ? state.ldoptionsMap[tokenString] : null;
    //ldOptionsLoc = ldOptionsLoc ? {...ldOptionsLoc} : null; //only spread if exists
    if (ldOptionsLoc) {
        ldOptionsLoc = ldTkStrRefToFilledProp(state, ownProps, ldOptionsLoc);
        //ldOptionsLoc = ldOptionsDeepCopy(ldOptionsLoc); //causes circular calls
    }
    return {
        ...ownProps,
        ldOptions: (ldOptionsLoc)
    };
};
const ldTkStrRefToFilledProp = (state, ownProps, ldOptionsLoc) => {
    let rv = { ...ldOptionsLoc };
    let ldTokenString = ldOptionsLoc.ldToken.get();
    /*let newKVStores = */
    ldOptionsLoc.resource.kvStores.map((val, idx) => {
        if (val.ldType !== UserDefDict.ldTokenStringReference)
            return val;
        let ldOptionsSub = state.ldoptionsMap[val.value];
        if (!ldOptionsSub)
            return val;
        let outPutKVMap = ldOptionsSub.resource.kvStores.find((elem) => elem.key === UserDefDict.outputKVMapKey);
        if (!outPutKVMap)
            return val;
        outPutKVMap = outPutKVMap.value;
        if (!outPutKVMap)
            return val;
        let rvInner = null;
        for (const key in outPutKVMap) {
            if (outPutKVMap.hasOwnProperty(key)) {
                const element = outPutKVMap[key];
                if (element.targetLDToken.get() !== ldTokenString)
                    continue;
                if (element.targetProperty !== val.key)
                    continue;
                rvInner = ldOptionsSub.resource.kvStores.find((elem) => elem.key === key);
            }
        }
        if (!rvInner)
            return val;
        return rvInner;
    });
    /*let newResource: ILDResource = {
        kvStores: newKVStores,
        webInResource: ldOptionsLoc.resource.webInResource,
        webOutResource: ldOptionsLoc.resource.webOutResource
    };*/
    return rv;
};
export const mapDispatchToProps = (dispatch, ownProps) => ({
    notifyLDOptionsChange: (ldOptions) => {
        if (!ldOptions) {
            let kvStores = [ /*ownProps.singleKV*/];
            let lang;
            let alias = ownProps.ldTokenString;
            dispatch(ldOptionsClientSideCreateAction(kvStores, lang, alias));
        }
        else {
            dispatch(ldOptionsClientSideUpdateAction(ldOptions));
        }
    },
    notifyLDOptionsLinearSplitChange: (ldOptions) => {
        if (!ldOptions)
            return;
        dispatch(linearSplitRequestAction(ldOptions));
    },
    notifyLDOptionsRefMapSplitChange: (ldOptions, refMap) => {
        if (!ldOptions)
            return;
        const { canInterpretType } = refMap;
        if (!ldOptions.resource || !ldOptions.resource.kvStores || ldOptions.resource.kvStores.length < 1) {
            //console.warn("can't dispatch RefMap Split");
            return;
        }
        let newLDOptions = ldOptionsDeepCopy(ldOptions);
        const matchingTypeKV = newLDOptions.resource.kvStores.find((a) => a.ldType === canInterpretType);
        if (!matchingTypeKV) {
            //console.warn("can't dispatch RefMap Split");
            //return;
        }
        else {
            const newKvArr = [];
            const matchVal = matchingTypeKV.value;
            if (!(!matchVal && matchVal !== false)) {
                const matchValAsObj = matchVal;
                for (const keyPart in matchValAsObj) {
                    if (matchValAsObj.hasOwnProperty(keyPart)) {
                        const valPart = matchValAsObj[keyPart];
                        const newKV = { key: keyPart, value: valPart, ldType: null };
                        newKvArr.push(newKV);
                    }
                }
            }
            newLDOptions.resource.kvStores = newKvArr;
        }
        let refMapKv = refMap.ownKVLs.find((val) => val.key === UserDefDict.intrprtrBPCfgRefMapKey);
        if (refMapKv && refMapKv.value && refMapKv.value[ITPT_REFMAP_BASE]) {
            dispatch(refMapSUCCESSAction(newLDOptions));
            return;
        }
        dispatch(refMapREQUESTAction(newLDOptions, refMap));
    },
    dispatchKvOutput: (changedKvStores, thisLdTkStr, updatedKvMap) => {
        if (!(changedKvStores && thisLdTkStr && updatedKvMap)) {
            //console.warn("dispatching KVs failed because not all values were set");
            return;
        }
        dispatch(dispatchKvUpdateAction(changedKvStores, thisLdTkStr, updatedKvMap));
        return;
    },
    dispatchLdAction: (ldId, ldType, payload) => {
        if (!payload)
            return;
        if (!ldId && !ldType)
            return;
        dispatch(ldAction(ldId, ldType, payload));
    }
});
