var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ldBlueprint, createLayoutBpCfg, PureLayoutComponent } from "@metaexplorer/core";
import CircleView from 'metaexplorer-react-components/lib/components/circle/circleview';
import React from 'react';
export const LayoutCircleDisplayName = 'metaexplorer.io/layout/circle-display';
let PureCircleLayout = class PureCircleLayout extends PureLayoutComponent {
    constructor() {
        super(...arguments);
        this.styleClassName = ""; //can be set, default behaviour is centering vertically and horizontally
    }
    render() {
        return React.createElement(CircleView, null, this.renderInputContainer());
    }
};
PureCircleLayout = __decorate([
    ldBlueprint(createLayoutBpCfg(LayoutCircleDisplayName))
], PureCircleLayout);
export { PureCircleLayout };
