import { ReduxItptRetriever } from "../ld-react-redux-connect/ReduxItptRetriever";
class AppItptRetriever extends ReduxItptRetriever {
}
var appIntRetr = null;
export let appItptRetrFn = () => {
    if (appIntRetr == null) {
        appIntRetr = new AppItptRetriever();
    }
    return appIntRetr;
};
