import { ldBlueprint } from "../../ldaccess/ldBlueprint";
import { UserDefDict } from "../../ldaccess/UserDefDict";
import { AbstractDataTransformer } from "../../datatransformation/abstractDataTransformer";
const transfInputKey = UserDefDict.inputData;
class PureDataTypeDisassembler extends AbstractDataTransformer {
    /**
     * this function assembles input fields to a single flat new datatype
     * @param inputParams
     * @param outputKvStores
     */
    mappingFunction(inputParams, outputKvStores) {
        let rv = [];
        let inputKV = inputParams.get(transfInputKey);
        //output var
        let outputValArr = [];
        outputKvStores.forEach((val, idx) => {
            const newOutputObj = {
                key: val.key,
                value: inputKV && (inputKV.value !== undefined && inputKV.value !== null) ? inputKV.value[val.key] : null,
                ldType: val.ldType
            };
            outputValArr.push(newOutputObj);
        });
        rv = outputValArr;
        return rv;
    }
}
export function flatDataTypeDisassemblerFactory(outputKvStores, nameSelf, inputType) {
    const ActionCompInputKVs = [
        {
            key: transfInputKey,
            value: undefined,
            ldType: inputType
        }
    ];
    const ownKVLs = [
        ...ActionCompInputKVs,
        ...outputKvStores,
    ];
    const inKeys = [transfInputKey];
    let bpCfg = {
        subItptOf: null,
        nameSelf,
        ownKVLs,
        inKeys,
        crudSkills: "cRUd"
    };
    let DataTypeDisassemblerExt = class extends PureDataTypeDisassembler {
        constructor() {
            super(...arguments);
            this.itptKeys = inKeys;
            this.outputKvStores = outputKvStores;
        }
    };
    return ldBlueprint(bpCfg)(DataTypeDisassemblerExt);
}
