var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ldBlueprint, AbstractSimpleTextTable, simpleTextTableCfg, LDUIDictVerbs } from '@metaexplorer/core';
import { TableBody, Table, TableHead, TableRow, TableCell } from '@material-ui/core';
import React from 'react';
export const MD_SIMPLE_TEXT_TABLE_NAME = "metaexplorer.io/material-design/SimpleTextTable";
export const MD_SIMPLE_TEXT_TABLE_CFG = { ...simpleTextTableCfg };
MD_SIMPLE_TEXT_TABLE_CFG.nameSelf = MD_SIMPLE_TEXT_TABLE_NAME;
let MDSimpleTextTable = class MDSimpleTextTable extends AbstractSimpleTextTable {
    render() {
        const { localValues } = this.state;
        const headingRow = localValues.get(LDUIDictVerbs.headings);
        const contentRows = localValues.get(LDUIDictVerbs.tuples);
        if (!headingRow || !contentRows)
            return null;
        return React.createElement(Table, null,
            React.createElement(TableHead, null,
                React.createElement(TableRow, null, headingRow.map((headingRowElem, hIdx) => {
                    return React.createElement(TableCell, { key: "h" + hIdx }, headingRowElem);
                }))),
            React.createElement(TableBody, null, contentRows.map((contentRow, rowIdx) => (React.createElement(TableRow, { key: rowIdx }, headingRow.map((contentKey, cIdx) => (React.createElement(TableCell, { key: "c" + cIdx }, contentRow[contentKey]))))))));
        /*<Table>
            <TableHead>
                {
                    (headingRow as string[]).map((headingRowElem, hIdx) => {
                        return <TableCell key={"h" + hIdx}>{headingRowElem}</TableCell>;
                    })
                }
            </TableHead>
            {contentRows.map((contentRow, rowIdx) => (
                <TableRow key={rowIdx}>
                    {
                        headingRow.map((contentKey, cIdx) => (
                            <TableCell key={"c" + cIdx}>{contentRow[contentKey]}</TableCell>
                        ))
                    }
                </TableRow>
            ))}
        </Table>;*/
    }
};
MDSimpleTextTable = __decorate([
    ldBlueprint(MD_SIMPLE_TEXT_TABLE_CFG)
], MDSimpleTextTable);
export { MDSimpleTextTable };
