import { NodeModel } from "@projectstorm/react-diagrams";
import shortid from "shortid";
import { filter, merge } from "lodash";
import { INTERPRETERDATATYPE_MODEL } from "../node-editor-consts";
import { editorDefaultNodesColor } from "../consts";
export class ItptNodeModel extends NodeModel {
    constructor(options) {
        // nameSelf: string = "Untitled", subItptOf: string = null, canInterpretType: string = "", color: string = "rgb(0,192,255)", type?: string, id?: string, isCompound?: boolean) {
        super({
            type: options.type ? options.type : INTERPRETERDATATYPE_MODEL,
            nameSelf: options.nameSelf,
            color: options.color ? options.color : editorDefaultNodesColor,
            canInterpretType: options.canInterpretType ? options.canInterpretType : null,
            subItptOf: options.subItptOf ? options.subItptOf : null,
            isCompound: !!options.isCompound,
            id: options.id ? options.id : shortid.generate(),
            ...options
        });
        //HOTFIX: initial auto-layout doesn't know the dimensions of the nodes
        this.height = 150;
        this.width = 200;
    }
    static fromVars(nameSelf = "Untitled", subItptOf = null, canInterpretType = "", color = "rgb(0,192,255)", isCompound, type) {
        return new this({
            nameSelf,
            subItptOf,
            canInterpretType,
            color,
            type,
            isCompound
        });
    }
    deSerialize(event) {
        super.deserialize(event);
        this.setNameSelf(event.data.nameSelf);
        this.setColor(event.data.color);
        this.setCanInterpretType(event.data.canInterpretType);
        this.setSubItptOf(event.data.subItptOf);
        this.setIsCompound(event.data.isCompound);
    }
    serialize() {
        return merge(super.serialize(), {
            nameSelf: this.getNameSelf(),
            color: this.getColor(),
            canInterpretType: this.getCanInterpretType(),
            subItptOf: this.getSubItptOf(),
            isCompound: this.getIsCompound()
        });
    }
    getInPorts() {
        return filter(this.ports, (portModel) => {
            return portModel.isIn();
        });
    }
    getOutPorts() {
        return filter(this.ports, (portModel) => {
            return !portModel.isIn();
        });
    }
    getNameSelf() {
        return this.options.nameSelf;
    }
    getCanInterpretType() {
        return this.options.canInterpretType;
    }
    getSubItptOf() {
        return this.options.subItptOf;
    }
    getColor() {
        return this.options.color;
    }
    getIsCompound() {
        return this.options.isCompound;
    }
    setNameSelf(val) {
        this.options.nameSelf = val;
    }
    setCanInterpretType(val) {
        this.options.canInterpretType = val;
    }
    setSubItptOf(val) {
        this.options.subItptOf = val;
    }
    setColor(val) {
        this.options.color = val;
    }
    setIsCompound(val) {
        this.options.isCompound = val;
    }
}
