export var VisualKeysDict;
(function (VisualKeysDict) {
    //Base data types:
    VisualKeysDict["headerTxt"] = "HeaderText";
    VisualKeysDict["subHeaderTxt"] = "SubHeaderText";
    VisualKeysDict["description"] = "Description";
    VisualKeysDict["cancelTxt"] = "cancelTxt";
    VisualKeysDict["confirmTxt"] = "confirmTxt";
    VisualKeysDict["copyTxt"] = "copyTxt";
    //layout
    VisualKeysDict["switchVerticalDirection"] = "switchVerticalDirection";
    VisualKeysDict["switchHorizontalDirection"] = "switchHorizontalDirection";
    VisualKeysDict["directionChangeBreakPoint"] = "directionChangeBreakPoint";
    VisualKeysDict["cssClassName"] = "cssClass";
    /**
     * id of the html element. User is responsible for making it unique
     */
    VisualKeysDict["elementId"] = "elementId";
    //other
    VisualKeysDict["popOverContent"] = "PopOverContent";
    VisualKeysDict["iconImg"] = "IconImage";
    VisualKeysDict["videoId"] = "VideoID";
    VisualKeysDict["iconName"] = "iconName";
    VisualKeysDict["utf8textData"] = "utf8textData";
    //Interpreters
    VisualKeysDict["primaryItpt"] = "primaryInterpreter";
    VisualKeysDict["secondaryItpt"] = "secondaryInterpreter";
    VisualKeysDict["inputContainer"] = "inputContainer";
    //NavigationElement-keys
    VisualKeysDict["routeSend_search"] = "RouteSend_Search";
    VisualKeysDict["routeSend_back"] = "RouteSend_Back";
    VisualKeysDict["searchText"] = "searchText";
    VisualKeysDict["routeSend_confirm"] = "RouteSend_confirm";
    VisualKeysDict["routeSend_cancel"] = "RouteSend_cancel";
})(VisualKeysDict || (VisualKeysDict = {}));
export var VisualTypesDict;
(function (VisualTypesDict) {
    //List-relevant Types
    VisualTypesDict["compactInfoElement"] = "CompactInformationElement";
    //Router-related Types
    VisualTypesDict["route_added"] = "Route_Added";
    VisualTypesDict["route_new"] = "Route_New";
    VisualTypesDict["route_popLast"] = "Route_PopLast";
})(VisualTypesDict || (VisualTypesDict = {}));
