var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LDRetrieverSuperRewrite, itptKeysFromInputKvs, ldBlueprint, LDDict, UserDefDict, SideFXDict } from "@metaexplorer/core";
import { EditorGlobalsLoadApi } from "./EditorGlobalsAPI";
export const EditorGlobalsRetrieverName = "metaexplorer.io/v1/server/EditorGlobalsRetriever";
export const EditorGlobalsRetrieverType = "metaexplorer.io/v1/server/EditorGlobalsRetrieverType";
const TRIGGER = "trigger";
let inputKVStores = [
    {
        key: SideFXDict.srvURL,
        value: undefined,
        ldType: LDDict.URL
    },
    {
        key: TRIGGER,
        value: undefined,
        ldType: undefined
    }
];
let outputKVStores = [
    {
        key: UserDefDict.outputData,
        value: undefined,
        ldType: undefined
    },
];
let ownKVLs = [...inputKVStores, ...outputKVStores];
let inKeys = itptKeysFromInputKvs(inputKVStores);
let bpCfg = {
    subItptOf: null,
    nameSelf: EditorGlobalsRetrieverName,
    ownKVLs: ownKVLs,
    inKeys,
    crudSkills: "cRud",
    canInterpretType: EditorGlobalsRetrieverType
};
let EditorGlobalsRetriever = class EditorGlobalsRetriever extends LDRetrieverSuperRewrite {
    constructor(parameters) {
        super(parameters, inKeys);
        this.apiCallOverride = () => new Promise((resolve) => {
            let srvUrl = this.state.localValues.get(SideFXDict.srvURL);
            srvUrl = srvUrl ? srvUrl : '/api/globals';
            const promise = EditorGlobalsLoadApi.getEditorGlobalsLoadApiSingleton().getGlobalsFrom(srvUrl);
            promise().then((response) => {
                resolve(this.wrapOutputKv(response));
            }).catch((reason) => resolve(this.wrapOutputKv({
                status: 'error',
                message: reason,
                statusPayload: "error"
            })));
        });
        let triggerValue = this.state.localValues.get(TRIGGER);
        if (triggerValue) {
            this.state.isInputDirty = true;
            this.evalDirtyInput();
        }
    }
    wrapOutputKv(inputBody) {
        return {
            [UserDefDict.outputData]: inputBody
        };
    }
};
EditorGlobalsRetriever = __decorate([
    ldBlueprint(bpCfg)
], EditorGlobalsRetriever);
export { EditorGlobalsRetriever };
