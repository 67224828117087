var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { SideFXDict } from "./SideFXDict";
import { LDDict } from "./../ldaccess/LDDict";
import { ldBlueprint } from "./../ldaccess/ldBlueprint";
import { LDRetrieverSuperRewrite, ldRetrCfgIntrprtKeys } from "./LDRetrieverSuper-rewrite";
export const productRetrieverName = "metaexplorer.io/productRetriever";
let ownKVLs = [
    {
        key: SideFXDict.srvURL,
        value: undefined,
        ldType: LDDict.Text
    },
    {
        key: SideFXDict.identifier,
        value: undefined,
        ldType: LDDict.Text
    },
    {
        key: LDDict.name,
        value: undefined,
        ldType: LDDict.Text
    },
    {
        key: LDDict.manufacturer,
        value: undefined,
        ldType: LDDict.Organization
    },
    {
        key: LDDict.description,
        value: undefined,
        ldType: LDDict.Text
    },
    {
        key: LDDict.image,
        value: undefined,
        ldType: undefined
    }
];
let bpCfg = {
    subItptOf: null,
    nameSelf: productRetrieverName,
    ownKVLs: ownKVLs,
    inKeys: ldRetrCfgIntrprtKeys,
    crudSkills: "cRud"
};
let ProductRetriever = class ProductRetriever extends LDRetrieverSuperRewrite {
};
ProductRetriever = __decorate([
    ldBlueprint(bpCfg)
], ProductRetriever);
export { ProductRetriever };
