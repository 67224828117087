export var ActionKeysDict;
(function (ActionKeysDict) {
    /**
     * for Actions like button clicks, NavProcessAtom-confirms etc.
     */
    ActionKeysDict["action_confirm"] = "Action_Confirm";
    /**
     * the act of selecting an item, e.g. from a list
     */
    ActionKeysDict["action_select"] = "Action_Select";
    /**
     * kvStore with this key will be pushed to a registered ActionHandler
     */
    ActionKeysDict["action_internal"] = "Action_internal";
    /**
     * when a Route is matched
     */
    ActionKeysDict["action_onRoute"] = "Action_onRoute";
    /**
     * for the handler to declare it can handle a type
     */
    ActionKeysDict["canHandleType"] = "canHandleType";
    /**
     * for the handler to declare it can handle an id
     */
    ActionKeysDict["canHandleId"] = "canHandleId";
    /**
     * for actions that trigger saving or somehow costly functions
     */
    ActionKeysDict["action_save"] = "Action_Save";
    /**
     * for actions that open (side drawers, folder structures, panels etc)
     */
    ActionKeysDict["action_open"] = "Action_Open";
    /**
     * for actions that close (side drawers, folder structures, panels etc)
     */
    ActionKeysDict["action_close"] = "Action_Close";
    ActionKeysDict["action_visibility_change"] = "Action_VisibilityChange";
    /**
     * a key on a component that dispatches actions,
     * after triggering will dispatch the action
     */
    ActionKeysDict["trigger"] = "action-trigger";
})(ActionKeysDict || (ActionKeysDict = {}));
export var ActionTypesDict;
(function (ActionTypesDict) {
    /**
     * an Action inside the frontend, not to be confused with http://schema.org/Action
     */
    ActionTypesDict["metaExplorerAction"] = "MetaExplorerAction";
})(ActionTypesDict || (ActionTypesDict = {}));
