var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ldBlueprint } from "../../ldaccess/ldBlueprint";
import { AbstractDataTransformer } from "../../datatransformation/abstractDataTransformer";
import { ActionTypesDict, ActionKeysDict } from "../../components/actions/ActionDict";
import { isObjPropertyRef } from "../../ldaccess/ldUtils";
import { getApplicationStore } from "../../approot";
import { ldAction } from "../../appstate/epicducks/ldOptions-duck";
export const ActionDispatcherName = "ActionDispatcher";
export const ActionDispatcherKeys = [ActionKeysDict.trigger, ActionKeysDict.action_confirm];
export const ActionDispatcherOutputKVs = [];
const ownKVLs = [
    {
        key: ActionKeysDict.trigger,
        value: undefined,
        ldType: undefined
    },
    {
        key: ActionKeysDict.action_confirm,
        value: undefined,
        ldType: ActionTypesDict.metaExplorerAction
    },
    ...ActionDispatcherOutputKVs
];
let bpCfg = {
    subItptOf: null,
    nameSelf: ActionDispatcherName,
    ownKVLs: ownKVLs,
    inKeys: ActionDispatcherKeys,
    crudSkills: "cRUd"
};
let ActionDispatcher = class ActionDispatcher extends AbstractDataTransformer {
    constructor(ldTkStr) {
        super(ldTkStr);
        this.evalDirtyInput = () => {
            if (this.isInputDirty) {
                this.isInputDirty = false;
                const currentTrigger = this.inputParams.get(ActionKeysDict.trigger);
                if (JSON.stringify(currentTrigger) !== JSON.stringify(this.previousTrigger)) {
                    this.previousTrigger = currentTrigger;
                    this.isOutputDirty = true;
                }
            }
        };
        this.itptKeys = ActionDispatcherKeys;
        this.outputKvStores = ActionDispatcherOutputKVs;
        let kvs = this.cfg.ownKVLs;
        //setting inputParams on first load, refresh output if necessary
        for (let inputidx = 0; inputidx < this.itptKeys.length; inputidx++) {
            const inputKey = this.itptKeys[inputidx];
            let param = kvs.find((val) => val.key === inputKey);
            if (param && param.value !== null && !isObjPropertyRef(param.value)
                && JSON.stringify(param) !== JSON.stringify(this.inputParams.get(inputKey))) {
                this.inputParams.set(inputKey, param);
            }
        }
        this.previousTrigger = this.inputParams.get(ActionKeysDict.trigger);
        //this.refreshOutput();
    }
    /**
     * doesn't follow the regular DataTransformer-flow, but dispatches an ldAction instead
     */
    refreshOutput() {
        // const trigger = this.inputParams.get(ActionKeysDict.trigger);
        const confirmAction = this.inputParams.get(ActionKeysDict.action_confirm);
        if (!confirmAction || !confirmAction.value || (typeof confirmAction.value !== 'object')) {
            console.info('received an invalid action in KVL: ' + confirmAction);
            return;
        }
        if (Array.isArray(confirmAction.value) && confirmAction.value.length === 1) {
            confirmAction.value = confirmAction.value[0];
        }
        const actionToDispatch = confirmAction.value;
        if (Array.isArray(actionToDispatch.payload) && actionToDispatch.payload.length === 1) {
            actionToDispatch.payload = actionToDispatch.payload[0];
        }
        getApplicationStore().dispatch(ldAction(actionToDispatch.ldId, actionToDispatch.ldType, actionToDispatch.payload));
    }
};
ActionDispatcher = __decorate([
    ldBlueprint(bpCfg)
], ActionDispatcher);
export { ActionDispatcher };
