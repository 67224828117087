var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ldBlueprint, VisualKeysDict, LDDict, gdsfpLD, initLDLocalState, isProduction } from "@metaexplorer/core";
import { Component } from "react";
import React from "react";
export const YoutubeEmbedName = "google-api/YoutubeEmbed";
let cfgIntrprtKeys = [VisualKeysDict.videoId];
let ownKVLs = [
    {
        key: VisualKeysDict.videoId,
        value: undefined,
        ldType: LDDict.Text,
    },
];
const bpCfg = {
    subItptOf: null,
    nameSelf: YoutubeEmbedName,
    ownKVLs: ownKVLs,
    inKeys: cfgIntrprtKeys,
    crudSkills: "cRud"
};
let PureYoutubeEmbed = class PureYoutubeEmbed extends Component {
    constructor(props) {
        super(props);
        this.cfg = this.constructor["cfg"];
        const ldState = initLDLocalState(this.cfg, props, [], [VisualKeysDict.videoId]);
        this.state = {
            ...ldState,
        };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        let rvLD = gdsfpLD(nextProps, prevState, [], [VisualKeysDict.videoId]);
        if (!rvLD) {
            return null;
        }
        let rvNew = { ...rvLD };
        return {
            ...rvNew
        };
    }
    render() {
        const { localValues } = this.state;
        let ytVideoId = localValues.get(VisualKeysDict.videoId);
        if (!isProduction)
            return null;
        return React.createElement("iframe", { className: "yt-embed", title: "YouTube video", style: { marginLeft: "auto", marginRight: "auto", maxWidth: "100%", borderWidth: "0px", flex: 1 }, src: "https://www.youtube.com/embed/" + ytVideoId + "?modestbranding=1&autohide=1&showinfo=0&controls=0autoplay=0&showinfo=0", frameBorder: 0, allowFullScreen: true });
    }
};
PureYoutubeEmbed = __decorate([
    ldBlueprint(bpCfg)
], PureYoutubeEmbed);
export { PureYoutubeEmbed };
