var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ldBlueprint, LDRetrieverSuperRewrite, LDServiceSchemaDict, SideFXDict, UserDefDict, } from "@metaexplorer/core";
import { WPGraphQLAPI } from "./../apis/wpgraphql-api";
import BP_CFG, { IN_KEYS } from "./BlogPreviewRetriever-bpcfg";
import { QUERY_GET_POSTS_BY_CATEGORY } from "./QUERY_GET_POSTS_BY_CATEGORY";
let BlogPreviewRetriever = class BlogPreviewRetriever extends LDRetrieverSuperRewrite {
    constructor(parameters) {
        super(parameters, IN_KEYS);
        const srvUrl = this.state.localValues.get(SideFXDict.srvURL);
        WPGraphQLAPI.getAPISingleton().setConfig({ uri: srvUrl });
        this.apiCallOverride = () => new Promise((resolve) => {
            const wpCat = this.state.localValues.get(LDServiceSchemaDict.WordpressCategory);
            const wpInstallUrl = this.state.localValues.get(LDServiceSchemaDict.WordpressInstallationURL);
            const promise = () => {
                return WPGraphQLAPI.getAPISingleton()
                    .getClient()
                    .query({
                    query: QUERY_GET_POSTS_BY_CATEGORY,
                    variables: { categoryName: wpCat },
                });
            };
            promise()
                .then((response) => {
                resolve(this.wrapOutputKv(response, wpInstallUrl));
            })
                .catch((reason) => resolve(this.wrapOutputKv({
                status: "error",
                message: reason,
                statusPayload: "error",
            })));
        });
        let triggerValue = this.state.localValues.get(SideFXDict.trigger);
        if (triggerValue) {
            this.state.isInputDirty = true;
            this.evalDirtyInput();
        }
    }
    wrapOutputKv(inputBody, wpInstallUrl) {
        return {
            [UserDefDict.outputData]: inputBody,
        };
    }
};
BlogPreviewRetriever = __decorate([
    ldBlueprint(BP_CFG)
], BlogPreviewRetriever);
export { BlogPreviewRetriever };
