import { ldBlueprint } from "../ldaccess/ldBlueprint";
import { getKVStoreByKey } from "../ldaccess/kvConvenienceFns";
import { UserDefDict } from "../ldaccess/UserDefDict";
import { ITPT_TAG_COMPOUND } from "../ldaccess/iitpt-retriever";
import { appItptRetrFn } from '../appconfig/appItptRetriever';
import { getApplicationStore } from "../approot";
import { LDError } from "../appstate/LDError";
import { ldOptionsDeepCopy } from "../ldaccess/ldUtils";
import { ldOptionsClientSideUpdateAction, ldOptionsClientSideCreateAction } from "../appstate/epicducks/ldOptions-duck";
import { appItptUpdateAction } from "../appstate/epicducks/appCfg-duck";
import { determineSingleKVKey } from "../components/generic/generatorFns";
import { appItptMatcherFn } from "./appItptMatcher";
/**
 * adds a blueprint defined in the editor to the AppItptRetriever, automatically looks
 * for the correct React-Class to extend
 * @param input the BlueprintConfig used as a setup for the new Itpt
 */
export const addBlueprintToRetriever = (input, retrieverName) => {
    let retriever = retrieverName ? appItptMatcherFn().getItptRetriever(retrieverName) : appItptRetrFn();
    if (!retriever)
        throw new LDError("retriever " + retrieverName + " not found");
    let candidate = retriever.getUnconnectedByNameSelf(input.subItptOf);
    if (!candidate) {
        //check if it's well-defined
        let refMap = getKVStoreByKey(input.ownKVLs, UserDefDict.intrprtrBPCfgRefMapKey);
        if (!refMap || !refMap.value || refMap.value === {})
            return;
        if (!refMap.value[input.subItptOf])
            return;
        let searchTerm = UserDefDict.intrprtrBPCfgRefMapName;
        candidate = retriever.getUnconnectedByNameSelf(searchTerm);
    }
    if (!candidate)
        return;
    let itptContainer = ldBlueprint(input)(candidate); //actually wraps, doesn't extend
    retriever.addItpt(input.canInterpretType, itptContainer, "cRud", [ITPT_TAG_COMPOUND]);
};
export const intrprtrTypeInstanceFromBlueprint = (input) => {
    if (!input)
        return null;
    let rv = {};
    input.inKeys.forEach((val) => {
        try {
            let propID = val.propRef;
            if (propID) {
                rv[propID] = null;
            }
            else if (val) {
                const kv = getKVStoreByKey(input.ownKVLs, val);
                if (!kv) {
                    let skvKey = determineSingleKVKey(input.ownKVLs, input.canInterpretType, input.inKeys);
                    if (skvKey) {
                        rv[val] = getKVStoreByKey(input.ownKVLs, skvKey).value[val];
                        return;
                    }
                }
                rv[val] = kv.value;
            }
        }
        catch (error) {
            rv[val] = null;
        }
    });
    return rv;
};
export const changeMainAppItpt = (toItptName, startingInstance) => {
    const appState = getApplicationStore().getState();
    const appKey = appState.appCfg.appKey;
    //this.generatePrefilled(val.itptList[numItpts - 1]);
    let newItpt = appItptRetrFn().getItptByNameSelf(toItptName);
    if (!newItpt)
        throw new LDError("error in interpreterAPI: could not find " + toItptName);
    let newItptCfg = { ...newItpt.cfg };
    newItptCfg.ownKVLs = startingInstance;
    let newType = newItptCfg.canInterpretType;
    let dummyInstance = intrprtrTypeInstanceFromBlueprint(newItptCfg);
    const appKvKey = appKey + "KvKey";
    if (appState.ldoptionsMap[appKey]) {
        let newLDOptions = ldOptionsDeepCopy(appState.ldoptionsMap[appKey]);
        newLDOptions.resource.kvStores = [
            { key: appKvKey, ldType: newType, value: dummyInstance }
        ];
        getApplicationStore().dispatch(ldOptionsClientSideUpdateAction(newLDOptions));
    }
    else {
        let kvStores = [
            { key: appKvKey, ldType: newType, value: dummyInstance }
        ];
        let lang;
        let alias = appKey;
        getApplicationStore().dispatch((ldOptionsClientSideCreateAction(kvStores, lang, alias)));
    }
    getApplicationStore().dispatch(appItptUpdateAction(toItptName));
};
