import * as shortId from "shortid";
import { ITPT_LINEAR_SPLIT, ITPT_REFMAP_BASE } from "./iitpt-retriever";
export function createConcatNetworkPreferredToken(inputLDTokenString, targetIntrprtrLnk) {
    return new NetworkPreferredToken(inputLDTokenString + "_" + targetIntrprtrLnk);
}
export function linearLDTokenStr(ldTokenStr, id) {
    return ldTokenStr + '-' + ITPT_LINEAR_SPLIT + id;
}
export function refMapBaseTokenStr(ldTokenStr) {
    return ldTokenStr + "_" + ITPT_REFMAP_BASE;
}
export class NetworkPreferredToken {
    constructor(initialVal) {
        this.nwVal = null;
        this.clientVal = null;
        if (!initialVal || initialVal.length === 0)
            this.clientVal = shortId.generate();
        else
            this.clientVal = initialVal;
    }
    getNetworkVal() {
        return this.nwVal;
    }
    setNetworkVal(val) {
        if (!val || val.length === 0)
            this.nwVal = null;
        this.nwVal = val;
    }
    getClientTokenVal() {
        return this.clientVal;
    }
    setClientTokenVal(val) {
        if (!val || val.length === 0)
            this.clientVal = null;
        this.clientVal = val;
    }
    get() {
        //let rv: string = null;
        if (this.clientVal && this.nwVal)
            return this.nwVal;
        if (!this.clientVal && !this.nwVal)
            return null;
        return this.clientVal ? this.clientVal : this.nwVal;
    }
}
