import { LDDict } from '../../../ldaccess/LDDict';
import { UserDefDict } from '../../../ldaccess/UserDefDict';
import { VisualKeysDict, VisualTypesDict } from '../../visualcomposition/visualDict';
import { initLDLocalState, gdsfpLD, generateItptFromCompInfo } from '../../generic/generatorFns';
import { Component } from 'react';
export const NavSearchBarName = "metaexplorer.io/material-design/NavSearchBar";
let cfgIntrprtKeys = [VisualKeysDict.inputContainer, VisualKeysDict.searchText, VisualKeysDict.routeSend_back, VisualKeysDict.cssClassName];
let ownKVLs = [
    {
        key: VisualKeysDict.inputContainer,
        value: undefined,
        ldType: UserDefDict.intrprtrClassType
    },
    {
        key: VisualKeysDict.searchText,
        value: undefined,
        ldType: LDDict.Text
    },
    {
        key: VisualKeysDict.routeSend_back,
        value: undefined,
        ldType: VisualTypesDict.route_added,
    },
    {
        key: VisualKeysDict.cssClassName,
        value: undefined,
        ldType: LDDict.Text
    },
    {
        key: VisualKeysDict.searchText,
        value: undefined,
        ldType: LDDict.Text
    }
];
export const NavSearchBarBpCfg = {
    subItptOf: null,
    nameSelf: NavSearchBarName,
    ownKVLs: ownKVLs,
    inKeys: cfgIntrprtKeys,
    crudSkills: "cRud"
};
export class AbstractNavSearchBar extends Component {
    constructor(props) {
        super(props);
        this.renderInputContainer = generateItptFromCompInfo.bind(this, VisualKeysDict.inputContainer);
        this.onSearchChange = (evtVal) => {
            this.setState({ ...this.state, searchValue: evtVal });
            const outputKVMap = this.state.localValues.get(UserDefDict.outputKVMapKey);
            if (!outputKVMap)
                return;
            let outSearchKv = {
                key: VisualKeysDict.searchText,
                value: evtVal,
                ldType: LDDict.Text
            };
            //TODO: it might be a good idea to debounce before updating the application state
            this.props.dispatchKvOutput([outSearchKv], this.props.ldTokenString, outputKVMap);
        };
        this.onBackBtnClick = () => {
            if (this.state.routeSendBack === undefined || this.state.routeSendBack === null)
                return;
            this.setState({ ...this.state, isDoRedirect: true });
        };
        this.cfg = this.constructor["cfg"];
        const ldState = initLDLocalState(this.cfg, props, [VisualKeysDict.inputContainer], [VisualKeysDict.searchText, VisualKeysDict.routeSend_back, VisualKeysDict.cssClassName, UserDefDict.outputKVMapKey]);
        this.state = {
            searchValue: ldState.localValues.get(VisualKeysDict.searchText),
            routeSendBack: ldState.localValues.get(VisualKeysDict.routeSend_back),
            isDoRedirect: false,
            ...ldState
        };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        let rvLD = gdsfpLD(nextProps, prevState, [VisualKeysDict.inputContainer], [VisualKeysDict.searchText, VisualKeysDict.routeSend_back, VisualKeysDict.cssClassName, UserDefDict.outputKVMapKey]);
        if (!rvLD) {
            return null;
        }
        let rvNew = { ...rvLD, };
        let searchValue = rvNew.localValues.get(VisualKeysDict.searchText);
        let routeSendBack = rvNew.localValues.get(VisualKeysDict.routeSend_back);
        return { ...rvNew, searchValue, routeSendBack, isDoRedirect: prevState.isDoRedirect };
    }
    render() {
        throw new Error("Method not implemented in abstract class");
    }
}
