import { PortModel, PortModelAlignment } from "@projectstorm/react-diagrams";
import { merge } from "lodash";
import { isInputValueValidFor, arrayMove } from "@metaexplorer/core";
import { LD_PORTMODEL } from "../node-editor-consts";
import { SettingsLinkModel } from "../edgesettings/SettingsLinkModel";
/**
 * @author Jonathan Schneider
 */
export class LDPortModel extends PortModel {
    static fromVars(isInput, name, kv, label = null) {
        return new this({
            in: isInput,
            name,
            kv,
            label
        });
    }
    constructor(options) {
        super({
            alignment: options.in ? PortModelAlignment.LEFT : PortModelAlignment.RIGHT,
            type: LD_PORTMODEL,
            linkSortOrder: [],
            label: options.label ? options.label : options.name,
            ...options
        });
    }
    deSerialize(event) {
        super.deserialize(event);
        //object, engine);
        this.options.in = event.data.in;
        this.options.label = event.data.label;
        this.options.kv = event.data.kv;
    }
    serialize() {
        return merge(super.serialize(), {
            in: this.options.in,
            label: this.options.label,
            kv: this.options.kv
        });
    }
    link(port) {
        let link = this.createLinkModel();
        link.setSourcePort(this);
        link.setTargetPort(port);
        return link;
    }
    canLinkToPort(port) {
        let rv = true;
        if (port instanceof LDPortModel) {
            if (this.options.in === port.options.in)
                return false;
        }
        else {
            return false;
        }
        let ldPort = port;
        if (ldPort.options.in) {
            rv = isInputValueValidFor(this.options.kv, ldPort.options.kv);
        }
        else {
            rv = isInputValueValidFor(ldPort.options.kv, this.options.kv);
        }
        return rv;
    }
    createLinkModel() {
        let link = super.createLinkModel();
        if (link)
            return link;
        let defaultLink = new SettingsLinkModel();
        return defaultLink;
    }
    removeLink(link) {
        super.removeLink(link);
        var idx = this.options.linkSortOrder.indexOf(link.getID());
        if (idx > -1) {
            this.options.linkSortOrder.splice(idx, 1);
        }
    }
    addLink(link) {
        super.addLink(link);
        this.options.linkSortOrder.push(link.getID());
    }
    decreaseLinksSortOrder(link) {
        // TODO: in here and in increaseLinksSortOrder fire a custom event. Currently doesn't update app preview
        const idx = this.options.linkSortOrder.indexOf(link.getID());
        if (idx > 0) {
            arrayMove(this.options.linkSortOrder, idx, idx - 1);
        }
    }
    increaseLinksSortOrder(link) {
        const idx = this.options.linkSortOrder.indexOf(link.getID());
        if (idx > -1 && idx <= this.options.linkSortOrder.length - 1) {
            arrayMove(this.options.linkSortOrder, idx, idx + 1);
        }
    }
    getLinksSortOrder() {
        return this.options.linkSortOrder;
    }
    setKV(kv) {
        this.options.kv = kv;
    }
    getKV() {
        return this.options.kv;
    }
    isIn() {
        return this.options.in;
    }
    getLabel() {
        return this.options.label;
    }
}
