import React from "react";
const CSS_CLASS_NAME = "savestatus";
export const TXT_INIT = "...no changes yet";
export const TXT_SAVED = "saved";
export const TXT_SAVING_ERROR = "couldn't save:";
export const SaveStatus = (props) => {
    return (React.createElement("div", { className: `${CSS_CLASS_NAME} ${CSS_CLASS_NAME}-${props.status}` },
        React.createElement("span", null, props.statusPayload),
        props.message ? React.createElement("p", null, props.message) : null));
};
