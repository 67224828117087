var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Button, Fab } from "@material-ui/core";
import { ldBlueprint, AbstractSingleAudioSelector, SingleAudioSelectorBpCfg, SingleAudioSelectorStateEnum } from "@metaexplorer/core";
import Dropzone from "react-dropzone";
import React, { createRef } from "react";
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import Audiotrack from '@material-ui/icons/Audiotrack';
import { MDAudioRecorderWPlayback } from "./recorder";
import Delete from '@material-ui/icons/Delete';
export const MD_SINGLE_AUDIO_SELECTOR_NAME = "metaexplorer.io/material-design/SingleAudioSelector";
export const MD_SINGLE_AUDIO_SELECTOR_CFG = { ...SingleAudioSelectorBpCfg };
MD_SINGLE_AUDIO_SELECTOR_CFG.nameSelf = MD_SINGLE_AUDIO_SELECTOR_NAME;
const DRAGGING_IMG_LINK = "/media/dragndrop.svg";
const i18nTxts = {
    openRecorder: 'open recorder',
    selectFile: 'select audio file'
};
const cssClasses = {
    root: 'single-audio-sel',
    delBtn: 'del-btn'
};
let MDSingleAudioSelector = class MDSingleAudioSelector extends AbstractSingleAudioSelector {
    constructor() {
        super(...arguments);
        this.dropzoneRef = createRef();
    }
    deletePreview() {
        this.destroyPreview();
        this.setState({ ...this.state, curStep: SingleAudioSelectorStateEnum.isSelectInputType });
    }
    render() {
        const { curStep, isMicAvailable, previewURL } = this.state;
        const dzInputKey = "dz-input";
        return (React.createElement(Dropzone
        // className={curStep === SingleAudioSelectorStateEnum.isPreviewing ? "single-img-sel accept" : "single-img-sel"}
        , { 
            // className={curStep === SingleAudioSelectorStateEnum.isPreviewing ? "single-img-sel accept" : "single-img-sel"}
            accept: "audio/*", multiple: false, noClick: true, 
            //disableClick={true}
            ref: this.dropzoneRef, onDropAccepted: (acceptedOrRejected) => {
                let files = acceptedOrRejected.map((file) => ({
                    ...file,
                    preview: URL.createObjectURL(file)
                }));
                this.onDropSuccess(files[0], files[0].preview);
            }, onDropRejected: () => {
                this.onDropFailure();
            }, 
            //onDragStart={() => this.startDrag()}
            onDragEnter: () => this.startDrag(), onDragOver: () => this.startDrag(), onDragLeave: () => this.onDropFailure(), onFileDialogCancel: () => this.onDropFailure(), onDrop: (aF) => console.log(aF) }, ((dzstate) => {
            switch (curStep) {
                case SingleAudioSelectorStateEnum.isSelectInputType:
                    return React.createElement("div", Object.assign({ className: cssClasses.root }, dzstate.getRootProps() //todo: check if "as any" can be removed without throwing an error. Currently @types wrong!
                    ),
                        React.createElement("input", Object.assign({ key: dzInputKey }, dzstate.getInputProps() //todo: check if "as any" can be removed without throwing an error. Currently @types wrong!
                        )),
                        isMicAvailable
                            ? React.createElement(Button, { className: "btn-extension", onClick: () => { this.startMic(); } },
                                React.createElement(RecordVoiceOverIcon, null),
                                i18nTxts.openRecorder)
                            : null,
                        React.createElement(Button, { className: "btn-extension", onClick: () => { dzstate.open(); } },
                            React.createElement(Audiotrack, null),
                            i18nTxts.selectFile));
                case SingleAudioSelectorStateEnum.isRecording:
                    return React.createElement(MDAudioRecorderWPlayback, { onAudioSrcReady: (a) => {
                            this.onDropSuccess(null, a);
                        } });
                case SingleAudioSelectorStateEnum.isDragging:
                    return React.createElement("div", Object.assign({ className: `${cssClasses.root}` }, dzstate.getRootProps() //todo: check if "as any" can be removed without throwing an error. Currently @types wrong!
                    ),
                        React.createElement("input", Object.assign({ key: dzInputKey }, dzstate.getInputProps() //todo: check if "as any" can be removed without throwing an error. Currently @types wrong!
                        )),
                        React.createElement("img", { className: "md-large-image", style: { flex: 1 }, src: DRAGGING_IMG_LINK, height: "100px" }));
                case SingleAudioSelectorStateEnum.isPreviewing:
                    return React.createElement("div", { className: `${cssClasses.root}` },
                        React.createElement("audio", { src: previewURL, controls: true }),
                        React.createElement(Fab, { className: cssClasses.delBtn, color: "secondary", onClick: () => this.deletePreview() },
                            React.createElement(Delete, null)));
                case SingleAudioSelectorStateEnum.isError:
                    return React.createElement("span", null, "isError");
                default:
                    return null;
            }
        })));
    }
};
MDSingleAudioSelector = __decorate([
    ldBlueprint(MD_SINGLE_AUDIO_SELECTOR_CFG)
], MDSingleAudioSelector);
export { MDSingleAudioSelector };
