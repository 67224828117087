import { ofType } from "redux-observable";
import { mergeMap, map, catchError } from 'rxjs/operators';
import { SingleModStateKeysDict } from '../../appstate/modstate';
import { of, from } from 'rxjs';
export const MOD_LOAD_REQUEST = "Mod/MOD_LOAD_REQUEST";
export const MOD_LOAD_RESULT = "Mod/MOD_LOAD_RESULT";
export const MOD_LOAD_RESULT_ALL = "Mod/MOD_LOAD_RESULT_ALL";
export const MOD_LOAD_ERROR = "Mod/MOD_LOAD_ERROR";
export const loadMod = (modId) => ({
    type: MOD_LOAD_REQUEST,
    modId
});
export const loadModResult = (statusResult) => ({
    type: MOD_LOAD_RESULT,
    statusResult
});
export const loadModResultAll = (statusResult) => ({
    type: MOD_LOAD_RESULT_ALL,
    statusResult
});
export const loadModFailure = (modId, message) => ({
    type: MOD_LOAD_ERROR,
    modId,
    message
});
export const modStatePartReducer = (state, action) => {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case MOD_LOAD_REQUEST:
            newState.map[action.modId] = { id: action.modId, name: null, state: SingleModStateKeysDict.loading };
            return newState;
        case MOD_LOAD_RESULT:
            newState.map[action.statusResult.id] = action.statusResult;
            return newState;
        case MOD_LOAD_RESULT_ALL:
            newState.map[action.statusResult.id] = action.statusResult;
            return newState;
        case MOD_LOAD_ERROR:
            newState.map[action.modId] = { id: action.modId, name: null, state: SingleModStateKeysDict.error, errorMsg: action.message };
            return newState;
        default:
            return newState;
    }
};
export const loadModEpic = (action$, store, { modAPI }) => {
    const _MODAPI = modAPI;
    const modQueue = [];
    return action$.pipe(ofType(MOD_LOAD_REQUEST), mergeMap((action) => {
        if (!_MODAPI.checkDependencies(action.modId)) {
            modQueue.push(action.modId);
            return from([]);
        }
        else {
            let rv = from(_MODAPI.getModData(action.modId));
            return rv.pipe(map((response) => {
                _MODAPI.setModLoadingComplete(action.modId);
                for (let idx = 0; idx < modQueue.length; idx++) {
                    const mod = modQueue[idx];
                    if (_MODAPI.checkDependencies(mod)) {
                        modQueue.splice(idx, 1);
                        return loadMod(mod);
                    }
                }
                if (_MODAPI.isRequiredLoadingComplete()) {
                    return loadModResultAll(response);
                }
                else {
                    return loadModResult(response);
                }
            }), catchError((error) => {
                console.warn(error);
                return of(loadModFailure(action.modId, `An error occurred: ${error.message ? error.message : JSON.stringify(error)}`));
            }));
        }
    }));
};
