import { DefaultPortLabel } from "@projectstorm/react-diagrams";
import { map } from "lodash";
import { LDPortModel } from "../_super/LDPortModel";
import { UserDefDict } from "@metaexplorer/core";
import React from "react";
export const TXT_ADD_PORT = "+1 in";
/**
 * @author Jonathan Schneider
 */
export class ExtendableTypesNodeWidget extends React.Component {
    constructor(props) {
        super(props);
        //super(EXTENDABLETYPES_MODEL, props);
        this.state = {};
    }
    generatePort(port) {
        return React.createElement(DefaultPortLabel, { engine: this.props.engine, port: port, key: port.getID() });
        //return <GeneralDataTypePortSelector model={port} key={port.id} />;
    }
    addInPort() {
        let len = this.props.node.getInPorts().length;
        let newPortName = "in_" + len;
        let newPortKV = {
            key: newPortName,
            value: undefined,
            ldType: UserDefDict.intrprtrClassType
        };
        this.props.node.addPort(new LDPortModel({
            in: true,
            name: newPortName,
            kv: newPortKV,
            id: newPortName,
            label: newPortName
        }));
        this.forceUpdate();
    }
    render() {
        const className = `basic-node ${this.props.node.isSelected() ? 'selected' : ''}`;
        return (React.createElement("div", { className: className, style: { background: this.props.node.getColor() } },
            React.createElement("div", { className: "title" },
                React.createElement("div", { className: "name" }, this.props.node.getNameSelf())),
            React.createElement("div", { className: "ports" },
                React.createElement("div", { className: "in" }, map(this.props.node.getInPorts(), this.generatePort.bind(this))),
                React.createElement("div", { className: "out" }, map(this.props.node.getOutPorts(), this.generatePort.bind(this)))),
            React.createElement("button", { className: "editor-btn editor-btn-addport input-highlight", onClick: this.addInPort.bind(this) }, TXT_ADD_PORT)));
    }
}
// export var ExtendableTypesNodeWidgetFactory = createFactory(ExtendableTypesNodeWidget);
