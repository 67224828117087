var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ldBlueprint } from "../../ldaccess/ldBlueprint";
import { VisualKeysDict, VisualTypesDict } from "../visualcomposition/visualDict";
import { Component } from "react";
import { gdsfpLD, initLDLocalState } from "../generic/generatorFns";
import { Redirect } from "react-router";
import { cleanRouteString } from "./route-helper-fns";
import React from "react";
export const RouteRedirectName = "metaexplorer.io/routing/Redirect";
let cfgIntrprtKeys = [VisualKeysDict.routeSend_confirm];
let ownKVLs = [
    {
        key: VisualKeysDict.routeSend_confirm,
        value: undefined,
        ldType: VisualTypesDict.route_added,
    },
];
const bpCfg = {
    subItptOf: null,
    nameSelf: RouteRedirectName,
    ownKVLs: ownKVLs,
    inKeys: cfgIntrprtKeys,
    crudSkills: "cRud"
};
let PureRedirectComponent = class PureRedirectComponent extends Component {
    constructor(props) {
        super(props);
        this.cfg = this.constructor["cfg"];
        const ldState = initLDLocalState(this.cfg, props, [], [VisualKeysDict.routeSend_confirm]);
        this.state = {
            ...ldState,
        };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        let rvLD = gdsfpLD(nextProps, prevState, [], [VisualKeysDict.routeSend_confirm]);
        if (!rvLD) {
            return null;
        }
        let rvNew = { ...rvLD, };
        return {
            ...rvNew
        };
    }
    render() {
        const { localValues } = this.state;
        const { location } = this.props.routes;
        let routeSendConfirm = localValues.get(VisualKeysDict.routeSend_confirm);
        if (!routeSendConfirm)
            return null;
        routeSendConfirm = cleanRouteString(routeSendConfirm, this.props.routes);
        if (location.pathname === routeSendConfirm)
            return null;
        //handle routing for editor-switching:
        /*let routeQuery = this.props.routes.location.search;
        if (routeQuery && (routeQuery.indexOf('mode=editor') !== -1)
            && routeSendConfirm && (routeSendConfirm.indexOf('mode=editor') === -1)) {
            routeSendConfirm += '?mode=editor';
            }*/
        return React.createElement(Redirect, { to: routeSendConfirm });
    }
};
PureRedirectComponent = __decorate([
    ldBlueprint(bpCfg)
], PureRedirectComponent);
export { PureRedirectComponent };
