import { MOD_LOAD_ERROR } from "./mod-duck";
export const ACTION_APPCFG_UPDATE_KEY = 'metaexplorer.io/APPCFG_UPDATE_KEY';
export const ACTION_APPCFG_UPDATE_ITPT = 'metaexplorer.io/APPCFG_UPDATE_ITPT';
export const appKeyUpdateAction = (updatedKey) => ({ type: ACTION_APPCFG_UPDATE_KEY, appKey: updatedKey });
export const appItptUpdateAction = (updatedItpt) => ({ type: ACTION_APPCFG_UPDATE_ITPT, mainItpt: updatedItpt });
export const appCfgStatePartReducer = (state, action) => {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case ACTION_APPCFG_UPDATE_KEY:
            newState.appKey = action.appKey;
            break;
        case ACTION_APPCFG_UPDATE_ITPT:
            newState.mainItpt = action.mainItpt;
            break;
        case MOD_LOAD_ERROR:
            newState.errorMsg = action.message;
            break;
        default:
            break;
    }
    return newState;
};
