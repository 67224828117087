var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LDDict } from '../../ldaccess/LDDict';
import { ldBlueprint } from '../../ldaccess/ldBlueprint';
import { UserDefDict } from '../../ldaccess/UserDefDict';
import { VisualKeysDict } from '../visualcomposition/visualDict';
import { initLDLocalState, generateItptFromCompInfo, gdsfpLD } from '../generic/generatorFns';
import { Route } from 'react-router';
import { Component } from 'react';
import { ActionKeysDict, ActionTypesDict } from '../../components/actions/ActionDict';
import React from 'react';
export const ROUTE_ISABSOLUTE = "isRouteAbsolute";
export const ROUTE_ISEXACT = "isRouteExact";
export const ROUTE_PATH = "routePath";
export const RouteComponentName = "metaexplorer.io/routing/Route";
let cfgIntrprtKeys = [VisualKeysDict.inputContainer, ROUTE_ISEXACT, ROUTE_ISABSOLUTE, ROUTE_PATH, ActionKeysDict.action_onRoute];
let ownKVLs = [
    {
        key: VisualKeysDict.inputContainer,
        value: undefined,
        ldType: UserDefDict.intrprtrClassType
    },
    {
        key: ROUTE_ISEXACT,
        value: undefined,
        ldType: LDDict.Boolean
    },
    {
        key: ROUTE_ISABSOLUTE,
        value: undefined,
        ldType: LDDict.Boolean
    },
    {
        key: ROUTE_PATH,
        value: undefined,
        ldType: LDDict.Text
    },
    {
        key: ActionKeysDict.action_onRoute,
        value: undefined,
        ldType: ActionTypesDict.metaExplorerAction
    }
];
let bpCfg = {
    subItptOf: null,
    nameSelf: RouteComponentName,
    ownKVLs: ownKVLs,
    inKeys: cfgIntrprtKeys,
    crudSkills: "cRud"
};
/**
 * if isRouteExact is true, will match on the exact path as in "without sub-paths"
 */
let PureRouteComponent = class PureRouteComponent extends Component {
    constructor(props) {
        super(props);
        this.renderInputContainer = generateItptFromCompInfo.bind(this, VisualKeysDict.inputContainer);
        this.cfg = this.constructor["cfg"];
        const ldState = initLDLocalState(this.cfg, props, [VisualKeysDict.inputContainer], [ROUTE_ISEXACT, ROUTE_ISABSOLUTE, ROUTE_PATH]);
        let isExact = !!ldState.localValues.get(ROUTE_ISEXACT);
        let isAbsolute = !!ldState.localValues.get(ROUTE_ISABSOLUTE);
        let toPath = ldState.localValues.get(ROUTE_PATH);
        let actionPayload = ldState.localValues.get(ActionKeysDict.action_onRoute);
        this.state = {
            ...ldState,
            isExact,
            isAbsolute,
            toPath,
            actionPayload
        };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        let rvLD = gdsfpLD(nextProps, prevState, [VisualKeysDict.inputContainer], [ROUTE_ISEXACT, ROUTE_ISABSOLUTE, ROUTE_PATH, ActionKeysDict.action_onRoute]);
        if (!rvLD) {
            return null;
        }
        let rvNew = { ...rvLD, };
        let isExact = !!rvNew.localValues.get(ROUTE_ISEXACT);
        let isAbsolute = !!rvNew.localValues.get(ROUTE_ISABSOLUTE);
        let toPath = rvNew.localValues.get(ROUTE_PATH);
        let actionOnRoute = rvNew.localValues.get(ActionKeysDict.action_onRoute);
        if (actionOnRoute) {
            nextProps.dispatchLdAction(actionOnRoute.ldId, actionOnRoute.ldType, actionOnRoute.payload);
        }
        return {
            ...rvNew,
            isExact,
            isAbsolute,
            toPath,
            actionPayload: actionOnRoute
        };
    }
    render() {
        const { isExact, toPath, isAbsolute } = this.state;
        const { routes } = this.props;
        let newPath = !isAbsolute && routes && routes.match ? routes.match.path : "";
        if (!newPath.startsWith("/")) {
            newPath += "/" + toPath;
        }
        else {
            newPath += toPath;
        }
        return React.createElement(Route, { exact: isExact, path: newPath, component: this.renderInputContainer });
    }
};
PureRouteComponent = __decorate([
    ldBlueprint(bpCfg)
], PureRouteComponent);
export { PureRouteComponent };
