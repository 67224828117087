var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var PureQRCodeDisplay_1;
import { ldBlueprint, VisualKeysDict, LDDict, gdsfpLD, initLDLocalState } from "@metaexplorer/core";
import { Component } from "react";
import React from "react";
export const QRCodeDisplayName = "qr/QRCodeDisplay";
export const QRCodeDisplayStngTypeNumber = "qr/typeNumber";
export const QRCodeDisplayStngErrorCorrectionLevel = "qr/correctionLevel";
let cfgIntrprtKeys = [VisualKeysDict.utf8textData, QRCodeDisplayStngTypeNumber, QRCodeDisplayStngErrorCorrectionLevel];
let ownKVLs = [
    {
        key: VisualKeysDict.utf8textData,
        value: undefined,
        ldType: LDDict.Text,
    },
    {
        key: QRCodeDisplayStngTypeNumber,
        value: undefined,
        ldType: LDDict.Integer,
    },
    {
        key: QRCodeDisplayStngErrorCorrectionLevel,
        value: undefined,
        ldType: LDDict.Text,
    },
];
const bpCfg = {
    subItptOf: null,
    nameSelf: QRCodeDisplayName,
    ownKVLs: ownKVLs,
    inKeys: cfgIntrprtKeys,
    crudSkills: "cRud"
};
let PureQRCodeDisplay = PureQRCodeDisplay_1 = class PureQRCodeDisplay extends Component {
    constructor(props) {
        super(props);
        this.cfg = this.constructor["cfg"];
        const ldState = initLDLocalState(this.cfg, props, [], cfgIntrprtKeys);
        this.state = PureQRCodeDisplay_1.makeQRpartInState(ldState);
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        let rvLD = gdsfpLD(nextProps, prevState, [], cfgIntrprtKeys);
        if (!rvLD) {
            return null;
        }
        return PureQRCodeDisplay_1.makeQRpartInState(rvLD);
    }
    static makeQRpartInState(inputState) {
        let rv;
        var utf8textData = "Hello";
        var typeNumber = 4;
        var correctionLevel = 'L';
        const localValues = inputState.localValues;
        utf8textData = localValues.get(VisualKeysDict.utf8textData);
        typeNumber = localValues.get(QRCodeDisplayStngTypeNumber);
        correctionLevel = localValues.get(QRCodeDisplayStngErrorCorrectionLevel);
        if (typeNumber === undefined || typeNumber === null || typeNumber < 0 || typeNumber > 40) {
            typeNumber = 0;
        }
        switch (correctionLevel) {
            case "L":
                break;
            case "M":
                break;
            case "Q":
                break;
            case "H":
                break;
            default:
                correctionLevel = "L";
                break;
        }
        if (!utf8textData)
            utf8textData = "no data";
        var qr = qrcode(typeNumber, correctionLevel);
        qr.addData(utf8textData);
        qr.make();
        rv = { ...inputState, qr };
        return rv;
    }
    render() {
        const { qr } = this.state;
        return React.createElement("div", { id: "qr-gen-target", dangerouslySetInnerHTML: qr ? { __html: qr.createImgTag() } : { __html: "" } });
    }
};
PureQRCodeDisplay = PureQRCodeDisplay_1 = __decorate([
    ldBlueprint(bpCfg)
], PureQRCodeDisplay);
export { PureQRCodeDisplay };
