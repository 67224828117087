import { SinglePortWidget } from "../edgesettings/SinglePortWidget";
import { BaseDataTypeDropDown } from "./BaseDataTypeDropDown";
import { mapStateToProps, mapDispatchToProps, ldOptionsDeepCopy, UserDefDict, NetworkPreferredToken, getKVStoreByKey, DEFAULT_ITPT_RETRIEVER_NAME, LDDict, gdsfpLD, BaseContainerRewrite } from "@metaexplorer/core";
import { connect } from "react-redux";
import { Component } from "react";
import React from "react";
class PureBaseDataTypePortSelector extends Component {
    constructor(props) {
        super(props);
        this.onPortTypeChange = (newType, nProps) => {
            let changedKvStore = this.props.model.getKV();
            let thisInput = {
                targetLDToken: new NetworkPreferredToken(nProps.ldOptions.ldToken.get()),
                targetProperty: UserDefDict.outputData
            };
            let outputKvMap = {
                key: UserDefDict.outputKVMapKey,
                ldType: UserDefDict.outputKVMapType,
                value: {
                    [UserDefDict.outputData]: [
                        thisInput
                    ]
                }
            };
            const outputKv = {
                key: UserDefDict.outputData,
                value: null,
                ldType: newType
            };
            if (newType !== changedKvStore.ldType) {
                changedKvStore.ldType = newType;
                changedKvStore.key = UserDefDict.outputData;
                changedKvStore.value = null;
            }
            this.setState({ ...this.state, portType: newType, portKvStore: outputKv });
            let newLDOptions = ldOptionsDeepCopy(nProps.ldOptions);
            newLDOptions.resource.kvStores = [changedKvStore, outputKvMap];
            this.props.notifyLDOptionsChange(newLDOptions);
        };
        this.state = {
            portType: null,
            //ldOptions: null,
            portKvStore: null,
            compInfos: null,
            localValues: null,
            localLDTypes: null
        };
        // this.props.notifyLDOptionsChange(null);
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        if (!prevState.compInfos && !prevState.localLDTypes && !prevState.localValues && !prevState.portKvStore && !prevState.portType) {
            let newLDTypes = new Map();
            let newLDValues = new Map();
            if (nextProps.model.getKV()) {
                let newLDTk = new NetworkPreferredToken(nextProps.ldTokenString);
                let ldOptsWModel = {
                    isLoading: false,
                    lang: null,
                    ldToken: newLDTk,
                    visualInfo: { retriever: DEFAULT_ITPT_RETRIEVER_NAME },
                    resource: {
                        webInResource: null,
                        webOutResource: null,
                        kvStores: [
                            nextProps.model.getKV()
                        ]
                    }
                };
                nextProps.notifyLDOptionsChange(ldOptsWModel);
                return {
                    compInfos: null,
                    localLDTypes: newLDTypes,
                    localValues: newLDValues,
                    portKvStore: nextProps.model.getKV(),
                    portType: nextProps.model.getKV().ldType
                };
            }
            nextProps.notifyLDOptionsChange(null);
            return {
                compInfos: null,
                localLDTypes: newLDTypes,
                localValues: newLDValues,
                portKvStore: null,
                portType: null
            };
        }
        let rvLD = gdsfpLD(nextProps, prevState, [], [UserDefDict.outputData]);
        let newKV = nextProps.ldOptions && nextProps.ldOptions.resource && nextProps.ldOptions.resource.kvStores
            ? getKVStoreByKey(nextProps.ldOptions.resource.kvStores, UserDefDict.outputData) : null;
        newKV = newKV ? newKV : prevState.portKvStore ? prevState.portKvStore : { key: UserDefDict.outputData, value: null, ldType: null };
        nextProps.model.setKV(newKV);
        let newType = newKV ? newKV.ldType : null;
        if (!rvLD) {
            return { ...prevState, portKvStore: newKV, portType: newType };
        }
        else {
            if (!nextProps.ldOptions)
                return null;
            let newLDOptions = ldOptionsDeepCopy(nextProps.ldOptions);
            let thisInput = {
                targetLDToken: new NetworkPreferredToken(nextProps.ldOptions.ldToken.get()),
                targetProperty: UserDefDict.outputData
            };
            let outputKvMap = {
                key: UserDefDict.outputKVMapKey,
                ldType: UserDefDict.outputKVMapType,
                value: {
                    [UserDefDict.outputData]: [
                        thisInput
                    ]
                }
            };
            newLDOptions.resource.kvStores = [newKV, outputKvMap];
            nextProps.notifyLDOptionsChange(newLDOptions);
            if ((newKV.value !== prevState.portKvStore.value) && nextProps.model) {
                const links = nextProps.model.getLinks();
                if (links && Object.keys(links).length > 0) {
                    const linksKeys = Object.keys(links);
                    const nextPort = nextProps.model.clone();
                    nextPort.setKV(newKV);
                    // { ...nextProps.model, kv: newKV };
                    links[linksKeys[0]].fireEvent({
                        port: nextPort
                    }, 'sourcePortChanged');
                }
            }
        }
        return { ...prevState, ...rvLD, portKvStore: newKV, portType: newType };
    }
    componentDidUpdate() {
        if (this.props.ldOptions && !this.state.portType) {
            this.onPortTypeChange(LDDict.Boolean, this.props);
        }
    }
    render() {
        const { portType } = this.state;
        var port = React.createElement(SinglePortWidget, { node: this.props.model.getParent(), name: this.props.model.getName(), isMulti: true });
        var label = React.createElement("div", { className: "name" }, this.props.model.getLabel());
        let targetID = this.props.model.getID();
        //let newToken: ILDToken = new NetworkPreferredToken(targetID);
        //let newOutputKVMap: OutputKVMap = { [targetID]: { targetLDToken: newToken, targetProperty: null } };
        return (React.createElement("div", { className: "out-port top-port" },
            React.createElement("div", { className: "input-highlight", onFocus: () => this.props.model.getParent().setSelected(false) },
                label,
                React.createElement(BaseDataTypeDropDown, { className: "input-highlight", selection: portType, selectionChange: (newType) => { this.onPortTypeChange(newType, this.props); } }),
                React.createElement("div", { className: "editor-selectbdt-inputcontainer" }, portType ? React.createElement(BaseContainerRewrite, { ldTokenString: targetID, routes: null }) : null)),
            port));
    }
}
PureBaseDataTypePortSelector.defaultProps = {
    in: true,
    label: "port",
    ldTokenString: null
};
export const BaseDataTypePortSelector = connect(mapStateToProps, mapDispatchToProps)(PureBaseDataTypePortSelector);
