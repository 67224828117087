import * as React from 'react';
const CSS_CLASSNAME = "itpt-summary";
const TXT_USER = "User";
const TXT_PROJECT = "Project";
const TXT_BLOCK = "Block";
export const ITPTSummary = (props) => {
    return React.createElement("div", { className: CSS_CLASSNAME },
        React.createElement("span", { className: "subheading" }, TXT_USER),
        React.createElement("span", { className: "info-itm" }, props.userName),
        React.createElement("span", { className: "subheading" }, TXT_PROJECT),
        React.createElement("span", { className: "info-itm" }, props.projectName),
        React.createElement("span", { className: "subheading" }, TXT_BLOCK),
        React.createElement("span", { className: "info-itm" }, props.blockName));
};
