import { from } from "rxjs";
export class ModAPI {
    constructor() {
        this.modInitFns = new Map();
        this.loadedModsMap = new Map();
        this.modDependencies = new Map();
    }
    getModData(id) {
        if (id == null) {
            throw new Error(("no id defined for loading Mod"));
        }
        if (!this.modInitFns.has(id)) {
            throw new Error(("no Promise found for mod with id: " + id));
        }
        let returnVal;
        const modPromise = this.modInitFns.get(id);
        returnVal = from(modPromise());
        return returnVal;
    }
    addModInitFn(modSpec) {
        this.modInitFns.set(modSpec.id, modSpec.initFn);
        this.modDependencies.set(modSpec.id, modSpec.dependencies);
    }
    addRequiredMod(modId) {
        this.loadedModsMap.set(modId, false);
    }
    setModLoadingComplete(modId) {
        this.loadedModsMap.set(modId, true);
    }
    checkDependencies(modId) {
        const deps = this.modDependencies.get(modId);
        for (let idx = 0; idx < deps.length; idx++) {
            const dep = deps[idx];
            if (this.loadedModsMap.has(dep)) {
                if (!this.loadedModsMap.get(dep)) {
                    return false;
                }
            }
            else {
                return false;
            }
        }
        return true;
    }
    getDepenciesfor(modId) {
        return this.modDependencies.get(modId);
    }
    isRequiredLoadingComplete() {
        let rv = true;
        this.loadedModsMap.forEach((val, key) => {
            if (val === false)
                rv = false;
        });
        return rv;
    }
}
