import * as React from "react";
import { indexOf } from "lodash";
export class SettingsLabelWidget extends React.Component {
    static getDerivedStateFromProps(nextProps, prevState) {
        let inputLinks = [];
        let outputLinks = [];
        try {
            //sourceLinks = nextProps.model.getParent().getSourcePort().getLinks();
            const sourcePort = nextProps.model.getParent().getSourcePort();
            if (sourcePort.isIn()) {
                inputLinks = sourcePort.getLinksSortOrder();
            }
            else {
                outputLinks = sourcePort.getLinksSortOrder();
            }
        }
        catch (ignore) {
            /** noop */
        }
        try {
            //targetLinks = nextProps.model.getParent().getTargetPort().getLinks();
            const targetPort = nextProps.model.getParent().getTargetPort();
            if (targetPort.isIn()) {
                inputLinks = targetPort.getLinksSortOrder();
            }
            else {
                outputLinks = targetPort.getLinksSortOrder();
            }
        }
        catch (ignore) {
            /** noop */
        }
        let isVisible = false;
        if (inputLinks.length > 1 || outputLinks.length > 1) {
            isVisible = true;
        }
        return { ...prevState, isVisible, inputLinks, outputLinks };
    }
    constructor(props) {
        super(props);
        // super("srd-default-label", props);
        // super("srd-link-menu", props);
        this.state = { isVisible: false, isOpened: false, inputLinks: [], outputLinks: [] };
    }
    getDestinationPort() {
        const parentLink = this.props.model.getParent();
        if (parentLink.getSourcePort().isIn()) {
            return parentLink.getSourcePort();
        }
        else {
            return parentLink.getTargetPort();
        }
    }
    getOriginPort() {
        const parentLink = this.props.model.getParent();
        if (!parentLink.getSourcePort().isIn()) {
            return parentLink.getSourcePort();
        }
        else {
            return parentLink.getTargetPort();
        }
    }
    renderLabelMsg() {
        const inKey = this.getDestinationPort().getKV().key;
        const outKey = this.getOriginPort().getKV().key;
        return React.createElement("div", { className: "labelmsg" },
            React.createElement("span", null, outKey),
            React.createElement("span", null,
                "\uD83D\uDC49",
                inKey));
    }
    render() {
        const { isVisible, isOpened, inputLinks, outputLinks } = this.state;
        const parentLink = this.props.model.getParent();
        if (!isVisible)
            return null;
        const inputLinksLen = inputLinks.length;
        const outputLinksLen = outputLinks.length;
        const inputPos = indexOf(inputLinks, parentLink.getID()) + 1;
        const outputPos = indexOf(outputLinks, parentLink.getID()) + 1;
        const destPort = this.getDestinationPort();
        if (!destPort)
            return null;
        const inKey = destPort.getKV().key;
        const origPort = this.getOriginPort();
        if (!origPort)
            return null;
        const outKey = origPort.getKV().key;
        const linkMenuClassName = "srd-link-menu";
        return React.createElement("div", { className: isOpened ? linkMenuClassName + " anim" : linkMenuClassName, onMouseEnter: (event) => this.setState({ ...this.state, isOpened: true }), onMouseLeave: () => this.setState({ ...this.state, isOpened: false }) },
            this.renderLabelMsg(),
            isOpened ?
                React.createElement("div", { className: "menu-col" },
                    outputLinksLen > 1
                        ? React.createElement(React.Fragment, null,
                            React.createElement("h3", null,
                                outKey,
                                " position"),
                            React.createElement("div", { className: "menu-row" },
                                React.createElement("button", { type: "button", className: "btn", disabled: outputPos === 1, onClick: () => {
                                        this.getOriginPort().decreaseLinksSortOrder(parentLink);
                                        this.forceUpdate();
                                    } }, "<"),
                                React.createElement("b", null, " " + outputPos + "/" + outputLinksLen + " "),
                                React.createElement("button", { type: "button", className: "btn", disabled: outputPos === outputLinksLen, onClick: () => {
                                        this.getOriginPort().increaseLinksSortOrder(parentLink);
                                        this.forceUpdate();
                                    } }, ">")))
                        : null,
                    inputLinksLen > 1
                        ? React.createElement(React.Fragment, null,
                            React.createElement("h3", null,
                                inKey,
                                " position:"),
                            React.createElement("div", { className: "menu-row" },
                                React.createElement("button", { type: "button", className: "btn", disabled: inputPos === 1, onClick: () => {
                                        this.getDestinationPort().decreaseLinksSortOrder(parentLink);
                                        this.forceUpdate();
                                    } }, "<"),
                                React.createElement("b", null, " " + inputPos + "/" + inputLinksLen + " "),
                                React.createElement("button", { type: "button", className: "btn", disabled: inputPos === inputLinksLen, onClick: () => {
                                        this.getDestinationPort().increaseLinksSortOrder(parentLink);
                                        this.forceUpdate();
                                    } }, ">"),
                                React.createElement("br", null)))
                        : null)
                : null);
    }
}
