import * as React from 'react';
import { ITPTFullscreen } from './content/ITPTFullscreen';
import { BaseContainerRewrite } from '@metaexplorer/core';
import { Tabs, MiniToolBox } from 'metaexplorer-react-components';
import { EditorDNDItemType } from './editorInterfaces';
import { EditorTrayItem } from './content/blockselection/EditorTrayItem';
import { PreviewMoveLayer, MTBItemDragContainer } from './panels/PreviewMoveLayer';
import { DNDEnabler } from './panels/DNDEnabler';
import { MainEditorDropLayer } from './panels/MainEditorDropLayer';
import { EditorTray } from './content/blockselection/EditorTray';
// import { UserInfo } from './content/status/UserInfo';
import { TabDropLayer } from './panels/TabDropLayer';
import { NewItptPanel } from './new-itpt/newItptPanel';
import { NewItptNode } from './new-itpt/newItptNodeDummy';
import { SaveStatus } from './content/status/SaveStatus';
import { LibraryPreviewVisual } from './content/librarypreview/LibraryPreviewVisuals';
const DND_CLASS = 'entrypoint-editor';
const TRANSIT_CLASS = 'editor-transit';
export const EditorMain = (props) => {
    const [activeTab, setActiveTab] = React.useState("nodeEditor");
    const [isPreviewFullScreen, setIsPreviewFullScreen] = React.useState(props.isPreviewFullScreen);
    const [previewPosition, setPreviewPosition] = React.useState({ top: 120, left: 600 });
    const mtbProps = {
        onMiniChanged: (mini) => props.onMiniChanged(mini),
        onMaxiClick: () => setIsPreviewFullScreen(true),
        onActiveStateChanged: (activeState) => props.onActiveStateChanged(activeState),
        //isMini: isMini,
        activeState: props.activeState
    };
    const mtbDragItem = {
        id: 'mtb',
        type: EditorDNDItemType.preview,
        sourceBhv: 'sGone',
        targetBhv: 'tCopy',
        data: {
            activeState: props.activeState,
            isMini: props.isMini
        }
    };
    const mtbStylableDragItem = {
        ...mtbDragItem,
        isWithDragHandle: true,
        className: 'mtb-dragcontainer',
        dragOrigin: { top: -10, left: -163 },
        isTransitDummy: true
    };
    const createTransitComponents = () => {
        const rv = [];
        //Blocks
        const editorTrayItemProps = {
            isCompoundBlock: true,
            data: {
                type: 'bdt',
                label: 'TODO'
            },
            isOpen: false,
            // tslint:disable-next-line:no-empty
            onEditBtnPress: () => { },
            // tslint:disable-next-line:no-empty
            onTriggerPreview: () => { }
        };
        rv.push({
            forType: EditorDNDItemType.block,
            componentFactory: (dragItem) => (fProps) => React.createElement(EditorTrayItem, Object.assign({}, editorTrayItemProps, { data: fProps.data }))
        });
        //Minitoolbox
        rv.push({
            forType: EditorDNDItemType.preview,
            componentFactory: (dragItem) => () => (React.createElement(MTBItemDragContainer, Object.assign({}, mtbStylableDragItem),
                React.createElement(MiniToolBox, { className: 'minitoolbox', activeState: dragItem.data.activeState, isMini: dragItem.data.isMini })))
        });
        return rv;
    };
    const tabDatas = [
        { data: 'nodeEditor', label: `current compound block: ${props.currentlyEditingItpt}` },
        { data: 'newNode', label: 'new*' }
    ];
    const onTabDrop = (item, left, top) => {
        props.changeNodeCurrentlyEditing(item.data);
    };
    const onMainEditorDrop = (item, left, top) => {
        if (item.type === EditorDNDItemType.preview) {
            setPreviewPosition({ left, top });
        }
        if (item.type === EditorDNDItemType.block) {
            const clientPosition = { clientX: left, clientY: top };
            props.onBlockItemDropped(item, clientPosition);
        }
    };
    //conditional returns
    if (isPreviewFullScreen) {
        return (React.createElement(ITPTFullscreen, { onExitFullscreen: () => setIsPreviewFullScreen(false), ldTokenString: props.previewLDTokenString, routes: props.routes }));
    }
    if (activeTab === 'newNode') {
        return (React.createElement("div", { className: DND_CLASS },
            React.createElement("div", { className: "hidden-editor" }, props.children),
            React.createElement("div", { className: `${DND_CLASS}-inner` },
                React.createElement(Tabs, { className: 'editor-tabs', selectedIdx: 1, tabs: tabDatas, onSelectionChange: (tabData) => { setActiveTab(tabData.data); } }),
                React.createElement(NewItptPanel, null,
                    React.createElement(NewItptNode, { onNewBtnClick: (newNameObj) => {
                            props.onNewBtnClick(newNameObj);
                            setActiveTab('nodeEditor');
                        } })))));
    }
    return (React.createElement(DNDEnabler, { className: DND_CLASS, transitClassName: TRANSIT_CLASS, transitComponents: createTransitComponents() },
        props.children,
        React.createElement(Tabs, { className: 'editor-tabs', selectedIdx: 0, tabs: tabDatas, onSelectionChange: (tabData) => {
                setActiveTab(tabData.data);
            } }),
        React.createElement(TabDropLayer, { onDrop: onTabDrop }),
        React.createElement(MainEditorDropLayer, { onDrop: onMainEditorDrop }),
        props.isPreviewHidden ?
            null
            : React.createElement(PreviewMoveLayer, Object.assign({}, mtbProps, { isMini: props.isMini, onUpClick: () => props.onUpClick(), previewPos: { left: previewPosition.left, top: previewPosition.top }, previewItemType: EditorDNDItemType.preview }),
                React.createElement("div", { className: "app-content mdscrollbar" },
                    React.createElement(BaseContainerRewrite, { key: props.previewLDTokenString, routes: props.routes, ldTokenString: props.previewLDTokenString }))),
        React.createElement("div", { className: `nav-drawer-wrapper ${props.isLeftDrawerActive ? "active" : "inactive"}` },
            React.createElement(EditorTray, { itpts: props.trayProps.itpts, onEditTrayItem: props.trayProps.onEditTrayItem.bind(this), onTriggerPreview: props.trayProps.onTriggerPreview.bind(this), onZoomAutoLayoutPress: () => props.trayProps.onZoomAutoLayoutPress() },
                React.createElement("div", { className: "editor-library-trayheader" },
                    React.createElement(LibraryPreviewVisual, { ldTokenString: props.libraryPreviewToken })))),
        React.createElement(SaveStatus, Object.assign({}, props.saveStatus))));
};
