export var LDDict;
(function (LDDict) {
    //Base data types:
    LDDict["Boolean"] = "http://schema.org/Boolean";
    LDDict["Integer"] = "http://schema.org/Integer";
    LDDict["Double"] = "http://schema.org/Number";
    LDDict["Text"] = "http://schema.org/Text";
    LDDict["Date"] = "http://schema.org/Date";
    LDDict["DateTime"] = "http://schema.org/DateTime";
    //classes:
    LDDict["Action"] = "https://schema.org/Action";
    LDDict["CreateAction"] = "http://schema.org/CreateAction";
    LDDict["ViewAction"] = "http://schema.org/ViewAction";
    LDDict["ChooseAction"] = "http://schema.org/ChooseAction";
    LDDict["ImageObject"] = "http://schema.org/ImageObject";
    LDDict["VideoObject"] = "https://schema.org/VideoObject";
    LDDict["AudioObject"] = "http://schema.org/AudioObject";
    LDDict["EntryPoint"] = "http://http://schema.org/EntryPoint";
    LDDict["Organization"] = "http://schema.org/Organization";
    LDDict["URL"] = "https://schema.org/URL";
    LDDict["Blog"] = "https://schema.org/Blog";
    LDDict["BlogPosting"] = "https://schema.org/BlogPosting";
    //properties:
    LDDict["address"] = "http://schema.org/address";
    LDDict["target"] = "http://schema.org/target";
    LDDict["agent"] = "http://schema.org/agent";
    LDDict["result"] = "http://schema.org/result";
    LDDict["contentUrl"] = "http://schema.org/contentUrl";
    LDDict["name"] = "http://schema.org/name";
    LDDict["fileFormat"] = "http://schema.org/fileFormat";
    LDDict["manufacturer"] = "http://schema.org/manufacturer";
    LDDict["description"] = "http://schema.org/description";
    LDDict["image"] = "http://schema.org/image";
    LDDict["gtin8"] = "http://schema.org/gtin8";
    LDDict["object"] = "http://schema.org/object";
    LDDict["actionOption"] = "http://schema.org/actionOption";
    LDDict["blogPost"] = "https://schema.org/blogPost";
    LDDict["email"] = "http://schema.org/email";
    LDDict["embedUrl"] = "https://schema.org/embedUrl";
})(LDDict || (LDDict = {}));
