var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from 'react';
import { ldBlueprint } from '../ldaccess/ldBlueprint';
import { UserDefDict } from '../ldaccess/UserDefDict';
import { VisualKeysDict } from './visualcomposition/visualDict';
import { initLDLocalState, generateItptFromCompInfo, gdsfpLD } from './generic/generatorFns';
import { Component } from 'react';
import { LDDict } from '../ldaccess/LDDict';
let allMyInputKeys = [VisualKeysDict.inputContainer, "http://my-domain.com/my-class/my-member-a"];
let ownKVLs = [
    //is an input, because it is defined in var allMyInputKeys, and is a visual container
    { key: VisualKeysDict.inputContainer, value: undefined, ldType: UserDefDict.intrprtrClassType },
    //is an input, because it is defined in var allMyInputKeys, is NOT a visual container
    { key: "http://my-domain.com/my-class/my-member-a", value: undefined, ldType: LDDict.Text },
    //is an output, because it is NOT defined in var allMyInputKey
    { key: "http://my-domain.com/my-class/my-member-b", value: undefined, ldType: LDDict.Text }
];
export const myTemplateCfg = {
    subItptOf: null,
    nameSelf: "http://my-domain.com/components/my-component-name",
    canInterpretType: "http://my-domain.com/my-class",
    ownKVLs: ownKVLs,
    inKeys: allMyInputKeys,
    crudSkills: "cRUd" //supports _R_ead and _U_pdate, but not _c_reate and _d_elete (capitalization)
};
let PureMyTemplate = class PureMyTemplate extends Component {
    constructor(props) {
        super(props);
        //generates child react components
        this.renderInputContainer = generateItptFromCompInfo.bind(this, VisualKeysDict.inputContainer);
        this.outputMemberB = () => {
            const modifiedKV = { key: "http://my-domain.com/my-class/my-member-b", value: "some Text", ldType: LDDict.Text };
            const outputKVMap = this.state.localValues.get(UserDefDict.outputKVMapKey); //internally set up for you
            this.props.dispatchKvOutput([modifiedKV], this.props.ldTokenString, outputKVMap); //outputting to the state machine
        };
        this.cfg = this.constructor["cfg"];
        const ldState = initLDLocalState(this.cfg, props, [VisualKeysDict.inputContainer], ["http://my-domain.com/my-class/my-member-a"]);
        this.state = { ...ldState, };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        let rvLD = gdsfpLD(nextProps, prevState, [VisualKeysDict.inputContainer], //gets the visual part
        ["http://my-domain.com/my-class/my-member-a"], //gets the non-visual
        "http://my-domain.com/my-class" // is the canInterpretType field
        );
        if (!rvLD) {
            return null;
        }
        let rvNew = { ...rvLD };
        return { ...rvNew };
    }
    render() {
        const { localValues } = this.state;
        const myMemberA = localValues.get("http://my-domain.com/my-class/my-member-a");
        return React.createElement(React.Fragment, null,
            React.createElement("div", { onClick: () => this.outputMemberB() }, myMemberA),
            React.createElement("div", null, this.renderInputContainer()));
    }
};
PureMyTemplate = __decorate([
    ldBlueprint(myTemplateCfg)
], PureMyTemplate);
export { PureMyTemplate };
