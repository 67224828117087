var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component } from "react";
import Card from "metaexplorer-react-components/lib/components/card/card";
import { Redirect } from "react-router";
import { ActionTypesDict, UserDefDict, ldBlueprint, VisualTypesDict, gdsfpLD, generateItptFromCompInfo, initLDLocalState, cleanRouteString, isExternalRoute, } from "@metaexplorer/core";
import React from "react";
export const CONTAINER_FRONT = "frontContainer";
export const CONTAINER_MIDDLE = "middleContainer";
export const CONTAINER_LAST = "lastContainer";
export const ACTION_MIDDLE_CONTAINER = "Action_middleContainer";
export const ROUTESEND_MIDDLE_CONTAINER = "RouteSend_middleContainer";
export const Card3itptLTRName = "metaexplorer.io/material-design/CardW3Containers";
let cfgIntrprtKeys = [
    CONTAINER_FRONT,
    CONTAINER_MIDDLE,
    CONTAINER_LAST,
    ROUTESEND_MIDDLE_CONTAINER,
    ACTION_MIDDLE_CONTAINER,
];
let ownKVLs = [
    {
        key: CONTAINER_FRONT,
        value: undefined,
        ldType: UserDefDict.intrprtrClassType,
    },
    {
        key: CONTAINER_MIDDLE,
        value: undefined,
        ldType: UserDefDict.intrprtrClassType,
    },
    {
        key: CONTAINER_LAST,
        value: undefined,
        ldType: UserDefDict.intrprtrClassType,
    },
    {
        key: ROUTESEND_MIDDLE_CONTAINER,
        value: undefined,
        ldType: VisualTypesDict.route_added,
    },
    {
        key: ACTION_MIDDLE_CONTAINER,
        value: undefined,
        ldType: ActionTypesDict.metaExplorerAction,
    },
];
export const createLayoutBpCfg = (nameSelf) => {
    return {
        subItptOf: null,
        nameSelf: nameSelf,
        ownKVLs: ownKVLs,
        inKeys: cfgIntrprtKeys,
        crudSkills: "cRud",
    };
};
let bpCfg = {
    subItptOf: null,
    nameSelf: Card3itptLTRName,
    ownKVLs: ownKVLs,
    inKeys: cfgIntrprtKeys,
    crudSkills: "cRud",
};
let PureCard3itptLTR = class PureCard3itptLTR extends Component {
    constructor(props) {
        super(props);
        this.styleClassName = "card-style";
        this.renderInputContainer = generateItptFromCompInfo.bind(this);
        this.onConfirmClick = () => {
            //let middleContainerAction = this.state.localValues.get(ACTION_MIDDLE_CONTAINER);
            //TODO: execute that action
            this.setState({
                ...this.state,
                isDoRedirectFromMiddleContent: true,
            });
        };
        this.cfg = this.constructor["cfg"];
        const ldState = initLDLocalState(this.cfg, props, cfgIntrprtKeys.slice(0, 3), cfgIntrprtKeys.slice(3));
        this.state = {
            isDoRedirectFromMiddleContent: false,
            ...ldState,
        };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        let rvLD = gdsfpLD(nextProps, prevState, cfgIntrprtKeys.slice(0, 3), cfgIntrprtKeys.slice(3));
        if (!rvLD) {
            return null;
        }
        let rvNew = {
            ...prevState,
            ...rvLD,
        };
        return {
            ...rvNew,
        };
    }
    render() {
        const { isDoRedirectFromMiddleContent, localValues } = this.state;
        const routeSendFromMiddleContent = localValues.get(ROUTESEND_MIDDLE_CONTAINER);
        const isExternal = isExternalRoute(routeSendFromMiddleContent);
        if (!isExternal &&
            isDoRedirectFromMiddleContent &&
            routeSendFromMiddleContent) {
            let route = cleanRouteString(routeSendFromMiddleContent, this.props.routes);
            this.setState({
                ...this.state,
                isDoRedirectFromMiddleContent: false,
            });
            return React.createElement(Redirect, { to: route });
        }
        if (isExternal) {
            return (React.createElement("a", { href: routeSendFromMiddleContent, target: "_blank", rel: "noopener" },
                React.createElement(Card, { frontContent: this.renderInputContainer(CONTAINER_FRONT), middleContent: this.renderInputContainer(CONTAINER_MIDDLE), lastContent: this.renderInputContainer(CONTAINER_LAST), className: this.styleClassName, onMiddleContentClick: () => { } })));
        }
        return (React.createElement(Card, { frontContent: this.renderInputContainer(CONTAINER_FRONT), middleContent: this.renderInputContainer(CONTAINER_MIDDLE), lastContent: this.renderInputContainer(CONTAINER_LAST), className: this.styleClassName, onMiddleContentClick: () => this.onConfirmClick() }));
    }
};
PureCard3itptLTR = __decorate([
    ldBlueprint(bpCfg)
], PureCard3itptLTR);
export { PureCard3itptLTR };
