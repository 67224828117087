import { LDError } from './../appstate/LDError';
import { from } from 'rxjs';
export class LDOptionsAPI {
    getLDOptions(targetUrl) {
        if (targetUrl == null) {
            throw new Error(("no targetUrl defined for LDOptionsAPI"));
        }
        let returnVal;
        let fetchPromise = fetch(targetUrl, {
            method: 'GET',
            headers: {
                Accept: "application/ld+json"
            },
        })
            .then((response) => {
            if (response.status >= 400) {
                throw new LDError("Bad response from server");
            }
            return response.json();
            /*
            var testVar = HydraClientAPI.getHC().getHypermediaProcessor(response);
            var procResource = HydraClientAPI.getHC().getHypermediaProcessor(response).process(response, HydraClientAPI.getHC()).then((hydraResponse) => {
                console.dir(hydraResponse);
                return hydraResponse;
            });
            return procResource;*/
        });
        returnVal = from(fetchPromise);
        return returnVal;
    }
    postLDOptions(uploadData, targetUrl) {
        if (targetUrl == null) {
            throw new Error(("no targetUrl defined for LDOptionsAPI"));
        }
        let returnVal;
        let uploadDataSerialized;
        let fetchPromise = fetch(targetUrl, {
            method: 'POST',
            headers: {
                'Accept': 'application/ld+json',
                'Content-Type': "application/ld+json"
            },
            body: uploadDataSerialized
        })
            .then((response) => {
            if (response.status >= 400) {
                throw new LDError("Bad response from server");
            }
            return response.json();
            /*
            var testVar = HydraClientAPI.getHC().getHypermediaProcessor(response);
            var procResource = HydraClientAPI.getHC().getHypermediaProcessor(response).process(response, HydraClientAPI.getHC()).then((hydraResponse) => {
                console.dir(hydraResponse);
                return hydraResponse;
            });
            return procResource;*/
        });
        returnVal = from(fetchPromise);
        return returnVal;
    }
}
