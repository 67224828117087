var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ldBlueprint } from '../../ldaccess/ldBlueprint';
import { UserDefDict } from '../../ldaccess/UserDefDict';
import { VisualKeysDict } from '../visualcomposition/visualDict';
import { initLDLocalState, generateItptFromCompInfo, gdsfpLD } from '../generic/generatorFns';
import { Component } from 'react';
import React from 'react';
let cfgIntrprtKeys = [VisualKeysDict.inputContainer];
let ownKVLs = [
    {
        key: VisualKeysDict.inputContainer,
        value: undefined,
        ldType: UserDefDict.intrprtrClassType
    }
];
export const createLayoutBpCfg = (nameSelf) => {
    return {
        subItptOf: null,
        nameSelf: nameSelf,
        ownKVLs: ownKVLs,
        inKeys: cfgIntrprtKeys,
        crudSkills: "cRud"
    };
};
export class PureLayoutComponent extends Component {
    constructor(props) {
        super(props);
        this.renderInputContainer = generateItptFromCompInfo.bind(this, VisualKeysDict.inputContainer);
        this.cfg = this.constructor["cfg"];
        const ldState = initLDLocalState(this.cfg, props, [VisualKeysDict.inputContainer], []);
        this.state = {
            ...ldState,
        };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        let rvLD = gdsfpLD(nextProps, prevState, [VisualKeysDict.inputContainer], []);
        if (!rvLD) {
            return null;
        }
        let rvNew = { ...rvLD };
        return {
            ...rvNew
        };
    }
    render() {
        return React.createElement("div", { className: this.styleClassName }, this.renderInputContainer());
    }
}
export const LayoutVHCenteredColumnName = 'metaexplorer.io/layout/vh-centered-column';
let PureVHcenteredColumnLayout = class PureVHcenteredColumnLayout extends PureLayoutComponent {
    constructor() {
        super(...arguments);
        this.styleClassName = "vh-centered-column";
    }
};
PureVHcenteredColumnLayout = __decorate([
    ldBlueprint(createLayoutBpCfg(LayoutVHCenteredColumnName))
], PureVHcenteredColumnLayout);
export { PureVHcenteredColumnLayout };
