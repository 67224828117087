import { Component } from 'react';
import React from 'react';
export class CameraSwitcherTabs extends Component {
    constructor() {
        super(...arguments);
        this.selectedImgLink = "/media/camera_white.svg";
        this.unselectedImgLink = "/media/camera_grey.svg";
    }
    render() {
        let { vidDeviceList, activeCameraId, onTabChanged } = this.props;
        let reactComps = vidDeviceList.map((itm, idx) => {
            const isSelected = itm.deviceId === activeCameraId;
            let imgSrc = isSelected ? this.selectedImgLink : this.unselectedImgLink;
            return React.createElement("div", { key: itm.deviceId, className: "sub-tab", onClick: isSelected ? () => { return; } : () => { onTabChanged(itm.deviceId); } },
                React.createElement("img", { className: "switcher-icon", src: imgSrc }));
        });
        return (React.createElement("div", { className: "md-camera-switcher-tabs" }, reactComps));
    }
}
