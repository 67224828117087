var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component } from "react";
import { connect } from "react-redux";
import { mapStateToProps, mapDispatchToProps, DOMCamera, UserDefDict, initLDLocalState, ldBlueprint, VisualKeysDict, LDDict } from "@metaexplorer/core";
import { QrCodeGenScanClientAPI } from "../apis/qr-code-genscan-api";
import React from "react";
/*import QrScanner from 'qr-scanner';

//by webcam
<video></video>

const qrScanner = new QrScanner(videoElem, (result) => console.log('decoded qr code:', result));

//by upload
QrScanner.scanImage(image)
    .then(result => console.log(result))
    .catch(error => console.log(error || 'No QR code found.'));
*/
export var QRCodeScannerStateEnum;
(function (QRCodeScannerStateEnum) {
    QRCodeScannerStateEnum[QRCodeScannerStateEnum["isLoading"] = 2] = "isLoading";
    QRCodeScannerStateEnum[QRCodeScannerStateEnum["isError"] = 3] = "isError";
    QRCodeScannerStateEnum[QRCodeScannerStateEnum["isScanning"] = 4] = "isScanning";
})(QRCodeScannerStateEnum || (QRCodeScannerStateEnum = {}));
export const QRCodeScannerName = "qr/QRCodeScanner";
let cfgType = LDDict.ViewAction;
let cfgIntrprtKeys = [];
let ownKVLs = [
    {
        key: VisualKeysDict.utf8textData,
        value: undefined,
        ldType: LDDict.Text
    }
];
let bpCfg = {
    subItptOf: null,
    canInterpretType: cfgType,
    nameSelf: QRCodeScannerName,
    ownKVLs: ownKVLs,
    inKeys: cfgIntrprtKeys,
    crudSkills: "cRud"
};
let QRCodeScanner = class QRCodeScanner extends Component {
    constructor(props) {
        super(props);
        this.loadingImgLink = "/media/camera_negative_black.svg";
        this.errorImgLink = "/media/nocamera_negative_black.svg";
        this.onQRCodeDetected = (result) => {
            const outputKVMap = this.state.localValues.get(UserDefDict.outputKVMapKey);
            if (!outputKVMap)
                return;
            let qrcode = result;
            const barcodeKV = {
                key: VisualKeysDict.utf8textData,
                value: qrcode,
                ldType: LDDict.Text
            };
            this.props.dispatchKvOutput([barcodeKV], this.props.ldTokenString, outputKVMap);
        };
        this.cfg = this.constructor["cfg"];
        const ldState = initLDLocalState(this.cfg, props, [], [UserDefDict.outputKVMapKey]);
        this.state = {
            ...ldState,
            vidDeviceList: null,
            curId: null,
            curStep: QRCodeScannerStateEnum.isLoading
        };
    }
    setStateToError() {
        this.setState({ ...this.state, vidDeviceList: null, curId: null, curStep: QRCodeScannerStateEnum.isError });
    }
    componentWillUnmount() {
        //Quagga.offDetected(this.onBarCodeDetected);
        if (this.state.curStep !== QRCodeScannerStateEnum.isError && this.state.curId !== null) {
            QrCodeGenScanClientAPI.destroyScanner().then(() => console.log("qr scanner destroyed"));
            //qrScanner = null;
        }
        this.setState({ ...this.state, curStep: QRCodeScannerStateEnum.isLoading, vidDeviceList: null, curId: null });
    }
    render() {
        //let stateVisLnk = this.loadingImgLink;
        // const { curStep, /*curId, vidDeviceList*/ } = this.state;
        //let isDisplayImage: boolean = true;
        //const isMultiVidSource: boolean = vidDeviceList && vidDeviceList.length > 1;
        /*switch (curStep) {
            case QRCodeScannerStateEnum.isError:
                stateVisLnk = this.errorImgLink;
                break;
            case QRCodeScannerStateEnum.isScanning:
                isDisplayImage = false;
                break;
            default:
                break;
        }*/
        return (React.createElement("div", { className: "md-barcode-reader" },
            React.createElement(DOMCamera, { showControls: false, onVideoDisplayReady: (video) => {
                    QrCodeGenScanClientAPI.getScanner(video, this.onQRCodeDetected).then((scanner) => console.log("qr scanner created"));
                }, onVideoDisplayRemoved: () => {
                    QrCodeGenScanClientAPI.destroyScanner().then(() => console.log("qr scanner destroyed"));
                } })));
    }
};
QRCodeScanner = __decorate([
    ldBlueprint(bpCfg)
], QRCodeScanner);
export { QRCodeScanner };
export default connect(mapStateToProps, mapDispatchToProps)(QRCodeScanner);
