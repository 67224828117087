import { ExtendableTypesNodeWidget } from "./ExtendableTypesNodeWidget";
import { EXTENDABLETYPES_MODEL } from "../node-editor-consts";
import { ExtendableTypesNodeModel } from "./ExtendableTypesNodeModel";
import * as React from "react";
import { AbstractReactFactory } from "@projectstorm/react-canvas-core";
export class ExtendableTypesWidgetFactory extends AbstractReactFactory {
    constructor() {
        super(EXTENDABLETYPES_MODEL);
    }
    generateReactWidget(event) {
        return React.createElement(ExtendableTypesNodeWidget, { node: event.model, engine: this.engine });
    }
    getNewInstance() {
        return ExtendableTypesNodeModel.fromVars();
    }
    generateModel(event) {
        return this.getNewInstance();
    }
}
