var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Button, Fab } from "@material-ui/core";
import { DOMCamera, ldBlueprint, AbstractSingleImageSelector, SingleImageSelectorBpCfg, SingleImageSelectorStateEnum, } from "@metaexplorer/core";
import Dropzone from "react-dropzone";
import React, { createRef } from "react";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import AddPhotoAlternate from "@material-ui/icons/AddPhotoAlternate";
import Delete from "@material-ui/icons/Delete";
import Camera from "@material-ui/icons/Camera";
export const MD_SINGLE_IMAGE_SELECTOR_NAME = "metaexplorer.io/material-design/SingleImageSelector";
export const MD_SINGLE_IMAGE_SELECTOR_CFG = { ...SingleImageSelectorBpCfg };
MD_SINGLE_IMAGE_SELECTOR_CFG.nameSelf = MD_SINGLE_IMAGE_SELECTOR_NAME;
const cssClasses = {
    delBtn: "del-btn",
    controlsContainer: "controls-container",
};
class ImgSelDOMCamera extends DOMCamera {
    stopVideoRecording() {
        this.getStream()
            .getTracks()
            .forEach((track) => {
            track.stop();
        });
        super.stopVideoRecording();
    }
    renderControls() {
        return (React.createElement("div", { className: cssClasses.controlsContainer },
            React.createElement(Fab, { color: "secondary", onClick: () => {
                    if (this.props.onImageSrcReady)
                        this.getScreenshotAsBlob();
                } },
                React.createElement(Camera, null))));
    }
}
let MDSingleImageSelector = class MDSingleImageSelector extends AbstractSingleImageSelector {
    constructor() {
        super(...arguments);
        this.dropzoneRef = createRef();
    }
    deletePreview() {
        this.destroyPreview();
        this.setState({
            ...this.state,
            curStep: SingleImageSelectorStateEnum.isSelectInputType,
        });
    }
    render() {
        const { curStep, isCamAvailable, previewURL } = this.state;
        const dzInputKey = "dz-input";
        return (React.createElement(Dropzone, { accept: "image/*", multiple: false, noClick: true, ref: this.dropzoneRef, onDropAccepted: (acceptedOrRejected) => {
                let files = acceptedOrRejected.map((file) => ({
                    ...file,
                    preview: URL.createObjectURL(file),
                }));
                this.onDropSuccess(files[0], files[0].preview);
            }, onDropRejected: () => {
                this.onDropFailure();
            }, onDragEnter: () => this.startDrag(), onDragOver: () => this.startDrag(), onDragLeave: () => this.onDropFailure(), onFileDialogCancel: () => this.onDropFailure(), onDrop: (aF) => {
                /*console.log(aF)*/
            } }, (dzstate) => {
            switch (curStep) {
                case SingleImageSelectorStateEnum.isSelectInputType:
                    return (React.createElement("div", Object.assign({ className: "single-img-sel" }, dzstate.getRootProps() //todo: check if "as any" can be removed without throwing an error. Currently @types wrong!
                    ),
                        React.createElement("input", Object.assign({ key: dzInputKey }, dzstate.getInputProps() //todo: check if "as any" can be removed without throwing an error. Currently @types wrong!
                        )),
                        isCamAvailable ? (React.createElement(Button, { className: "btn-extension", onClick: () => {
                                this.startCamera();
                            } },
                            React.createElement(AddAPhotoIcon, null),
                            "Open Camera")) : null,
                        React.createElement(Button, { className: "btn-extension", onClick: () => {
                                dzstate.open();
                            } },
                            React.createElement(AddPhotoAlternate, null),
                            "Select Image")));
                case SingleImageSelectorStateEnum.isCamShooting:
                    return (React.createElement("div", { className: "single-img-sel" },
                        React.createElement(ImgSelDOMCamera, { showControls: true, onImageSrcReady: (a) => {
                                this.onDropSuccess(null, a);
                            } })));
                case SingleImageSelectorStateEnum.isDragging:
                    return (React.createElement("div", Object.assign({ className: "single-img-sel accept" }, dzstate.getRootProps() //todo: check if "as any" can be removed without throwing an error. Currently @types wrong!
                    ),
                        React.createElement("input", Object.assign({ key: dzInputKey }, dzstate.getInputProps() //todo: check if "as any" can be removed without throwing an error. Currently @types wrong!
                        )),
                        React.createElement("img", { className: "md-large-image", style: { flex: 1 }, src: this.draggingImgLink, height: "100px" })));
                case SingleImageSelectorStateEnum.isPreviewing:
                    return (React.createElement("div", { className: "single-img-sel" },
                        React.createElement("img", { className: "cover-img", src: previewURL, alt: "image preview" }),
                        React.createElement(Fab, { className: cssClasses.delBtn, color: "secondary", onClick: () => this.deletePreview() },
                            React.createElement(Delete, null))));
                case SingleImageSelectorStateEnum.isError:
                    return React.createElement("span", null, "isError");
                default:
                    return null;
            }
        }));
    }
};
MDSingleImageSelector = __decorate([
    ldBlueprint(MD_SINGLE_IMAGE_SELECTOR_CFG)
], MDSingleImageSelector);
export { MDSingleImageSelector };
