import React, { Component } from "react";
const imageFormat = "image/jpeg";
export const ERROR_NOT_ALLOWED = "Error:NotAllowed";
export var DOMCameraErrorTypes;
(function (DOMCameraErrorTypes) {
    DOMCameraErrorTypes[DOMCameraErrorTypes["streamError"] = 2] = "streamError";
    DOMCameraErrorTypes[DOMCameraErrorTypes["mediaDevicesAccessFail"] = 3] = "mediaDevicesAccessFail";
    DOMCameraErrorTypes[DOMCameraErrorTypes["noVideoInputs"] = 4] = "noVideoInputs";
    DOMCameraErrorTypes[DOMCameraErrorTypes["enumerateDevicesFail"] = 5] = "enumerateDevicesFail";
    DOMCameraErrorTypes[DOMCameraErrorTypes["notAllowed"] = 6] = "notAllowed";
    DOMCameraErrorTypes[DOMCameraErrorTypes["browserIncapable"] = 7] = "browserIncapable";
    DOMCameraErrorTypes[DOMCameraErrorTypes["other"] = 8] = "other";
})(DOMCameraErrorTypes || (DOMCameraErrorTypes = {}));
export var DOMCameraStateEnum;
(function (DOMCameraStateEnum) {
    DOMCameraStateEnum[DOMCameraStateEnum["isLoading"] = 2] = "isLoading";
    DOMCameraStateEnum[DOMCameraStateEnum["isError"] = 3] = "isError";
    DOMCameraStateEnum[DOMCameraStateEnum["isVideoing"] = 4] = "isVideoing";
})(DOMCameraStateEnum || (DOMCameraStateEnum = {}));
/**
 * inspired by:
 * https://webrtc.github.io/samples/
 * https://github.com/webrtc/samples/blob/gh-pages/src/content/getusermedia/record/js/main.js
 *
 * this class only concerns getting a video stream from the devices camera, or failing to do so.
 * It can also handle getting individual images, and will by default render the video stream
 * in a <video>-tag, so the users can see themselves.
 */
export class DOMCamera extends Component {
    constructor(props) {
        super(props);
        this._isMounted = false;
        this.state = {
            curStep: DOMCameraStateEnum.isLoading,
            vidDeviceList: null,
            curId: null,
        };
    }
    getStream() {
        return this.stream;
    }
    startStream(strDeviceId) {
        if (!this.videoDispl)
            return;
        if (this.videoDispl.srcObject)
            return;
        const vidDispl = this.videoDispl;
        navigator.mediaDevices
            .getUserMedia({
            video: { deviceId: strDeviceId },
            audio: !!this.props.isRecordingAudio,
        })
            .then((stream) => {
            if (!this.videoDispl || !this.videoDispl.paused) {
                stream.getTracks().forEach((track) => {
                    track.stop();
                });
                return;
            }
            this.stream = stream;
            vidDispl.setAttribute('autoplay', 'true');
            vidDispl.setAttribute('muted', 'true');
            vidDispl.muted = true;
            vidDispl.setAttribute('playsinline', 'true');
            vidDispl.srcObject = stream;
            vidDispl.play();
            this.triggerReadyToRecord();
        })
            .catch((e) => {
            if (e && e.name && e.name === ERROR_NOT_ALLOWED) {
                this.setStateToError(DOMCameraErrorTypes.notAllowed);
            }
            else {
                this.setStateToError(DOMCameraErrorTypes.streamError);
            }
            return;
        });
    }
    componentWillUnmount() {
        this._isMounted = false;
        if (this.state.curStep !== DOMCameraStateEnum.isError) {
            this.setStateIfMounted({
                curStep: DOMCameraStateEnum.isLoading,
                vidDeviceList: null,
                curId: null,
            });
        }
        if (this.stream && this.stream.active) {
            this.stream.getTracks().forEach((track) => {
                track.stop();
            });
        }
        if (this.videoDispl)
            this.videoDispl.pause();
        if (this.props.onVideoDisplayRemoved) {
            this.props.onVideoDisplayRemoved();
        }
        this.videoDispl = null;
    }
    setStateIfMounted(state, callback) {
        if (!this._isMounted)
            return;
        this.setState(state, callback);
    }
    setStateToError(errorType) {
        if (!this._isMounted)
            return;
        this.setStateIfMounted({
            ...this.state,
            curStep: DOMCameraStateEnum.isError,
        });
        if (this.props.onVideoDisplayRemoved) {
            this.props.onVideoDisplayRemoved();
        }
        if (this.props.onError) {
            this.props.onError(errorType);
        }
    }
    componentDidMount() {
        this._isMounted = true;
        if (!navigator.mediaDevices ||
            !navigator.mediaDevices.enumerateDevices) {
            this.setStateToError(DOMCameraErrorTypes.mediaDevicesAccessFail);
            return;
        }
        navigator.mediaDevices
            .enumerateDevices()
            .then((devices) => {
            const vidInputList = [];
            devices.forEach((device) => {
                if (device.kind === 'videoinput') {
                    vidInputList.push(device);
                }
            });
            if (vidInputList.length === 0) {
                this.setStateToError(DOMCameraErrorTypes.noVideoInputs);
                return;
            }
            {
                const deviceId = vidInputList[0].deviceId;
                this.setStateIfMounted({
                    curId: deviceId,
                    curStep: DOMCameraStateEnum.isVideoing,
                    vidDeviceList: vidInputList,
                }, () => {
                    if (!this.props.isNoStream) {
                        this.startStream(deviceId);
                    }
                    this.triggerReadyToStream();
                });
                return;
            }
        })
            .catch(() => {
            this.setStateToError(DOMCameraErrorTypes.enumerateDevicesFail);
            return;
        });
    }
    getScreenshotAsDataURL() {
        const canvas = this.getCanvas();
        if (canvas && this.props.onImageSrcReady) {
            const b = canvas.toDataURL(imageFormat);
            this.props.onImageSrcReady(b);
        }
    }
    getScreenshotAsBlob() {
        const canvas = this.getCanvas();
        if (canvas) {
            canvas.toBlob((a) => {
                if (this.props.onImageSrcReady) {
                    const b = window.URL.createObjectURL(a);
                    this.props.onImageSrcReady(b);
                }
            });
        }
    }
    getCanvas() {
        const vidDispl = this.videoDispl;
        if (!vidDispl)
            return null;
        if (!vidDispl.videoHeight)
            return null;
        if (!this.ctx) {
            const canvasElem = document.createElement('canvas');
            const aspectRatio = vidDispl.videoWidth / vidDispl.videoHeight;
            canvasElem.width = vidDispl.clientWidth;
            canvasElem.height = vidDispl.clientWidth / aspectRatio;
            this.canvas = canvasElem;
            this.ctx = canvasElem.getContext('2d');
        }
        this.checkAndDrawOnCanvas();
        return this.canvas;
    }
    checkAndDrawOnCanvas() {
        if (this.ctx && this.videoDispl && this.canvas) {
            this.ctx.drawImage(this.videoDispl, 0, 0, this.canvas.width, this.canvas.height);
        }
    }
    triggerReadyToStream() {
        if (this._isMounted && this.props.onReadyToStream) {
            this.props.onReadyToStream();
        }
    }
    triggerReadyToRecord() {
        if (this._isMounted && this.props.onVideoDisplayReady) {
            this.props.onVideoDisplayReady(this.videoDispl);
        }
    }
    startVideoRecording() {
        if (this.props.onVideoRecordingStarted)
            this.props.onVideoRecordingStarted();
    }
    stopVideoRecording() {
        if (this.props.onVideoRecordingStopped)
            this.props.onVideoRecordingStopped();
    }
    /**
     * not yet implemented
     */
    pauseVideoRecording() {
        if (this.props.onVideoRecordingPaused)
            this.props.onVideoRecordingPaused();
    }
    renderError() {
        return React.createElement("span", null, "error opening camera");
    }
    renderLoading() {
        return React.createElement("span", null, "loading");
    }
    renderVideo() {
        const { curId } = this.state;
        return (React.createElement("video", { ref: (video) => {
                if (!video)
                    return;
                this.videoDispl = video;
                if (curId && !this.props.isNoStream)
                    this.startStream(curId);
            } }));
    }
    renderControls() {
        return (React.createElement("div", { className: "controls-container" },
            React.createElement("button", { onClick: () => {
                    if (this.props.onImageSrcReady)
                        this.getScreenshotAsBlob();
                } })));
    }
    render() {
        const { curStep } = this.state;
        return (React.createElement("div", { className: "dom-camera" }, (() => {
            switch (curStep) {
                case DOMCameraStateEnum.isError:
                    return this.renderError();
                case DOMCameraStateEnum.isLoading:
                    return this.renderLoading();
                case DOMCameraStateEnum.isVideoing:
                    return (React.createElement(React.Fragment, null,
                        this.renderVideo(),
                        this.renderControls()));
                default:
                    return null;
            }
        })()));
    }
}
