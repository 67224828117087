import * as React from 'react';
import { MoveContainer, DragContainer, MiniToolBox } from 'metaexplorer-react-components';
export function PreviewMoveLayer(props) {
    const mtbDragItem = {
        id: 'mtb',
        type: props.previewItemType,
        sourceBhv: 'sGone',
        targetBhv: 'tCopy',
        data: {
            activeState: props.activeState,
            isMini: props.isMini
        }
    };
    const mtbStylableDragItem = {
        ...mtbDragItem,
        isWithDragHandle: true,
        className: 'mtb-dragcontainer',
        dragOrigin: { top: -10, left: -163 }
    };
    return (React.createElement(MoveContainer, { className: 'editor-movecontainer', positionMap: {
            mtb: {
                pos: { top: props.previewPos.top, left: props.previewPos.left },
                child: React.createElement(MTBItemDragContainer, Object.assign({}, mtbStylableDragItem),
                    React.createElement(MiniToolBox, { className: 'minitoolbox', onMiniChanged: props.onMiniChanged, onMaxiClick: props.onMaxiClick, onUpClick: props.onUpClick, onActiveStateChanged: props.onActiveStateChanged, isMini: props.isMini, activeState: props.activeState }, props.children))
            }
        } }));
}
export function MTBItemDragContainer(props) {
    return React.createElement(DragContainer, Object.assign({}, props));
}
