import React from "react";
/**
 * @author Jonathan Schneider
 */
export class SinglePortWidget extends React.Component {
    constructor(props) {
        super(props);
        // super("srd-port", props);
        this.state = {
            selected: false
        };
    }
    /*
        getClassName() {
            return "port " + super.getClassName() + (this.state.selected ? this.bem("--selected") : "");
        }*/
    render() {
        return (React.createElement("div", { onMouseEnter: () => {
                this.setState({ selected: true });
            }, onMouseLeave: () => {
                this.setState({ selected: false });
            }, className: "port" + (this.state.selected ? " selected" : "") + (this.props.isMulti ? " isMulti" : ""), "data-name": this.props.name, "data-nodeid": this.props.node.getID() }));
    }
}
