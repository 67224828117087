var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ldBlueprint } from '../../ldaccess/ldBlueprint';
import { BaseContainerRewrite } from './baseContainer-rewrite';
import { Component } from 'react';
import { gdsfpLD, initLDLocalState } from './generatorFns';
import { ldOptionsDeepCopy } from '../../ldaccess/ldUtils';
import { NetworkPreferredToken } from '../../ldaccess/ildtoken';
import { getKVStoreByKey } from '../../ldaccess/kvConvenienceFns';
import { UserDefDict } from '../../ldaccess/UserDefDict';
import React from 'react';
export const SingleDynamicTypeDisplayName = "metaexplorer.io/SingleDynamicTypeDisplay";
const inputdata = UserDefDict.inputData;
const singleDynamicTypeDisplayOutput = "mOut";
export const singleDynamicTypeDisplayCanInterpretType = "metaexplorer.io/SingleDynamicTypeDisplayType";
let SingleDynamicTypeDisplayInputKeys = [inputdata];
let ownKVLs = [
    { key: inputdata, value: undefined, ldType: undefined }
];
export const SingleDynamicTypeDisplayCfg = {
    subItptOf: null,
    nameSelf: SingleDynamicTypeDisplayName,
    ownKVLs: ownKVLs,
    inKeys: SingleDynamicTypeDisplayInputKeys,
    crudSkills: "cRud",
    canInterpretType: singleDynamicTypeDisplayCanInterpretType
};
let PureSingleDynamicTypeDisplay = class PureSingleDynamicTypeDisplay extends Component {
    constructor(props) {
        super(props);
        this.cfg = this.constructor["cfg"];
        const ldState = initLDLocalState(this.cfg, props, [], SingleDynamicTypeDisplayInputKeys);
        this.state = { ...ldState, containertoken: null };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        let rvLocal = gdsfpLD(nextProps, prevState, [], SingleDynamicTypeDisplayInputKeys, singleDynamicTypeDisplayCanInterpretType);
        if (!rvLocal) {
            return null;
        }
        let nextContainerTokenStr = nextProps.ldOptions.ldToken.get() + "-dynType";
        let nextContainerToken = new NetworkPreferredToken(nextContainerTokenStr);
        if (!prevState.containertoken) {
            const newLDOptionsFItpt = ldOptionsDeepCopy(nextProps.ldOptions);
            newLDOptionsFItpt.ldToken = nextContainerToken;
            const modKV = getKVStoreByKey(nextProps.ldOptions.resource.kvStores, inputdata);
            if (modKV) {
                let newKvStores = [];
                let outputNum = 1;
                if (Array.isArray(modKV.value)) {
                    let newLdType = modKV.ldType;
                    outputNum = modKV.value.length;
                    for (let i = 0; i < outputNum; i++) {
                        newKvStores.push({
                            key: singleDynamicTypeDisplayOutput + i,
                            value: modKV.value[i],
                            ldType: newLdType
                        });
                    }
                }
                else {
                    newKvStores = [{ ...modKV }];
                }
                newLDOptionsFItpt.resource.kvStores = newKvStores;
                nextProps.notifyLDOptionsChange(newLDOptionsFItpt);
            }
        }
        else {
            const modKV = getKVStoreByKey(nextProps.ldOptions.resource.kvStores, inputdata);
            if (modKV) {
                let outputKVMap = rvLocal.localValues.get(UserDefDict.outputKVMapKey);
                if (!outputKVMap) {
                    outputKVMap = {};
                }
                let outputNum = 1;
                if (Array.isArray(modKV.value)) {
                    outputNum = modKV.value.length;
                    for (let i = 0; i < outputNum; i++) {
                        const newElems = [{ targetLDToken: nextContainerToken, targetProperty: singleDynamicTypeDisplayOutput + i }];
                        outputKVMap[inputdata] = newElems;
                    }
                }
                else {
                    const newElems = [{ targetLDToken: nextContainerToken, targetProperty: inputdata }];
                    outputKVMap[inputdata] = newElems;
                }
                nextProps.dispatchKvOutput([modKV], nextContainerTokenStr, outputKVMap);
            }
        }
        let rvNew = { ...rvLocal };
        return { ...prevState, ...rvNew, containertoken: nextContainerTokenStr };
    }
    render() {
        const { containertoken } = this.state;
        if (!containertoken)
            return null;
        return React.createElement(BaseContainerRewrite, { ldTokenString: this.state.containertoken });
    }
};
PureSingleDynamicTypeDisplay = __decorate([
    ldBlueprint(SingleDynamicTypeDisplayCfg)
], PureSingleDynamicTypeDisplay);
export { PureSingleDynamicTypeDisplay };
