import { intrprtrTypeInstanceFromBlueprint, addBlueprintToRetriever, NetworkPreferredToken, DEFAULT_ITPT_RETRIEVER_NAME, getApplicationStore, ldOptionsClientSideUpdateAction, isProduction } from "@metaexplorer/core";
export const USER_CSS_PREFIX = "/styles/";
export const USER_CSS_POSTFIX = "-style.css";
export class UserItptLoadApi {
    constructor() {
        this.batchAdd = (input) => {
            input.forEach((nodesBPCFG) => {
                addBlueprintToRetriever(nodesBPCFG);
            });
        };
        this.generateDummy = (blueprintCfg, ldTokenString, kvKey) => {
            let dummyInstance = intrprtrTypeInstanceFromBlueprint(blueprintCfg);
            let newType = blueprintCfg.canInterpretType;
            let newLDOptions = {
                isLoading: false,
                lang: undefined,
                ldToken: new NetworkPreferredToken(ldTokenString),
                resource: {
                    webInResource: undefined,
                    webOutResource: undefined,
                    kvStores: [
                        { key: kvKey, ldType: newType, value: dummyInstance }
                    ]
                },
                visualInfo: {
                    retriever: DEFAULT_ITPT_RETRIEVER_NAME
                }
            };
            getApplicationStore().dispatch(ldOptionsClientSideUpdateAction(newLDOptions));
        };
        this.loadUserStyleSheet = (userName) => {
            return new Promise((resolve, reject) => {
                const fileName = USER_CSS_PREFIX + userName + USER_CSS_POSTFIX;
                var cssFileReference = document.createElement("link");
                cssFileReference.onload = resolve;
                cssFileReference.onerror = reject;
                cssFileReference.setAttribute("rel", "stylesheet");
                cssFileReference.setAttribute("type", "text/css");
                cssFileReference.setAttribute("href", fileName);
                document.body.appendChild(cssFileReference);
            });
        };
    }
    static getUserItptLoadApiSingleton() {
        if (UserItptLoadApi.apiSingleton == null) {
            UserItptLoadApi.apiSingleton = new UserItptLoadApi();
        }
        return UserItptLoadApi.apiSingleton;
    }
    getItptsUnauthed() {
        return this.getItptsFrom(isProduction ? "/api/blocks" : "/api-static/interpreters.json");
    }
    getItptsFrom(targetUrl) {
        return () => {
            return new Promise((resolve, reject) => {
                fetch(targetUrl, {
                    method: 'GET',
                    headers: {
                        Accept: "application/json"
                    },
                }).then((response) => {
                    if (response.status >= 400) {
                        reject("Bad response from server");
                    }
                    response.json().then((bodyVal) => {
                        resolve(bodyVal);
                    }).catch((reason) => {
                        reject(reason);
                    });
                });
            });
        };
    }
    setItptsAt(targetUrl, postBodyJson) {
        return () => {
            return new Promise((resolve, reject) => {
                fetch(targetUrl, {
                    method: 'POST',
                    headers: {
                        Accept: "application/json",
                        'Content-Type': 'application/json'
                    },
                    body: postBodyJson
                }).then((response) => {
                    if (response.status >= 400) {
                        reject("Bad response from server");
                    }
                    response.json().then((bodyVal) => {
                        resolve(bodyVal);
                    }).catch((reason) => {
                        reject(reason);
                    });
                });
            });
        };
    }
}
