var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { SideFXDict } from "../sidefx/SideFXDict";
import { LDDict } from "../ldaccess/LDDict";
import { ldBlueprint } from "../ldaccess/ldBlueprint";
import { resolveNS } from "../ldaccess/ns/nameSpaceResolution";
import { LDRetrieverSuperRewrite, ldRetrCfgIntrprtKeys } from "./LDRetrieverSuper-rewrite";
export const imageRetrieverName = "metaexplorer.io/imageRetriever";
let ownKVLs = [
    {
        key: SideFXDict.srvURL,
        value: undefined,
        ldType: LDDict.Text
    },
    {
        key: SideFXDict.identifier,
        value: undefined,
        ldType: LDDict.Text
    },
    {
        key: LDDict.name,
        value: undefined,
        ldType: LDDict.Text
    },
    {
        key: LDDict.fileFormat,
        value: undefined,
        ldType: undefined
    },
    {
        key: LDDict.contentUrl,
        value: undefined,
        ldType: undefined
    }
];
let bpCfg = {
    subItptOf: null,
    nameSelf: imageRetrieverName,
    ownKVLs: ownKVLs,
    inKeys: ldRetrCfgIntrprtKeys,
    crudSkills: "cRud"
};
let ImageRetriever = class ImageRetriever extends LDRetrieverSuperRewrite {
    constructor() {
        super(...arguments);
        this.consumeLDOptionsLikeGDSFP = (ldOptions) => {
            let superRV = super.consumeLDOptionsLikeGDSFP(ldOptions);
            if (superRV) {
                let identifier = superRV.localValues.get(SideFXDict.identifier);
                if (identifier) {
                    let identifierResolved = resolveNS(identifier);
                    if (identifier !== identifierResolved) {
                        this.setState({ ...this.state, isInputDirty: true });
                        superRV.localValues.set(SideFXDict.identifier, identifierResolved);
                    }
                }
            }
            return superRV;
        };
    }
    /*setIdentifier = (value: KVL) => {
        let kvResolved: string = getKVValue(value);
        let changedSrvIdPart = resolveNS(kvResolved);
        if (changedSrvIdPart !== this.identifier) this.isInputDirty = true;
        this.identifier = changedSrvIdPart;
    }*/
    consumeLDOptions(ldOptions) {
        super.consumeLDOptions(ldOptions);
        /*if (!ldOptions || !ldOptions.resource || !ldOptions.resource.kvStores) return;
        super.consumeLDOptions(ldOptions);
        this.retrieverStoreKey = ldOptions.ldToken.get();
        let kvs = ldOptions.resource.kvStores;
        let identifier: KVL = kvs.find((val) => SideFXDict.identifier === val.key);
        let kvResolved: string = getKVValue(identifier);
        let changedSrvIdPart = resolveNS(kvResolved);
        let identifierKv = this.inputParams.get(SideFXDict.identifier);
        if (identifierKv && identifierKv.value && changedSrvIdPart !== identifierKv.value) this.isInputDirty = true;
        identifierKv.value = changedSrvIdPart;*/
        /*outputKVMap = outputKVMap ? outputKVMap : this.cfg.ownKVLs.find((val) => UserDefDict.outputKVMapKey === val.key);
        this.setOutputKVMap(outputKVMap && outputKVMap.value ? outputKVMap.value : this.outputKVMap);
        for (let idx = 0; idx < ldRetrCfgIntrprtKeys.length; idx++) {
            const inputKey = ldRetrCfgIntrprtKeys[idx];
            let param = kvs.find((val) => val.key === inputKey);
            if (param.value !== null) {
                this.inputParams.set(inputKey, param);
                this.isInputDirty = true;
            }
        }
        //this.setSrvUrl(srvUrlKv && srvUrlKv.value ? srvUrlKv.value : this.srvUrl);
        //this.setIdentifier(identifier && identifier.value !== null ? identifier : this.identifier);
        this.setWebContent(ldOptions);
        this.evalDirtyInput();
        this.evalDirtyOutput();*/
    }
};
ImageRetriever = __decorate([
    ldBlueprint(bpCfg)
], ImageRetriever);
export { ImageRetriever };
