import * as React from "react";
import { DefaultLabelModel } from "@projectstorm/react-diagrams";
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { SettingsLabelWidget } from "./SettingsLabelWidget";
/**
 * @author Jonathan Schneider
 */
export class SettingsLabelFactory extends AbstractReactFactory {
    constructor() {
        super("default");
    }
    generateReactWidget(event) {
        return React.createElement(SettingsLabelWidget, { model: event.model });
    }
    getNewInstance(initialConfig) {
        return new DefaultLabelModel();
    }
    generateModel(event) {
        return this.getNewInstance();
    }
}
