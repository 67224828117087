import { AbstractDataTransformer } from "../../datatransformation";
import { ldBlueprint, LDDict, UserDefDict, } from "../../ldaccess";
export const TEXT_TEMPLATE = "text/template";
export const TEXT_FRAGMENT_ONE = "text/fragment-one";
export const TEXT_FRAGMENT_TWO = "text/fragment-two";
export const TEXT_ALTERNATIVE = "text/alternative";
export const TEXT_FILLER_TYPE = "text/Textfiller-Type";
export const ARITHMETIC_OUTPUT_TYPE = LDDict.Text;
export const TextfillerName = "text/Textfiller";
export const textfillerItptKeys = [
    TEXT_TEMPLATE,
    TEXT_FRAGMENT_ONE,
    TEXT_FRAGMENT_TWO,
    TEXT_ALTERNATIVE,
];
export const TextfillerOutputKVs = [
    {
        key: UserDefDict.outputData,
        value: undefined,
        ldType: ARITHMETIC_OUTPUT_TYPE,
    },
];
const textfillerKVLs = [
    {
        key: TEXT_TEMPLATE,
        value: undefined,
        ldType: LDDict.Text,
    },
    {
        key: TEXT_FRAGMENT_ONE,
        value: undefined,
        ldType: undefined,
    },
    {
        key: TEXT_FRAGMENT_TWO,
        value: undefined,
        ldType: undefined,
    },
    {
        key: TEXT_ALTERNATIVE,
        value: undefined,
        ldType: undefined,
    },
    ...TextfillerOutputKVs,
];
let textfillerBpCfg = {
    subItptOf: null,
    nameSelf: TextfillerName,
    ownKVLs: textfillerKVLs,
    inKeys: textfillerItptKeys,
    canInterpretType: TEXT_FILLER_TYPE,
    crudSkills: "cRUd",
};
/***
 * replaces a {{0}} string with the first fragment, then {{1}} with the second and so forth
 */
class Textfiller extends AbstractDataTransformer {
    constructor() {
        super();
        this.itptKeys = textfillerItptKeys;
        this.outputKvStores = TextfillerOutputKVs;
    }
    mappingFunction(inputParams, outputKvStores) {
        let rv = [];
        const textFragment_one = inputParams.get(TEXT_FRAGMENT_ONE);
        const textFragment_two = inputParams.get(TEXT_FRAGMENT_TWO);
        const textAlternative = inputParams.get(TEXT_ALTERNATIVE);
        const textTemplate = inputParams.get(TEXT_TEMPLATE);
        const outputDataKV = outputKvStores.get(UserDefDict.outputData);
        let templatedString = textTemplate.value
            ? String(textTemplate.value)
            : "";
        if (textTemplate &&
            textTemplate.value &&
            textFragment_one &&
            textFragment_one.value &&
            textFragment_two &&
            textFragment_two.value) {
            const textFrags = [
                textFragment_one.value,
                textFragment_two.value,
            ];
            textFrags.forEach((val, idx) => {
                templatedString = templatedString.replaceAll(`{{${idx}}}`, val);
            });
        }
        outputDataKV.value =
            templatedString === textTemplate.value && textAlternative && textAlternative.value
                ? textAlternative.value
                : templatedString;
        rv = [outputDataKV];
        return rv;
    }
}
export const TextfillerBlock = ldBlueprint(textfillerBpCfg)(Textfiller);
//
