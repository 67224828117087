import React from 'react';
import { LDDict } from '../../../ldaccess/LDDict';
import { ldOptionsDeepCopy } from "../../../ldaccess/ldUtils";
import { Component } from "react";
import { UserDefDict } from "../../../ldaccess/UserDefDict";
import { getKVStoreByKey } from "../../../ldaccess/kvConvenienceFns";
import { gdsfpLD, initLDLocalState, determineSingleKVKey } from "../../generic/generatorFns";
import { debounce } from 'debounce';
export const LD_BASE_DATA_TYPE_INPUT_TYPES = [LDDict.Boolean, LDDict.Integer, LDDict.Double, LDDict.Text, LDDict.Date, LDDict.DateTime];
export const baseDataTypeBpcfgs = new Array();
for (var bdt in LD_BASE_DATA_TYPE_INPUT_TYPES) {
    if (LD_BASE_DATA_TYPE_INPUT_TYPES.hasOwnProperty(bdt)) {
        var elem = LD_BASE_DATA_TYPE_INPUT_TYPES[bdt];
        //let cfgType: string = LDDict.CreateAction;
        let cfgIntrprtKeys = [LDDict.description, UserDefDict.inputData];
        let ownKVLs = [
            {
                key: LDDict.description,
                value: undefined,
                ldType: LDDict.Text
            },
            {
                key: UserDefDict.inputData,
                value: undefined,
                ldType: elem
            },
            {
                key: UserDefDict.outputData,
                value: undefined,
                ldType: elem
            },
        ];
        let bpCfg = {
            subItptOf: undefined,
            canInterpretType: elem,
            nameSelf: "metaexplorer.io/core/" + elem,
            ownKVLs: ownKVLs,
            inKeys: cfgIntrprtKeys,
            crudSkills: "CRUd"
        };
        baseDataTypeBpcfgs.push(bpCfg);
    }
}
export class AbstractBaseDataTypeInput extends Component {
    constructor(props) {
        super(props);
        this.dispatchDebounced = debounce((modSingleKV, ldTokenString, outputKVMap) => {
            this.setState({ ...this.state, singleKVInput: modSingleKV, isDispatched: true });
            this.props.dispatchKvOutput([modSingleKV], ldTokenString, outputKVMap);
        }, 200);
        this.consumeLDOptions = (ldOptions) => {
            return;
        };
        this.handleChange = (evtval) => {
            let singleInputKey = this.state.singleKVInputKey;
            let modSingleKVOutput = {
                key: this.state.singleKVOutputKey,
                ldType: this.state.localLDTypes.get(singleInputKey),
                value: this.state.localValues.get(singleInputKey)
            };
            if (typeof evtval === 'string') {
                switch (modSingleKVOutput.ldType) {
                    case LDDict.Boolean:
                        evtval = (evtval === 'true');
                        break;
                    case LDDict.Integer:
                        evtval = parseInt(evtval, 10);
                        break;
                    case LDDict.Double:
                        evtval = parseFloat(evtval);
                        break;
                    case LDDict.Date:
                        evtval = new Date(evtval);
                        break;
                    case LDDict.DateTime:
                        evtval = new Date(evtval);
                        break;
                    default:
                        break;
                }
            }
            modSingleKVOutput.value = evtval;
            const modSingleKvInput = this.state.singleKVInput;
            modSingleKvInput.value = evtval;
            this.setState({ ...this.state, singleKVInput: modSingleKvInput, isDispatched: false }, () => {
                const outputKVMap = this.state.localValues.get(UserDefDict.outputKVMapKey);
                if (!outputKVMap || !outputKVMap[this.state.singleKVOutputKey])
                    return;
                this.dispatchDebounced(modSingleKVOutput, this.props.ldTokenString, outputKVMap);
            });
        };
        this.cfg = this.constructor["cfg"];
        let bdtState = {
            singleKVInput: null,
            singleKVInputKey: UserDefDict.inputData,
            singleKVOutputKey: UserDefDict.outputData,
            isDispatched: true
        };
        this.state = {
            ...bdtState,
            ...initLDLocalState(this.cfg, props, [], [LDDict.description, UserDefDict.inputData, UserDefDict.outputKVMapKey])
        };
        this.state = {
            ...this.state, singleKVInput: {
                key: this.state.singleKVOutputKey,
                value: this.state.localValues.get(this.state.singleKVInputKey),
                ldType: this.state.localLDTypes.get(this.state.singleKVInputKey)
            }
        };
    }
    componentDidMount() {
        if (!this.state.singleKVInput || !this.state.singleKVInput.ldType) {
            //this self-creates an object. Used e.g. in the itpt-editor, bdt-part
            if (this.props.ldOptions) {
                let newLDOptionsObj = ldOptionsDeepCopy(this.props.ldOptions);
                let kvStoreIdx = newLDOptionsObj.resource.kvStores.findIndex((a) => {
                    return UserDefDict.singleKvStore.toString() === a.key;
                });
                let singleKv;
                if (kvStoreIdx === -1) {
                    singleKv = { key: UserDefDict.singleKvStore, value: null, ldType: this.cfg.canInterpretType };
                    newLDOptionsObj.resource.kvStores.push(singleKv);
                    this.props.notifyLDOptionsChange(newLDOptionsObj);
                }
            }
            else {
                this.props.notifyLDOptionsChange(null);
            }
        }
    }
    render() {
        return React.createElement(React.Fragment, null, this.renderSingleKv(this.cfg.canInterpretType));
    }
    renderSingleKv(baseDT) {
        throw new Error("Method not implemented in abstract class");
    }
}
export function wrapBaseDataTypeGDSF(cfg) {
    return (nextProps, prevState) => {
        if (!prevState.isDispatched) {
            //user input takes precedence, dispatching that before handling other input!
            return null;
        }
        let rvLD = gdsfpLD(nextProps, prevState, [], [LDDict.description, UserDefDict.inputData, UserDefDict.outputKVMapKey], cfg.canInterpretType);
        if (!rvLD)
            return null;
        let rvLocal = null;
        if (nextProps.ldOptions) {
            let pLdOpts = nextProps.ldOptions;
            let newSingleKVInputKey = determineSingleKVKey(pLdOpts.resource.kvStores, cfg.canInterpretType, cfg.inKeys);
            let nextDescription = rvLD.localValues.get(LDDict.description);
            let nextSingleInputKV = getKVStoreByKey(pLdOpts.resource.kvStores, newSingleKVInputKey);
            if (!nextSingleInputKV) {
                //this happens when state changes let this comp re-evaluate before it's being replaced. Do nothing
                return null;
            }
            let desc = nextDescription ? nextDescription : newSingleKVInputKey;
            rvLD.localLDTypes.set(newSingleKVInputKey, nextSingleInputKV.ldType);
            rvLD.localValues.set(newSingleKVInputKey, nextSingleInputKV.value);
            rvLD.localLDTypes.set(LDDict.description, LDDict.Text);
            rvLD.localValues.set(LDDict.description, desc);
            let singleKVOutputKeyStr = UserDefDict.outputData;
            let outputKvMap = getKVStoreByKey(pLdOpts.resource.kvStores, UserDefDict.outputKVMapKey);
            //i.e. inputKvStore is not set or it's dynamically generated
            if (newSingleKVInputKey !== UserDefDict.inputData)
                singleKVOutputKeyStr = newSingleKVInputKey;
            if (outputKvMap && outputKvMap.value) {
                if (outputKvMap.value.hasOwnProperty(UserDefDict.outputData)) {
                    //unless outputData is explicitly set
                    singleKVOutputKeyStr = UserDefDict.outputData;
                }
            }
            rvLocal = { singleKVInput: nextSingleInputKV, singleKVInputKey: newSingleKVInputKey, singleKVOutputKey: singleKVOutputKeyStr, isDispatched: true };
        }
        if (!rvLocal)
            return null;
        return { ...prevState, ...rvLD, ...rvLocal };
    };
}
