export var LDConsts;
(function (LDConsts) {
    //json-ld
    LDConsts["type"] = "@type";
    LDConsts["id"] = "@id";
    LDConsts["lang"] = "@lang";
    //hydra
    LDConsts["isA"] = "isA";
    LDConsts["iri"] = "iri";
})(LDConsts || (LDConsts = {}));
export var LDApiConst;
(function (LDApiConst) {
    LDApiConst["baseUrl"] = "ldui.net";
    LDApiConst["apiEndpoint"] = "/api";
})(LDApiConst || (LDApiConst = {}));
