var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from 'react';
import { LDDict, ldBlueprint, VisualKeysDict, gdsfpLD, initLDLocalState, ActionKeysDict, generateItptFromCompInfo, LDUIDictVerbs, ActionTypesDict, LDUIDict, UserDefDict, NetworkPreferredToken, } from '@metaexplorer/core';
import { Component } from 'react';
import { Drawer } from '@material-ui/core';
export const MDSideSheetName = "metaexplorer.io/material-design/SideSheet";
const cfgItptInputKeys = [
    VisualKeysDict.primaryItpt,
];
let cfgRegularInputKeys = [
    LDUIDictVerbs.isOpen,
    LDUIDictVerbs.isModal,
    ActionKeysDict.action_close,
    LDUIDictVerbs.anchor,
];
let ownKVLs = [
    {
        key: VisualKeysDict.primaryItpt,
        value: undefined,
        ldType: UserDefDict.intrprtrClassType
    },
    {
        key: LDUIDictVerbs.isOpen,
        value: undefined,
        ldType: LDDict.Boolean
    },
    {
        key: LDUIDictVerbs.isModal,
        value: undefined,
        ldType: LDDict.Boolean
    },
    {
        key: ActionKeysDict.action_close,
        value: undefined,
        ldType: ActionTypesDict.metaExplorerAction
    },
    {
        key: LDUIDictVerbs.anchor,
        value: undefined,
        ldType: undefined // TODO: one of LDUIDict.Top/Right/Bottom/Left
    },
    //output
    {
        key: UserDefDict.outputData,
        value: undefined,
        ldType: LDDict.Boolean
    },
];
let bpCfg = {
    subItptOf: null,
    nameSelf: MDSideSheetName,
    ownKVLs: ownKVLs,
    inKeys: [...cfgItptInputKeys, ...cfgRegularInputKeys],
    crudSkills: "cRud"
};
let PureMDSideSheet = class PureMDSideSheet extends Component {
    constructor(props) {
        super(props);
        this.renderSub = generateItptFromCompInfo.bind(this);
        this.setIsDrawerOpen = (isOpen) => {
            this.setState({
                ...this.state,
                isOpen
            }, () => {
                const closeAction = this.state.localValues.get(ActionKeysDict.action_close);
                const outputKVMap = this.state.localValues.get(UserDefDict.outputKVMapKey);
                const isOpenOutputKey = UserDefDict.outputData;
                if (!outputKVMap || !outputKVMap[isOpenOutputKey])
                    return;
                if (outputKVMap[isOpenOutputKey].findIndex((val) => (val.targetLDToken.getClientTokenVal() === this.props.ldTokenString
                    && val.targetProperty === LDUIDictVerbs.isOpen)) === -1) {
                    outputKVMap[isOpenOutputKey].push({
                        targetLDToken: new NetworkPreferredToken(this.props.ldTokenString),
                        targetProperty: LDUIDictVerbs.isOpen
                    });
                }
                this.props.dispatchKvOutput([{
                        key: isOpenOutputKey,
                        value: isOpen,
                        ldType: undefined
                    }], this.props.ldTokenString, outputKVMap);
                if (closeAction) {
                    this.props.dispatchLdAction(closeAction.ldId, closeAction.ldType, closeAction.payload);
                }
            });
        };
        this.cfg = this.constructor["cfg"];
        const sideSheetStatePart = {
            isOpen: false
        };
        this.state = {
            ...sideSheetStatePart,
            ...initLDLocalState(this.cfg, props, [...cfgItptInputKeys], [...cfgRegularInputKeys, UserDefDict.outputKVMapKey])
        };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        let rvLD = gdsfpLD(nextProps, prevState, [...cfgItptInputKeys], [...cfgRegularInputKeys, UserDefDict.outputKVMapKey]);
        if (!rvLD) {
            return null;
        }
        const isOpen = rvLD.localValues.get(LDUIDictVerbs.isOpen);
        return {
            ...prevState,
            isOpen,
            ...rvLD
        };
    }
    render() {
        const { localValues, compInfos, isOpen } = this.state;
        const hasPrimaryContent = compInfos.has(VisualKeysDict.primaryItpt);
        const anchorLD = localValues.get(LDUIDictVerbs.anchor);
        const isModal = localValues.get(LDUIDictVerbs.isModal);
        //allow shorthand values
        let anchor = (["top", "right", "bottom", "left"].find((val) => val === anchorLD));
        anchor = anchor ? anchor : "left";
        switch (anchorLD) {
            case LDUIDict.Top:
                anchor = "top";
                break;
            case LDUIDict.Right:
                anchor = "right";
                break;
            case LDUIDict.Bottom:
                anchor = "bottom";
                break;
            case LDUIDict.Left:
                anchor = "left";
                break;
            default:
                break;
        }
        return this.renderSideSheet(isOpen, hasPrimaryContent, anchor, isModal);
    }
    /**
     * in a separate render function to eventually turn it into an abstract component
     */
    renderSideSheet(isOpen, hasPrimaryContent, anchor, isModal) {
        this.renderSub(VisualKeysDict.primaryItpt);
        return React.createElement(Drawer, { variant: isModal ? "temporary" : "permanent", anchor: anchor ? anchor : 'left', open: !!isOpen, onClose: () => this.setIsDrawerOpen(false) }, hasPrimaryContent && this.renderSub(VisualKeysDict.primaryItpt));
    }
};
PureMDSideSheet = __decorate([
    ldBlueprint(bpCfg)
], PureMDSideSheet);
export { PureMDSideSheet };
