var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React, { Component, useEffect, useRef } from "react";
import { ldBlueprint } from "../../ldaccess/ldBlueprint";
import { LDDict, UserDefDict, } from "../../ldaccess";
import { VisualKeysDict } from "../visualcomposition";
import { ActionKeysDict, ActionTypesDict } from "../actions";
import useIntersectionObserver from "./intersector-hook";
import { gdsfpLD, initLDLocalState } from "../generic";
const VISIBLE = "visible";
const DividerHR = (props) => {
    const hrRef = useRef(null);
    const [isVisible /*entry*/] = useIntersectionObserver({
        elementRef: hrRef,
    });
    useEffect(() => props.onVisibilityChanged && props.onVisibilityChanged(isVisible), [isVisible, !!props.onVisibilityChanged]);
    const compProps = {};
    if (props) {
        if (props.className)
            compProps["className"] = props.className;
        if (props.elementId)
            compProps["id"] = props.elementId;
    }
    return React.createElement("hr", Object.assign({ ref: hrRef }, compProps));
};
let cfgIntrprtKeys = [
    VisualKeysDict.elementId,
    VisualKeysDict.cssClassName,
    ActionKeysDict.action_visibility_change
];
let ownKVLs = [
    {
        key: VisualKeysDict.elementId,
        value: undefined,
        ldType: LDDict.Text
    },
    {
        key: VisualKeysDict.cssClassName,
        value: undefined,
        ldType: LDDict.Text,
    },
    {
        key: ActionKeysDict.action_visibility_change,
        value: undefined,
        ldType: ActionTypesDict.metaExplorerAction,
    },
    {
        key: VISIBLE,
        value: undefined,
        ldType: LDDict.Text,
    },
];
export const ANALYTICS_HR_NAME = "metaexplorer.io/analytics/HRVisibilityDetector";
const hrBpCfg = {
    subItptOf: null,
    nameSelf: ANALYTICS_HR_NAME,
    ownKVLs: ownKVLs,
    inKeys: cfgIntrprtKeys,
    crudSkills: "cRud",
    canInterpretType: ANALYTICS_HR_NAME + UserDefDict.standardItptObjectTypeSuffix,
};
export const HR_IFRAME_CFG = { ...hrBpCfg };
let PureHRAnalyticsComponent = class PureHRAnalyticsComponent extends Component {
    constructor(props) {
        super(props);
        this.onTrigger = (isVisible) => {
            let visibilityAction = this.state.localValues.get(ActionKeysDict.action_visibility_change);
            if (visibilityAction) {
                if (Array.isArray(visibilityAction.payload) &&
                    visibilityAction.payload.length === 1) {
                    visibilityAction.payload = isVisible; // visibilityAction.payload[0];
                }
                this.props.dispatchLdAction(visibilityAction.ldId, visibilityAction.ldType, visibilityAction.payload);
            }
            const outputKVMap = this.state.localValues.get(UserDefDict.outputKVMapKey);
            if (!outputKVMap)
                return;
            let outCurItptKV = {
                key: VISIBLE,
                value: isVisible,
                ldType: LDDict.Text
            };
            this.props.dispatchKvOutput([outCurItptKV], this.props.ldTokenString, outputKVMap);
        };
        this.cfg = this.constructor["cfg"];
        const ldState = initLDLocalState(this.cfg, props, [], [...cfgIntrprtKeys, UserDefDict.outputKVMapKey]);
        this.state = {
            ...ldState,
        };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        let rvLD = gdsfpLD(nextProps, prevState, [], [...cfgIntrprtKeys, UserDefDict.outputKVMapKey], null);
        if (!rvLD) {
            return null;
        }
        let rvNew = { ...rvLD };
        return {
            ...rvNew,
        };
    }
    render() {
        const { localValues } = this.state;
        const cssClassName = localValues.get(VisualKeysDict.cssClassName);
        const elementId = localValues.get(VisualKeysDict.elementId);
        return React.createElement(DividerHR, { onVisibilityChanged: (v) => this.onTrigger(v), elementId: elementId, className: cssClassName });
    }
};
PureHRAnalyticsComponent = __decorate([
    ldBlueprint(HR_IFRAME_CFG)
], PureHRAnalyticsComponent);
export { PureHRAnalyticsComponent };
