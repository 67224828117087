var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LDDict, ldBlueprint, CameraSwitcherTabs, UserDefDict, initLDLocalState, } from "@metaexplorer/core";
import { QrCodeGenScanClientAPI } from "../apis/qr-code-genscan-api";
import { Component } from "react";
import React from "react";
export var EANScannerStateEnum;
(function (EANScannerStateEnum) {
    EANScannerStateEnum[EANScannerStateEnum["isLoading"] = 2] = "isLoading";
    EANScannerStateEnum[EANScannerStateEnum["isError"] = 3] = "isError";
    EANScannerStateEnum[EANScannerStateEnum["isScanning"] = 4] = "isScanning";
})(EANScannerStateEnum || (EANScannerStateEnum = {}));
export const EANScannerName = "metaexplorer.io/EANScanner";
let cfgType = LDDict.ViewAction;
let cfgIntrprtKeys = [];
let ownKVLs = [
    {
        key: LDDict.gtin8,
        value: undefined,
        ldType: LDDict.Text,
    },
];
let bpCfg = {
    subItptOf: null,
    canInterpretType: cfgType,
    nameSelf: EANScannerName,
    ownKVLs: ownKVLs,
    inKeys: cfgIntrprtKeys,
    crudSkills: "cRud",
};
let EANScanner = class EANScanner extends Component {
    constructor(props) {
        super(props);
        this.loadingImgLink = "/media/camera_negative_black.svg";
        this.errorImgLink = "/media/nocamera_negative_black.svg";
        this.onBarCodeDetected = (result) => {
            const outputKVMap = this.state.localValues.get(UserDefDict.outputKVMapKey);
            if (!outputKVMap)
                return;
            let barcode = result.codeResult.code;
            const barcodeKV = {
                key: LDDict.gtin8,
                value: barcode,
                ldType: LDDict.Text,
            };
            this.props.dispatchKvOutput([barcodeKV], this.props.ldTokenString, outputKVMap);
        };
        this.cfg = this.constructor["cfg"];
        const ldState = initLDLocalState(this.cfg, props, [], [UserDefDict.outputKVMapKey]);
        this.state = {
            ...ldState,
            vidDeviceList: null,
            curId: null,
            curStep: EANScannerStateEnum.isLoading,
        };
    }
    componentDidMount() {
        if (!navigator.mediaDevices ||
            !navigator.mediaDevices.enumerateDevices) {
            this.setStateToError();
            return;
        }
        navigator.mediaDevices
            .enumerateDevices()
            .then((devices) => {
            let vidInputList = [];
            devices.forEach((device) => {
                if (device.kind === "videoinput")
                    vidInputList.push(device);
            });
            if (vidInputList.length === 0) {
                this.setStateToError();
                return;
            }
            else {
                const deviceId = vidInputList[0].deviceId;
                this.setState({
                    ...this.state,
                    curId: deviceId,
                    curStep: EANScannerStateEnum.isLoading,
                    vidDeviceList: vidInputList,
                });
                this.initQuagga(deviceId);
                return;
            }
        })
            .catch((err) => {
            this.setStateToError();
            return;
        });
    }
    async initQuagga(deviceId) {
        if (!("Quagga" in window)) {
            try {
                const qrAPI = await QrCodeGenScanClientAPI.getQrCodeGenScanAPISingleton();
                await qrAPI.initQuaggaScriptLoad();
                this.startQuagga(deviceId);
            }
            catch (e) {
                throw e;
            }
            return;
        }
        this.startQuagga(deviceId);
    }
    startQuagga(deviceId) {
        Quagga.init({
            inputStream: {
                type: "LiveStream",
                constraints: {
                    width: 1024,
                    height: 1024,
                    deviceId: deviceId,
                    facingMode: "environment",
                },
            },
            debug: true,
            locator: {
                patchSize: "medium",
                halfSample: true,
            },
            numOfWorkers: 4,
            decoder: {
                readers: ["ean_reader"],
            },
            locate: true,
        }, (err) => {
            if (err) {
                this.setStateToError();
                return;
            }
            this.setState({
                ...this.state,
                curStep: EANScannerStateEnum.isScanning,
            });
            Quagga.start();
        });
        Quagga.onDetected(this.onBarCodeDetected);
    }
    setStateToError() {
        this.setState({
            ...this.state,
            vidDeviceList: null,
            curId: null,
            curStep: EANScannerStateEnum.isError,
        });
    }
    componentWillUnmount() {
        if (!Quagga)
            return;
        Quagga.offDetected(this.onBarCodeDetected);
        if (this.state.curStep !== EANScannerStateEnum.isError &&
            this.state.curId !== null) {
            Quagga.stop();
        }
        this.setState({
            ...this.state,
            curStep: EANScannerStateEnum.isLoading,
            vidDeviceList: null,
            curId: null,
        });
    }
    render() {
        let stateVisLnk = this.loadingImgLink;
        const { curStep, curId, vidDeviceList } = this.state;
        let isDisplayImage = true;
        const isMultiVidSource = vidDeviceList && vidDeviceList.length > 1;
        switch (curStep) {
            case EANScannerStateEnum.isError:
                stateVisLnk = this.errorImgLink;
                break;
            case EANScannerStateEnum.isScanning:
                isDisplayImage = false;
                break;
            default:
                break;
        }
        return (React.createElement("div", { className: "md-barcode-reader" },
            React.createElement("div", { id: "interactive", className: "viewport" }),
            isDisplayImage ? (React.createElement("img", { className: "md-large-image", src: stateVisLnk, height: "100px" })) : isMultiVidSource ? (React.createElement(CameraSwitcherTabs, { activeCameraId: curId, vidDeviceList: vidDeviceList, onTabChanged: (newActiveId) => {
                    Quagga.offDetected(this.onBarCodeDetected);
                    Quagga.stop();
                    this.setState({
                        ...this.state,
                        curId: newActiveId,
                        curStep: EANScannerStateEnum.isLoading,
                    });
                    this.initQuagga(newActiveId);
                } })) : null));
    }
};
EANScanner = __decorate([
    ldBlueprint(bpCfg)
], EANScanner);
export { EANScanner };
