import { initLDLocalState, gdsfpLD, generateItptFromCompInfo } from '../../../components/generic/generatorFns';
import { Component } from 'react';
import { LDDict } from '../../../ldaccess/LDDict';
import { UserDefDict } from '../../../ldaccess/UserDefDict';
export const SectionedListName = "metaexplorer.io/material-design/SectionedList";
export const sectionHeadings = "section-headings";
export const sectionElements = "section-elements";
export const sectionedListItptKeys = [sectionElements];
export const sectionedListValueKeys = [sectionHeadings];
export const sectionedListInputKeys = [...sectionedListValueKeys, ...sectionedListItptKeys];
export const ownKVLs = [
    { key: sectionHeadings, value: undefined, ldType: LDDict.Text },
    { key: sectionElements, value: undefined, ldType: UserDefDict.intrprtrClassType }
];
export const SectionedListCfg = {
    subItptOf: null,
    nameSelf: SectionedListName,
    ownKVLs: ownKVLs,
    inKeys: sectionedListInputKeys,
    crudSkills: "cRud"
};
export class AbstractSectionedList extends Component {
    constructor(props) {
        super(props);
        this.renderSub = generateItptFromCompInfo.bind(this);
        this.cfg = this.constructor["cfg"];
        const ldState = initLDLocalState(this.cfg, props, sectionedListItptKeys, sectionedListValueKeys, [true], [true]);
        this.state = { ...ldState };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        let rvLD = gdsfpLD(nextProps, prevState, sectionedListItptKeys, sectionedListValueKeys, null, [true], [true]);
        if (!rvLD) {
            return null;
        }
        let rvNew = { ...rvLD };
        return { ...prevState, ...rvNew };
    }
    render() {
        throw new Error("Method not implemented in abstract class");
    }
}
