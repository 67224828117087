import { DefaultPortLabel } from "@projectstorm/react-diagrams";
import { map } from 'lodash';
import React from "react";
export const TXT_EXPLORE = "explore";
/**
 * @author Jonathan Schneider
 */
export class GeneralDataTypeNodeWidget extends React.Component {
    constructor(props) {
        super(props);
        // super(GENERALDATATYPE_MODEL, props);
        this.state = {};
    }
    generatePort(port) {
        return React.createElement(DefaultPortLabel, { engine: this.props.engine, port: port, key: port.getID() });
    }
    render() {
        const className = `basic-node ${this.props.node.isSelected() ? 'selected' : ''}`;
        return (React.createElement("div", { className: className, style: { background: this.props.node.getColor() } },
            React.createElement("div", { className: "title" },
                React.createElement("div", { className: "name" }, this.props.node.getSubItptOf())),
            React.createElement("div", { className: "ports" },
                React.createElement("div", { className: "in" }, map(this.props.node.getInPorts(), this.generatePort.bind(this))),
                React.createElement("div", { className: "out" }, map(this.props.node.getOutPorts(), this.generatePort.bind(this)))),
            this.props.node.getIsCompound()
                ? React.createElement("button", { onClick: (ev) => this.props.node.onExploreBtnClicked(), className: "editor-btn editor-btn-explore" }, TXT_EXPLORE)
                : null));
    }
}
// export var GeneralDataTypeNodeWidgetFactory = createFactory(GeneralDataTypeNodeWidget);
