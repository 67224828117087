import * as React from 'react';
import { DropContainer } from 'metaexplorer-react-components';
import { EditorDNDItemType } from '../editorInterfaces';
const itptEditorDropContainerProps = {
    onlyAppearOnDrag: true,
    acceptedItemTypes: [EditorDNDItemType.block],
    className: 'editor-tabs-dropcontainer'
};
export function TabDropLayer(props) {
    return (React.createElement(DropContainer, Object.assign({}, itptEditorDropContainerProps, { onItemDropped: (item, pos) => {
            props.onDrop(item, pos.left, pos.top);
        }, style: { backgroundColor: 'blue', opacity: 0.0 } })));
}
