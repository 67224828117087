var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ldBlueprint, BottomNavW5ChoicesBpCfg, AbstractBottomNavigation, cleanRouteString, classNamesLD } from '@metaexplorer/core';
import { Redirect, Route } from 'react-router';
import { BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import React from 'react';
let MDBottomNavigation = class MDBottomNavigation extends AbstractBottomNavigation {
    generateTab(imgSrcActive, imgSrcInActive, route, isActive, key, label) {
        //const mustRedirect = match && isActive && (match.params.lastPath !== undefined || match.params.lastPath !== null) && match.params.lastPath !== route;
        let icon = null;
        if (imgSrcActive && imgSrcInActive) {
            if (isActive) {
                icon = React.createElement("img", { src: imgSrcActive, style: { height: "24px" } });
            }
            else {
                icon = React.createElement("img", { src: imgSrcInActive, style: { height: "24px" } });
            }
        }
        return React.createElement(BottomNavigationAction, { key: key, label: label, icon: icon });
        /*<Tab label='' key={key} className="bottom-nav-tab" icon={isActive
            ? <img src={imgSrcActive} style={{ height: "inherit" }} />
            : <img src={imgSrcInActive} style={{ height: "inherit" }} />}>
        </Tab>;*/
    }
    generateRedirect(tabIdx) {
        if (!this.props.routes || !this.state.hasTabChanged)
            return null;
        const { location } = this.props.routes;
        let cleanedTabIdx = tabIdx;
        for (let idx = tabIdx; idx >= 0; idx--) {
            if (!this.state.isGenerateAtPositions[idx]) {
                cleanedTabIdx++;
            }
        }
        let route = this.state.routes[cleanedTabIdx];
        //if (match.params.nextPath === undefined) match.params.nextPath = route;
        let newPath = cleanRouteString(route, this.props.routes);
        this.setState({ ...this.state, hasTabChanged: false });
        if (location.pathname === newPath)
            return null;
        return React.createElement(Redirect, { to: newPath });
    }
    render() {
        const { numTabs, isGenerateAtPositions, localValues, iconEnabledURLs, iconDisabledURLs, labels, routes, tabIdx } = this.state;
        let tabs = [];
        let cleanedTabIdx = tabIdx;
        for (let idx = 0; idx < numTabs; idx++) {
            const isGen = isGenerateAtPositions[idx];
            if (!isGen) {
                cleanedTabIdx++;
                continue;
            }
            let newTab = this.generateTab(iconEnabledURLs[idx], iconDisabledURLs[idx], routes[idx], cleanedTabIdx === idx, "t-" + idx, labels[idx]);
            tabs.push(newTab);
        }
        return React.createElement("div", { className: classNamesLD("bottom-nav", localValues) },
            React.createElement("div", { className: "bottom-nav-topfree mdscrollbar" },
                tabs.length > 0 ? this.generateRedirect(tabIdx) : null,
                React.createElement(Route, { component: this.renderInputContainer }),
                this.props.children),
            React.createElement(BottomNavigation, { value: tabIdx, onChange: (event, newValue) => {
                    this.onTabChanged(newValue);
                }, showLabels: true }, tabs));
        /*
        <div className={classNamesLD("bottom-nav", localValues)}>
            <div className="bottom-nav-topfree mdscrollbar">
                {this.generateRedirect(tabIdx)}
                <Route component={this.renderInputContainer} />
                {this.props.children}
            </div>
            <Tabs index={tabIdx} onChange={this.onTabChanged} fixed className="bottom-nav-tabs">
                {tabs}
            </Tabs>
        </div>;
        */
    }
};
MDBottomNavigation = __decorate([
    ldBlueprint(BottomNavW5ChoicesBpCfg)
], MDBottomNavigation);
export { MDBottomNavigation };
