var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { AbstractDataTransformer } from "../../datatransformation/abstractDataTransformer";
import { UserDefDict } from "../../ldaccess/UserDefDict";
import { ActionKeysDict } from "./ActionDict";
import { LDDict } from "../../ldaccess/LDDict";
import { ldBlueprint } from "../../ldaccess/ldBlueprint";
import { getApplicationStore } from "../../approot";
import { registerIdActionHandlerAction, registerTypeActionHandlerAction } from "../../appstate/epicducks/action-duck";
export const handleTypeKey = ActionKeysDict.canHandleType;
export const handleIdKey = ActionKeysDict.canHandleId;
export const payloadOutputKey = UserDefDict.outputData;
export const ActionHandlerName = "ActionHandler";
export const ActionHandlerKeys = [handleTypeKey, handleIdKey];
export const ActionHandlerOutputKVs = [
    {
        key: payloadOutputKey,
        value: undefined,
        ldType: undefined
    }
];
export const ActionHandlerInputKVs = [
    {
        key: handleTypeKey,
        value: undefined,
        ldType: LDDict.Text
    },
    {
        key: handleIdKey,
        value: undefined,
        ldType: LDDict.Text
    }
];
const ownKVLs = [
    ...ActionHandlerInputKVs,
    ...ActionHandlerOutputKVs
];
let bpCfg = {
    subItptOf: null,
    nameSelf: ActionHandlerName,
    ownKVLs: ownKVLs,
    inKeys: ActionHandlerKeys,
    crudSkills: "cRUd"
};
let ActionHandler = class ActionHandler extends AbstractDataTransformer {
    constructor(ldTkStr) {
        super(ldTkStr);
        this.itptKeys = [ActionKeysDict.action_internal, ...ActionHandlerKeys];
        this.outputKvStores = ActionHandlerOutputKVs;
        let typeKv = this.cfg.ownKVLs.find((val) => val.key === handleTypeKey);
        let idKv = this.cfg.ownKVLs.find((val) => val.key === handleIdKey);
        this.triggerRegisterIfNecessary(typeKv, idKv);
    }
    triggerRegisterIfNecessary(typeKv, idKv) {
        if (idKv && !!idKv.value) {
            getApplicationStore().dispatch(registerIdActionHandlerAction(idKv.value, this.ldTkStr));
        }
        if (typeKv && !!typeKv.value) {
            getApplicationStore().dispatch(registerTypeActionHandlerAction(typeKv.value, this.ldTkStr));
        }
    }
    mappingFunction(inputParams, outputKvStores) {
        let rv = [];
        let handleTypeInputKv = inputParams.get(handleTypeKey);
        let handleIdInputKv = inputParams.get(handleIdKey);
        let internalActionKv = inputParams.get(ActionKeysDict.action_internal);
        if (((handleTypeInputKv && handleTypeInputKv.value) || (handleIdInputKv && handleIdInputKv.value)) && internalActionKv && internalActionKv.value) {
            const payload = internalActionKv.value;
            const transfOutputKV = outputKvStores.get(payloadOutputKey);
            transfOutputKV.value = payload;
            rv = [transfOutputKV];
        }
        return rv;
    }
};
ActionHandler = __decorate([
    ldBlueprint(bpCfg)
], ActionHandler);
export { ActionHandler };
