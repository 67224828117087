import { UserDefDict } from '../../../ldaccess/UserDefDict';
import { VisualKeysDict } from '../../visualcomposition/visualDict';
import { generateItptFromCompInfo, initLDLocalState, gdsfpLD } from '../../generic/generatorFns';
import { Component } from 'react';
import { LDDict } from '../../../ldaccess/LDDict';
export const NavBarInputContainerName = "metaexplorer.io/material-design/NavBarInputContainer";
let cfgIntrprtItptKeys = [
    VisualKeysDict.inputContainer,
    VisualKeysDict.primaryItpt
];
let cfgIntrprtValueKeys = [
    VisualKeysDict.cssClassName
];
let cfgIntrprtKeys = [...cfgIntrprtItptKeys, ...cfgIntrprtValueKeys];
let ownKVLs = [
    {
        key: VisualKeysDict.inputContainer,
        value: undefined,
        ldType: UserDefDict.intrprtrClassType
    },
    {
        key: VisualKeysDict.primaryItpt,
        value: undefined,
        ldType: UserDefDict.intrprtrClassType
    },
    {
        key: VisualKeysDict.cssClassName,
        value: undefined,
        ldType: LDDict.Text
    }
];
export const NavBarInputContainerBpCfg = {
    subItptOf: null,
    nameSelf: NavBarInputContainerName,
    ownKVLs: ownKVLs,
    inKeys: cfgIntrprtKeys,
    crudSkills: "cRud"
};
export class AbstractNavBarInputContainer extends Component {
    constructor(props) {
        super(props);
        this.renderSub = generateItptFromCompInfo.bind(this);
        this.cfg = this.constructor["cfg"];
        let navBarStatePart = {};
        this.state = {
            ...navBarStatePart,
            ...initLDLocalState(this.cfg, props, cfgIntrprtItptKeys, cfgIntrprtValueKeys)
        };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        let rvLD = gdsfpLD(nextProps, prevState, cfgIntrprtItptKeys, cfgIntrprtValueKeys);
        if (!rvLD) {
            return null;
        }
        return {
            ...prevState, ...rvLD
        };
    }
    render() {
        throw new Error("Method not implemented in abstract class");
    }
}
