import { Component } from 'react';
import { UserDefDict } from '../../../ldaccess/UserDefDict';
import { initLDLocalState, generateItptFromCompInfo, gdsfpLD } from '../../../components/generic/generatorFns';
import { VisualTypesDict, VisualKeysDict } from '../../../components/visualcomposition/visualDict';
import { LDDict } from '../../../ldaccess/LDDict';
export const CompactInfoListElementName = "metaexplorer.io/CompactInfoListElement";
let CompactInfoListElementItptKeys = [VisualKeysDict.primaryItpt, VisualKeysDict.secondaryItpt];
let compactInfoListElementValueKeys = [VisualKeysDict.headerTxt, VisualKeysDict.subHeaderTxt];
let CompactInfoListElementInputKeys = [...CompactInfoListElementItptKeys, ...compactInfoListElementValueKeys];
let ownKVLs = [
    { key: VisualKeysDict.primaryItpt, value: undefined, ldType: UserDefDict.intrprtrClassType },
    { key: VisualKeysDict.secondaryItpt, value: undefined, ldType: UserDefDict.intrprtrClassType },
    { key: VisualKeysDict.headerTxt, value: undefined, ldType: LDDict.Text },
    { key: VisualKeysDict.subHeaderTxt, value: undefined, ldType: LDDict.Text },
];
export const CompactInfoListElementCfg = {
    subItptOf: null,
    nameSelf: CompactInfoListElementName,
    ownKVLs: ownKVLs,
    inKeys: CompactInfoListElementInputKeys,
    crudSkills: "cRud",
    canInterpretType: VisualTypesDict.compactInfoElement
};
export class AbstractCompactInfoListElement extends Component {
    constructor(props) {
        super(props);
        this.renderSub = generateItptFromCompInfo.bind(this);
        this.cfg = this.constructor["cfg"];
        const ldState = initLDLocalState(this.cfg, props, CompactInfoListElementItptKeys, compactInfoListElementValueKeys);
        this.state = { ...ldState };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        let rvNew = gdsfpLD(nextProps, prevState, CompactInfoListElementItptKeys, compactInfoListElementValueKeys, CompactInfoListElementCfg.canInterpretType, [false, false], [false, false]);
        return { ...prevState, ...rvNew, };
    }
    render() {
        throw new Error("Method not implemented in abstract class");
    }
}
