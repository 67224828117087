import React, { Component } from "react";
import { ErrorhandlingCanvasWidget } from "./ErrorhandlingCanvasWidget";
/**
 * @author Jonathan Schneider
 */
export class NodeEditorBody extends Component {
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.currentlyEditingItpt !== prevState.currentlyEditingItpt) {
            let nextCurEditItpt = nextProps.currentlyEditingItpt;
            return { currentlyEditingItpt: nextCurEditItpt, isReloadToEditor: true };
        }
        return null;
    }
    constructor(props) {
        super(props);
        this.state = { currentlyEditingItpt: null, isReloadToEditor: false };
    }
    componentDidMount() {
        if (this.props.logic) {
            this.props.logic.autoDistribute();
        }
    }
    componentDidUpdate(nextProps) {
        const { currentlyEditingItpt, isReloadToEditor } = this.state;
        if (!isReloadToEditor && currentlyEditingItpt) {
            if (currentlyEditingItpt) {
                nextProps.logic.clear();
                nextProps.loadToEditorByName(currentlyEditingItpt, true);
                this.setState({ ...this.state, isReloadToEditor: false });
            }
        }
    }
    render() {
        const { hideRefMapDropSpace } = this.props;
        return (React.createElement("div", { className: "diagram-body" },
            React.createElement("div", { className: "diagram-layer", onDragOver: (event) => {
                    event.preventDefault();
                } },
                React.createElement(ErrorhandlingCanvasWidget, { className: "srd-diagram", engine: this.props.logic.getDiagramEngine() }),
                hideRefMapDropSpace
                    ? null
                    : React.createElement("div", { className: "editor-top-bar" }))));
    }
}
