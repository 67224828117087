import { ItptNodeModel } from "../_super/ItptNodeModel";
import { BASEDATATYPE_MODEL } from "../node-editor-consts";
import { editorDefaultNodesColor } from "../consts";
export class BaseDataTypeNodeModel extends ItptNodeModel {
    static fromVars(nameSelf = "Untitled", subItptOf = null, canInterpretType = "", color = "rgb(0,192,255)") {
        return new this({
            nameSelf,
            subItptOf,
            canInterpretType,
            color,
            isCompound: false
        });
    }
    constructor(options) {
        options.type = BASEDATATYPE_MODEL;
        options.color = options.color ? options.color : editorDefaultNodesColor;
        super(options);
    }
}
