import { getApplicationStore } from "../approot";
import { isLDOptionsSame } from "../ldaccess/ldUtils";
const connectedMap = new Map();
let lastLDOptionsMap = null;
const nonVisListener = () => {
    let state = getApplicationStore().getState();
    connectedMap.forEach((bpIntrprtr, key) => {
        let ldOptions = state.ldoptionsMap[key];
        if (!ldOptions)
            return;
        if (lastLDOptionsMap && isLDOptionsSame(lastLDOptionsMap[key], ldOptions))
            return;
        bpIntrprtr.consumeLDOptions(ldOptions);
    });
    lastLDOptionsMap = state.ldoptionsMap;
};
export const initLDConnect = () => {
    getApplicationStore().subscribe(nonVisListener);
};
export const connectNonVisLDComp = (alias, itpt) => {
    if (connectedMap.get(alias) && connectedMap.get(alias).cfg === itpt.cfg)
        return;
    connectedMap.set(alias, itpt);
};
