import React from 'react';
import { Component } from 'react';
import { Provider, connect } from 'react-redux';
import { configureStore, modAPI } from './appstate/store';
//import './styles/styles.scss';
//import { DevTools } from './appstate/devTools';
import { initLDConnect } from './sidefx/nonVisualConnect';
import { BrowserRouter as Router } from 'react-router-dom';
import { appItptMatcherFn } from './appconfig/appItptMatcher';
import { initEssentialInterpreters } from './components/essentials/initEssentialItptRetrieverSetup';
import { Route, Switch } from 'react-router';
import { initEssentialItpts } from './defaults/initEssentialItpts';
import { initGameItpt } from './components/game/initGameItpts';
import { initBaseHtmlItpt } from './components/basic-html/initBaseHtmlItpt';
import { LDApproot } from './ldapproot';
import { initRequiredMods } from './modding/initMods';
import { mapStateToPropsRoot } from './appstate/reduxFns';
import { initIRIFormatter } from './components/iri-formatter/initIRIFormatter';
import { initMathsBlocks } from './components/maths/initMathsBlocks';
import { initAnalyticsItpt } from './components';
import { initTextBlocks } from './components/text/initTextBlocks';
export const APP_LD_KEY = "app";
const firstDisplayedBlock = "metaexplorer.io/index";
const initialState = {
    appCfg: {
        appKey: APP_LD_KEY,
        mainItpt: firstDisplayedBlock,
        errorMsg: null
    },
    ldoptionsMap: {},
    ldNonVisualMap: {},
    mods: {
        isIdle: true,
        map: {}
    },
    isLoading: false,
    actionHandlerMap: {
        idHandler: {},
        typehandler: {}
    }
};
let applStore = null;
export const getApplicationStore = () => {
    if (applStore)
        return applStore;
    applStore = configureStore(initialState);
    return applStore;
};
export function rootSetup(requiredMods) {
    appItptMatcherFn();
    initEssentialItpts();
    initMathsBlocks();
    initTextBlocks();
    initAnalyticsItpt();
    initBaseHtmlItpt();
    initEssentialInterpreters();
    initIRIFormatter();
    initGameItpt();
    initLDConnect();
    initRequiredMods(modAPI, requiredMods);
}
export class PureAppRoot extends Component {
    static getDerivedStateFromProps(nextProps, prevState) {
        if (!prevState || !prevState.cfg ||
            prevState.cfg.appKey !== nextProps.cfg.appKey
            || prevState.cfg.mainItpt !== nextProps.cfg.mainItpt
            || prevState.isLoading !== nextProps.isLoading
            || !!nextProps.cfg.errorMsg) {
            return { ...prevState, ...nextProps };
        }
        return null;
    }
    constructor(props) {
        super(props);
        this.state = {
            isDemoInitialized: false,
            cfg: { appKey: "", mainItpt: "", errorMsg: null },
            isLoading: true
        };
    }
    render() {
        const { cfg, isLoading } = this.state;
        const errorMsg = cfg.errorMsg;
        if (errorMsg) {
            return React.createElement("div", null,
                "encountered an error while loading a Mod: ",
                errorMsg);
        }
        return (React.createElement(React.Fragment, null, !isLoading ?
            React.createElement(Router, null,
                React.createElement(Switch, null,
                    React.createElement(Route, { path: "/", render: (routeProps) => {
                            return React.createElement(LDApproot, { initiallyDisplayedItptName: cfg.mainItpt, ldTokenString: cfg.appKey, routes: routeProps });
                        } })))
            : React.createElement("div", { className: "approot-loading" }, "loading")));
    }
}
export const AppRoot = () => {
    const ReduxAppRoot = connect(mapStateToPropsRoot)(PureAppRoot);
    return React.createElement(Provider, { store: getApplicationStore() },
        React.createElement(ReduxAppRoot, null));
};
