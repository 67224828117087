var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from 'react';
import { LDDict } from '../../ldaccess/LDDict';
import { ldBlueprint } from '../../ldaccess/ldBlueprint';
import { VisualKeysDict } from '../../components/visualcomposition/visualDict';
import { UserDefDict } from '../../ldaccess/UserDefDict';
import { gdsfpLD, generateItptFromCompInfo, initLDLocalState } from '../../components/generic/generatorFns';
import { Component } from 'react';
export const GAME_TASK_HEADING = "GameTaskHeading";
export const GAME_TASK_TXT = "GameTaskText";
export const GAME_FIELD_ITPT_UL = "GameFieldItpt_UpperLeft";
export const GAME_FIELD_ITPT_UR = "GameFieldItpt_UpperRight";
export const GAME_FIELD_ITPT_LL = "GameFieldItpt_LowerLeft";
export const GAME_FIELD_ITPT_LR = "GameFieldItpt_LowerRight";
export const GAME_STATS = "GameStatsText";
export const GAME_CORRECT_FIELD_IDX = "GameField_Correct_idx";
export var FourFieldsViewIntrprtrName = "game/FourFieldsView";
let cfgType = FourFieldsViewIntrprtrName;
let cfgIntrprtKeys = [
    GAME_TASK_HEADING,
    GAME_TASK_TXT,
    GAME_FIELD_ITPT_UL,
    GAME_FIELD_ITPT_UR,
    GAME_FIELD_ITPT_LL,
    GAME_FIELD_ITPT_LR,
    GAME_STATS,
    VisualKeysDict.inputContainer,
    GAME_CORRECT_FIELD_IDX
];
let ownKVLs = [
    { key: GAME_TASK_HEADING, value: undefined, ldType: LDDict.Text },
    { key: GAME_TASK_TXT, value: undefined, ldType: LDDict.Text },
    { key: GAME_FIELD_ITPT_UL, value: undefined, ldType: UserDefDict.intrprtrClassType },
    { key: GAME_FIELD_ITPT_UR, value: undefined, ldType: UserDefDict.intrprtrClassType },
    { key: GAME_FIELD_ITPT_LL, value: undefined, ldType: UserDefDict.intrprtrClassType },
    { key: GAME_FIELD_ITPT_LR, value: undefined, ldType: UserDefDict.intrprtrClassType },
    { key: GAME_STATS, value: undefined, ldType: LDDict.Text },
    { key: VisualKeysDict.inputContainer, value: undefined, ldType: UserDefDict.intrprtrClassType },
    { key: GAME_CORRECT_FIELD_IDX, value: 0, ldType: LDDict.Integer }
];
let bpCfg = {
    subItptOf: null,
    nameSelf: cfgType,
    ownKVLs: ownKVLs,
    inKeys: cfgIntrprtKeys,
    crudSkills: "cRud"
};
let PureFourFieldsView = class PureFourFieldsView extends Component {
    constructor(props) {
        super(props);
        this.renderSub = generateItptFromCompInfo.bind(this);
        this.onPressResult = (fieldIdx) => {
            const gamecorrectfieldidx = this.state.localValues.get(GAME_CORRECT_FIELD_IDX);
            let newRevealedArray = this.state.revealedArray.slice();
            newRevealedArray[fieldIdx] = true;
            if (fieldIdx === parseInt(gamecorrectfieldidx, 10)) {
                this.setState({ ...this.state, revealedArray: newRevealedArray });
                //TODO: do something
            }
            else {
                this.setState({ ...this.state, revealedArray: newRevealedArray });
            }
        };
        this.cfg = this.constructor["cfg"];
        this.state = {
            revealedArray: [false, false, false, false],
            ...initLDLocalState(this.cfg, props, [
                GAME_FIELD_ITPT_UL,
                GAME_FIELD_ITPT_UR,
                GAME_FIELD_ITPT_LL,
                GAME_FIELD_ITPT_LR,
                VisualKeysDict.inputContainer
            ], [
                GAME_TASK_HEADING,
                GAME_TASK_TXT,
                GAME_STATS,
                GAME_CORRECT_FIELD_IDX
            ])
        };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        let rvLD = gdsfpLD(nextProps, prevState, [
            GAME_FIELD_ITPT_UL,
            GAME_FIELD_ITPT_UR,
            GAME_FIELD_ITPT_LL,
            GAME_FIELD_ITPT_LR,
            VisualKeysDict.inputContainer
        ], [
            GAME_TASK_HEADING,
            GAME_TASK_TXT,
            GAME_STATS,
            GAME_CORRECT_FIELD_IDX
        ]);
        if (!rvLD) {
            return null;
        }
        return {
            ...prevState, ...rvLD
        };
    }
    render() {
        const { localValues, revealedArray } = this.state;
        let gamecorrectfieldidx = this.state.localValues.get(GAME_CORRECT_FIELD_IDX);
        gamecorrectfieldidx = parseInt(gamecorrectfieldidx, 10);
        const gametaskheading = localValues.get(GAME_TASK_HEADING);
        const gametasktxt = localValues.get(GAME_TASK_TXT);
        const gamestats = localValues.get(GAME_STATS);
        let ulClassName = "game-4f-field-col ";
        ulClassName += revealedArray[0]
            ? gamecorrectfieldidx === 0 ? "game-4f-correct" : "game-4f-wrong"
            : "game-4f-idle";
        let urClassName = "game-4f-field-col ";
        urClassName += revealedArray[1]
            ? gamecorrectfieldidx === 1 ? "game-4f-correct" : "game-4f-wrong"
            : "game-4f-idle";
        let llClassName = "game-4f-field-col ";
        llClassName += revealedArray[2]
            ? gamecorrectfieldidx === 2 ? "game-4f-correct" : "game-4f-wrong"
            : "game-4f-idle";
        let lrClassName = "game-4f-field-col ";
        lrClassName += revealedArray[3]
            ? gamecorrectfieldidx === 3 ? "game-4f-correct" : "game-4f-wrong"
            : "game-4f-idle";
        let mainGameClassName = "game-4f-base ";
        return React.createElement("div", { className: mainGameClassName },
            React.createElement("div", { className: "game-4f-top" },
                React.createElement("h3", null, gametaskheading),
                React.createElement("span", null, gametasktxt)),
            React.createElement("div", { className: "game-4f-fields" },
                React.createElement("div", { className: "game-4f-field-row" },
                    React.createElement("div", { className: ulClassName, onClick: () => this.onPressResult(0) }, this.renderSub(GAME_FIELD_ITPT_UL)),
                    React.createElement("div", { className: urClassName, onClick: () => this.onPressResult(1) }, this.renderSub(GAME_FIELD_ITPT_UR))),
                React.createElement("div", { className: "game-4f-field-row" },
                    React.createElement("div", { className: llClassName, onClick: () => this.onPressResult(2) }, this.renderSub(GAME_FIELD_ITPT_LL)),
                    React.createElement("div", { className: lrClassName, onClick: () => this.onPressResult(3) }, this.renderSub(GAME_FIELD_ITPT_LR)))),
            React.createElement("div", { className: "game-4f-bottom" },
                React.createElement("span", null, gamestats)));
    }
};
PureFourFieldsView = __decorate([
    ldBlueprint(bpCfg)
], PureFourFieldsView);
export { PureFourFieldsView };
