var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from "react";
import { LDDict, gdsfpLD, generateItptFromCompInfo, initLDLocalState, ldBlueprint, VisualKeysDict, UserDefDict, } from "@metaexplorer/core";
import { debounce } from "debounce";
import { Component } from "react";
export const CSS_BASECLASS = "titletextandimage";
export var TitleTextAndImageName = "metaexplorer.io/TitleTextAndContainer";
let cfgIntrprtKeys = [
    VisualKeysDict.inputContainer,
    VisualKeysDict.headerTxt,
    VisualKeysDict.description,
    VisualKeysDict.directionChangeBreakPoint,
    VisualKeysDict.switchVerticalDirection,
    VisualKeysDict.switchHorizontalDirection,
];
let ownKVLs = [
    {
        key: VisualKeysDict.inputContainer,
        value: undefined,
        ldType: UserDefDict.intrprtrClassType,
    },
    {
        key: VisualKeysDict.headerTxt,
        value: undefined,
        ldType: LDDict.Text,
    },
    {
        key: VisualKeysDict.description,
        value: undefined,
        ldType: LDDict.Text,
    },
    {
        key: VisualKeysDict.directionChangeBreakPoint,
        value: undefined,
        ldType: LDDict.Integer,
    },
    {
        key: VisualKeysDict.switchVerticalDirection,
        value: undefined,
        ldType: LDDict.Boolean,
    },
    {
        key: VisualKeysDict.switchHorizontalDirection,
        value: undefined,
        ldType: LDDict.Boolean,
    },
];
let bpCfg = {
    subItptOf: null,
    nameSelf: TitleTextAndImageName,
    ownKVLs: ownKVLs,
    inKeys: cfgIntrprtKeys,
    crudSkills: "cRud",
};
const DEFAULT_BREAKPOINT = 350;
let PureTitleTextAndImage = class PureTitleTextAndImage extends Component {
    constructor(props) {
        super(props);
        this.divElement = null;
        this.renderSub = generateItptFromCompInfo.bind(this);
        this.debouncedDirection = debounce((width) => {
            let directionChangeBreakPoint = this.state.localValues.get(VisualKeysDict.directionChangeBreakPoint);
            directionChangeBreakPoint = directionChangeBreakPoint
                ? directionChangeBreakPoint
                : DEFAULT_BREAKPOINT;
            if (width > directionChangeBreakPoint && !this.state.isHorizontal) {
                this.setState({ ...this.state, isHorizontal: true });
            }
            if (width < directionChangeBreakPoint && this.state.isHorizontal) {
                this.setState({ ...this.state, isHorizontal: false });
            }
        }, 200);
        this.cfg = this.constructor["cfg"];
        this.state = {
            isHorizontal: false,
            ...initLDLocalState(this.cfg, props, [VisualKeysDict.inputContainer], [
                VisualKeysDict.headerTxt,
                VisualKeysDict.description,
                VisualKeysDict.directionChangeBreakPoint,
                VisualKeysDict.switchVerticalDirection,
                VisualKeysDict.switchHorizontalDirection,
            ]),
        };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        let rvLD = gdsfpLD(nextProps, prevState, [VisualKeysDict.inputContainer], [
            VisualKeysDict.headerTxt,
            VisualKeysDict.description,
            VisualKeysDict.directionChangeBreakPoint,
            VisualKeysDict.switchVerticalDirection,
            VisualKeysDict.switchHorizontalDirection,
        ]);
        if (!rvLD) {
            return null;
        }
        return {
            ...prevState,
            ...rvLD,
        };
    }
    determineDirection() {
        if (!this.divElement)
            return;
        const width = this.divElement.clientWidth;
        this.debouncedDirection(width);
    }
    componentDidMount() {
        this.determineDirection();
    }
    componentDidUpdate() {
        this.determineDirection();
    }
    render() {
        const { localValues, isHorizontal } = this.state;
        const headerText = localValues.get(VisualKeysDict.headerTxt);
        const description = localValues.get(VisualKeysDict.description);
        const switchVertical = localValues.get(VisualKeysDict.switchVerticalDirection);
        const switchHorizontal = localValues.get(VisualKeysDict.switchHorizontalDirection);
        const directionStyle = isHorizontal
            ? switchHorizontal
                ? { flexDirection: "row" }
                : { flexDirection: "row-reverse" }
            : switchVertical
                ? { flexDirection: "column" }
                : { flexDirection: "column-reverse" };
        const switcherCSSclassName = isHorizontal
            ? switchHorizontal
                ? "row"
                : "row-reverse"
            : switchVertical
                ? "column"
                : "column-reverse";
        return (React.createElement("div", { className: `flex-container ${CSS_BASECLASS} ${switcherCSSclassName}`, ref: (divElement) => {
                this.divElement = divElement;
                if (divElement) {
                    window.addEventListener("resize", () => {
                        this.determineDirection();
                    });
                }
            }, style: directionStyle },
            React.createElement("div", { className: `flex-filler vh-centered-column ${CSS_BASECLASS}-inputcontainer`, style: { minHeight: DEFAULT_BREAKPOINT } }, this.renderSub(VisualKeysDict.inputContainer)),
            React.createElement("div", { className: `flex-filler vh-centered-column ${CSS_BASECLASS}-textcontainer`, style: { minHeight: "300px" } },
                React.createElement("h2", { className: `${CSS_BASECLASS}-heading` }, headerText ? headerText : ""),
                React.createElement("p", { className: `${CSS_BASECLASS}-text` }, description ? description : ""))));
    }
};
PureTitleTextAndImage = __decorate([
    ldBlueprint(bpCfg)
], PureTitleTextAndImage);
export { PureTitleTextAndImage };
