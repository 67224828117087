var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LDDict } from '../../ldaccess/LDDict';
import { ldBlueprint } from '../../ldaccess/ldBlueprint';
import { VisualKeysDict } from '../../components/visualcomposition/visualDict';
import { gdsfpLD, initLDLocalState } from '../../components/generic/generatorFns';
import { Component } from 'react';
import React from 'react';
export var SingleFieldViewIntrprtrName = "game/SingleFieldView";
let cfgIntrprtKeys = [
    VisualKeysDict.headerTxt
];
let ownKVLs = [
    {
        key: VisualKeysDict.headerTxt,
        value: undefined,
        ldType: LDDict.Text
    }
];
let bpCfg = {
    subItptOf: null,
    nameSelf: SingleFieldViewIntrprtrName,
    ownKVLs: ownKVLs,
    inKeys: cfgIntrprtKeys,
    crudSkills: "cRud"
};
let PureSingleFieldView = class PureSingleFieldView extends Component {
    constructor(props) {
        super(props);
        this.cfg = this.constructor["cfg"];
        this.state = {
            ...initLDLocalState(this.cfg, props, [], [
                VisualKeysDict.headerTxt
            ])
        };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        let rvLD = gdsfpLD(nextProps, prevState, [], [
            VisualKeysDict.headerTxt
        ]);
        if (!rvLD) {
            return null;
        }
        return {
            ...prevState, ...rvLD
        };
    }
    render() {
        const { localValues } = this.state;
        const headerTxt = localValues.get(VisualKeysDict.headerTxt);
        return React.createElement("div", { className: "game-field" }, headerTxt);
    }
};
PureSingleFieldView = __decorate([
    ldBlueprint(bpCfg)
], PureSingleFieldView);
export { PureSingleFieldView };
