var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from 'react';
import { gdsfpLD, generateItptFromCompInfo, initLDLocalState, LDDict, ldBlueprint, VisualKeysDict, UserDefDict } from '@metaexplorer/core';
import { Component } from 'react';
import HeroGallery from 'metaexplorer-react-components/lib/components/hero/hero';
export var HeroGalleryName = "metaexplorer.io/HeroGallery";
const backgroundItpt = "backgroundPart";
const foregroundItpt = "foregroundPart";
const prevBtnLabel = "previousBtnLabel";
const nextBtnLabel = "nextBtnLabel";
let cfgIntrprtKeys = [backgroundItpt, foregroundItpt, prevBtnLabel, nextBtnLabel, VisualKeysDict.headerTxt, VisualKeysDict.subHeaderTxt];
let ownKVLs = [
    {
        key: cfgIntrprtKeys[0],
        value: undefined,
        ldType: UserDefDict.intrprtrClassType
    },
    {
        key: cfgIntrprtKeys[1],
        value: undefined,
        ldType: UserDefDict.intrprtrClassType
    },
    {
        key: cfgIntrprtKeys[2],
        value: undefined,
        ldType: LDDict.Text
    },
    {
        key: cfgIntrprtKeys[3],
        value: undefined,
        ldType: LDDict.Text
    },
    {
        key: cfgIntrprtKeys[4],
        value: undefined,
        ldType: LDDict.Text
    },
    {
        key: cfgIntrprtKeys[5],
        value: undefined,
        ldType: LDDict.Text
    }
];
let bpCfg = {
    subItptOf: null,
    nameSelf: HeroGalleryName,
    ownKVLs: ownKVLs,
    inKeys: cfgIntrprtKeys,
    crudSkills: "cRud"
};
let PureHeroGallery = class PureHeroGallery extends Component {
    constructor(props) {
        super(props);
        this.renderSub = generateItptFromCompInfo.bind(this);
        this.cfg = this.constructor["cfg"];
        this.state = {
            numGalleryItems: 0,
            displayIdx: 0,
            ...initLDLocalState(this.cfg, props, [cfgIntrprtKeys[0], cfgIntrprtKeys[1]], [cfgIntrprtKeys[2], cfgIntrprtKeys[3], cfgIntrprtKeys[4], cfgIntrprtKeys[5]], [true, true], [true, true, false, false])
        };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        let rvLD = gdsfpLD(nextProps, prevState, [cfgIntrprtKeys[0], cfgIntrprtKeys[1]], [cfgIntrprtKeys[2], cfgIntrprtKeys[3], cfgIntrprtKeys[4], cfgIntrprtKeys[5]], null, [true, true], [true, true, false, false]);
        if (!rvLD) {
            return null;
        }
        let galFrontItems = rvLD.compInfos.get(cfgIntrprtKeys[1]);
        let numGalleryItems = galFrontItems ? galFrontItems.length : 0;
        return {
            ...prevState, ...rvLD,
            numGalleryItems
        };
    }
    onGalleryLeftClick() {
        let newDisplayIdx = this.state.displayIdx - 1;
        newDisplayIdx = newDisplayIdx < 0 ? this.state.numGalleryItems - 1 : newDisplayIdx;
        this.setState({ ...this.state, displayIdx: newDisplayIdx });
    }
    onGalleryRightClick() {
        let newDisplayIdx = this.state.displayIdx + 1;
        newDisplayIdx = newDisplayIdx >= this.state.numGalleryItems ? 0 : newDisplayIdx;
        this.setState({ ...this.state, displayIdx: newDisplayIdx });
    }
    render() {
        const { localValues, displayIdx } = this.state;
        const { routes } = this.props;
        const prevBtnLabelStrings = localValues.get(prevBtnLabel);
        const nxtBtnLabelStrings = localValues.get(nextBtnLabel);
        const subHeaderTextStr = localValues.get(VisualKeysDict.subHeaderTxt);
        const topHeaderTextStr = localValues.get(VisualKeysDict.headerTxt);
        return React.createElement(HeroGallery, { backgroundComp: this.renderSub(backgroundItpt, routes, displayIdx), foregroundComp: this.renderSub(foregroundItpt, routes, displayIdx), leftBtnLabel: prevBtnLabelStrings[displayIdx], rightBtnLabel: nxtBtnLabelStrings[displayIdx], onLeftBtnClick: () => this.onGalleryLeftClick(), onRightBtnClick: () => this.onGalleryRightClick(), topHeader: topHeaderTextStr, subHeader: subHeaderTextStr });
    }
};
PureHeroGallery = __decorate([
    ldBlueprint(bpCfg)
], PureHeroGallery);
export { PureHeroGallery };
