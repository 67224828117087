var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { AbstractSectionedList, SectionedListCfg, sectionElements, sectionHeadings, ldBlueprint } from "@metaexplorer/core";
import { List, ListSubheader } from "@material-ui/core";
import { Fragment } from "react";
import React from "react";
let MDSectionedList = class MDSectionedList extends AbstractSectionedList {
    render() {
        const { localValues, compInfos } = this.state;
        let sectHeadStrngs = localValues.get(sectionHeadings);
        if (sectHeadStrngs && !Array.isArray(sectHeadStrngs)) {
            sectHeadStrngs = [sectHeadStrngs];
        }
        const sectionElems = compInfos.get(sectionElements);
        if (!sectionElems)
            return null;
        const { routes } = this.props;
        let listSections = [];
        if (Array.isArray(sectionElems)) {
            sectionElems.forEach((elem, displayIdx) => {
                listSections.push(this.renderSub(sectionElements, routes, displayIdx));
            });
        }
        else {
            listSections.push(this.renderSub(sectionElements, routes));
        }
        return React.createElement(List, null, listSections.map((listSection, idx) => {
            if (!listSection)
                return null;
            const sectHeader = (sectHeadStrngs && idx < sectHeadStrngs.length) ? sectHeadStrngs[idx] : null;
            //<Fragment> is the same as <></>, just fixes a warning about missing keys:
            return React.createElement(Fragment, { key: "frag" + idx },
                sectHeader ? React.createElement(ListSubheader, { key: "lh" + idx }, sectHeader) : null,
                listSection);
        }));
    }
};
MDSectionedList = __decorate([
    ldBlueprint(SectionedListCfg)
], MDSectionedList);
export { MDSectionedList };
