var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from 'react';
import { LDDict, ldBlueprint, VisualKeysDict, UserDefDict, gdsfpLD, generateItptFromCompInfo, initLDLocalState } from '@metaexplorer/core';
import { Component } from 'react';
export var ImgHeadSubDescIntrprtrName = "metaexplorer.io/ImgHeadSubDescIntrprtr";
let cfgIntrprtKeys = [VisualKeysDict.primaryItpt, VisualKeysDict.headerTxt, VisualKeysDict.subHeaderTxt, VisualKeysDict.description, VisualKeysDict.secondaryItpt];
let ownKVLs = [
    {
        key: VisualKeysDict.primaryItpt,
        value: undefined,
        ldType: UserDefDict.intrprtrClassType
    },
    {
        key: VisualKeysDict.headerTxt,
        value: undefined,
        ldType: LDDict.Text
    },
    {
        key: VisualKeysDict.subHeaderTxt,
        value: undefined,
        ldType: LDDict.Text
    },
    {
        key: VisualKeysDict.description,
        value: undefined,
        ldType: LDDict.Text
    },
    {
        key: VisualKeysDict.secondaryItpt,
        value: undefined,
        ldType: UserDefDict.intrprtrClassType
    }
];
let bpCfg = {
    subItptOf: null,
    nameSelf: ImgHeadSubDescIntrprtrName,
    ownKVLs: ownKVLs,
    inKeys: cfgIntrprtKeys,
    crudSkills: "cRud"
};
let PureImgHeadSubDesc = class PureImgHeadSubDesc extends Component {
    constructor(props) {
        super(props);
        this.renderSub = generateItptFromCompInfo.bind(this);
        this.cfg = this.constructor["cfg"];
        this.state = {
            ...initLDLocalState(this.cfg, props, [VisualKeysDict.primaryItpt, VisualKeysDict.secondaryItpt], [VisualKeysDict.headerTxt, VisualKeysDict.subHeaderTxt, VisualKeysDict.description])
        };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        let rvLD = gdsfpLD(nextProps, prevState, [VisualKeysDict.primaryItpt, VisualKeysDict.secondaryItpt], [VisualKeysDict.headerTxt, VisualKeysDict.subHeaderTxt, VisualKeysDict.description]);
        if (!rvLD) {
            return null;
        }
        return {
            ...prevState, ...rvLD
        };
    }
    render() {
        const { localValues } = this.state;
        const headerText = localValues.get(VisualKeysDict.headerTxt);
        const subHeaderText = localValues.get(VisualKeysDict.subHeaderTxt);
        const description = localValues.get(VisualKeysDict.description);
        return React.createElement("div", { className: "mdscrollbar" },
            React.createElement("div", { className: "header-img-container" }, this.renderSub(VisualKeysDict.primaryItpt)),
            React.createElement("div", { className: "header-img-container gradient" },
                React.createElement("div", { className: "header-text" },
                    React.createElement("span", null, headerText ? headerText : 'headerTextPlaceholder'))),
            React.createElement("div", { className: "imgheadsubdesc-text" },
                React.createElement("div", null,
                    React.createElement("h4", null, subHeaderText ? subHeaderText : "subHeaderTextPlaceholder")),
                React.createElement("div", null,
                    React.createElement("i", null, description ? description : 'descriptionPlaceholder'))),
            React.createElement("div", null, this.renderSub(VisualKeysDict.secondaryItpt)));
    }
};
PureImgHeadSubDesc = __decorate([
    ldBlueprint(bpCfg)
], PureImgHeadSubDesc);
export { PureImgHeadSubDesc };
