export class EditorGlobalsLoadApi {
    static getEditorGlobalsLoadApiSingleton() {
        if (EditorGlobalsLoadApi.apiSingleton == null) {
            EditorGlobalsLoadApi.apiSingleton = new EditorGlobalsLoadApi();
        }
        return EditorGlobalsLoadApi.apiSingleton;
    }
    getGlobalsFrom(targetUrl) {
        return () => {
            return new Promise((resolve, reject) => {
                fetch(targetUrl, {
                    method: 'GET',
                    headers: {
                        Accept: "application/json"
                    },
                }).then((response) => {
                    if (response.status >= 400) {
                        reject("Bad response from server");
                    }
                    response.json().then((bodyVal) => {
                        resolve(bodyVal);
                    }).catch((reason) => {
                        reject(reason);
                    });
                });
            });
        };
    }
    setGlobalsAt(targetUrl, postBodyJson) {
        return () => {
            return new Promise((resolve, reject) => {
                let bodyJson = postBodyJson;
                try {
                    bodyJson = JSON.stringify(bodyJson);
                }
                catch (error) {
                    reject(error);
                }
                fetch(targetUrl, {
                    method: 'POST',
                    headers: {
                        "Accept": "application/json",
                        'Content-Type': 'application/json'
                    },
                    body: bodyJson
                }).then((response) => {
                    if (response.status >= 400) {
                        reject("Bad response from server");
                    }
                    response.json().then((bodyVal) => {
                        resolve(bodyVal);
                    }).catch((reason) => {
                        reject(reason);
                    });
                });
            });
        };
    }
}
