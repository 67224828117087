import React from 'react';
import { BaseContainerRewrite } from "./components/generic/baseContainer-rewrite";
import { Route } from "react-router";
import { Component } from "react";
import { connect } from "react-redux";
import { mapStateToProps, mapDispatchToProps } from "./appstate/reduxFns";
export class PureLDApproot extends Component {
    constructor(props) {
        super(props);
        const { initiallyDisplayedItptName } = this.props;
        this.state = { initiallyDisplayedItptName, hasCompletedFirstRender: false };
    }
    componentDidMount() {
        if (!this.props.ldOptions) {
            this.props.notifyLDOptionsChange(null);
        }
    }
    render() {
        return React.createElement("div", { className: "app-content" },
            React.createElement(Route, { path: "/", render: (routeProps) => {
                    return React.createElement(React.Fragment, null,
                        React.createElement(BaseContainerRewrite, { routes: routeProps, ldTokenString: this.props.ldTokenString }));
                } }));
    }
}
export const LDApproot = connect(mapStateToProps, mapDispatchToProps)(PureLDApproot);
