var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ButtonBpCfg, AbstractButton, ldBlueprint } from "@metaexplorer/core";
import { Button } from "@material-ui/core";
import React from "react";
export const MDButtonName = "metaexplorer.io/material-design/Button";
let MDButton = class MDButton extends AbstractButton {
    renderButton(isIconVal, iconUrlVal, confirmTxt, routeAsHref) {
        const buttonProps = routeAsHref
            ? { href: routeAsHref, target: "_blank", rel: "noopener" }
            : {};
        if (isIconVal) {
            return (React.createElement(Button, Object.assign({}, buttonProps, { onClick: () => this.onConfirmClick() }), iconUrlVal ? React.createElement("img", { src: iconUrlVal }) : null));
        }
        return (React.createElement(Button, Object.assign({}, buttonProps, { onClick: () => this.onConfirmClick() }),
            iconUrlVal ? React.createElement("img", { src: iconUrlVal }) : null,
            confirmTxt ? confirmTxt : "confirm"));
    }
};
MDButton = __decorate([
    ldBlueprint({ ...ButtonBpCfg, nameSelf: MDButtonName })
], MDButton);
export { MDButton };
