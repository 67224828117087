var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from 'react';
import { ldBlueprint } from '../ldaccess/ldBlueprint';
import { LDDict } from '../ldaccess/LDDict';
import { Component } from 'react';
/*
const mapStateToProps = (state: ExplorerState, ownProps: OwnProps): ConnectedState => ({
});

const mapDispatchToProps = (dispatch: Dispatch<Action<any>>): ConnectedDispatch => ({
    fileChange: (fileList: FileList, url: string) => {
        dispatch(uploadImgRequestAction(fileList, url));
        return;
    }
});*/
let cfgType = LDDict.CreateAction;
let cfgIntrprtKeys = [LDDict.agent, LDDict.target];
let ownKVLs = [
    {
        key: LDDict.agent,
        value: undefined,
        ldType: undefined
    },
    {
        key: LDDict.target,
        value: 'http://localhost:1111/api/ysj/media/upload',
        ldType: LDDict.EntryPoint
    },
    {
        key: LDDict.result,
        value: undefined,
        ldType: LDDict.ImageObject
    }
];
var bpCfg = {
    subItptOf: null,
    canInterpretType: cfgType,
    nameSelf: "metaexplorer.io/imageUpload",
    ownKVLs: ownKVLs,
    inKeys: cfgIntrprtKeys,
    crudSkills: "Crud"
};
let PureImgUploader = class PureImgUploader extends Component {
    constructor() {
        super(...arguments);
        this.onClickFileChange = (e) => {
            e.preventDefault();
            let fileList = e.target.files;
            if (fileList.length > 0) {
                var url = this.getStringValFromKey(LDDict.target);
                this.props.fileChange(fileList, url);
            }
        };
    }
    render() {
        return React.createElement("div", null,
            React.createElement("input", { type: "file", onChange: (evt) => this.onClickFileChange(evt), placeholder: "Upload file", accept: ".jpg,.png,.txt" }));
    }
    getStringValFromKey(key) {
        let kvStoreVal = this.ownKVLs;
        if (kvStoreVal != null && kvStoreVal) {
            return kvStoreVal.filter((curItm) => curItm.key === key).map((val) => val.value).reduce((a, b, idx) => idx === 0 ? b : a);
            //TODO: analyze which handling could be better, now selects first
        }
        return null;
    }
};
PureImgUploader = __decorate([
    ldBlueprint(bpCfg)
], PureImgUploader);
export { PureImgUploader };
