import { LDError } from '../appstate/LDError';
import { UserDefDict } from './UserDefDict';
function handleKVInheritance(baseClassKV, subClassKV, isReplace) {
    let rv = [];
    if (isReplace) {
        rv = subClassKV ? subClassKV : baseClassKV;
    }
    else {
        if (!baseClassKV) {
            rv = subClassKV;
        }
        else {
            let baseCopy = baseClassKV.slice();
            subClassKV.forEach((kv, idx, arr) => {
                let findIdx = baseCopy.findIndex((findKv) => findKv.key === kv.key);
                rv.push(kv);
                if (findIdx >= 0) {
                    baseCopy.splice(findIdx, 1);
                }
            });
            rv.push(...baseCopy);
        }
    }
    return rv;
}
// tslint:disable-next-line:callable-types
function blueprintDecorator(constructorFn, blueprintCfg, replaceKVs = false) {
    var _a;
    var classToExtend = null;
    if (typeof constructorFn !== 'function')
        throw new LDError("blueprint was not decorated on a function, but on: " + constructorFn);
    classToExtend = (_a = class extends constructorFn {
            constructor() {
                super(...arguments);
                this.ownKVLs = this["ownKVLs"]
                    ? handleKVInheritance(this["ownKVLs"], blueprintCfg.ownKVLs, replaceKVs)
                    : blueprintCfg.ownKVLs;
                this.inKeys = blueprintCfg.inKeys;
            }
        },
        _a.nameSelf = blueprintCfg.nameSelf,
        _a.cfg = blueprintCfg,
        _a);
    return classToExtend;
}
export const ldBlueprint = (blueprintCfg, replaceKVs = false) => {
    //eval phase
    if (blueprintCfg == null)
        throw new LDError("blueprintCfg must not be null");
    if (blueprintCfg.nameSelf == null)
        throw new LDError("blueprintCfg.nameSelf must not be null");
    if (blueprintCfg.canInterpretType == null) {
        //autmatically generate an itpt-specific Instance type
        blueprintCfg.canInterpretType = blueprintCfg.nameSelf + UserDefDict.standardItptObjectTypeSuffix;
    }
    if (blueprintCfg.crudSkills == null)
        throw new LDError("blueprintCfg.crudSkills must not be null");
    if (blueprintCfg.inKeys == null)
        throw new LDError("blueprintCfg.inKeys must not be null");
    // tslint:disable-next-line:callable-types
    return (target) => {
        return blueprintDecorator(target, blueprintCfg, replaceKVs);
    };
};
