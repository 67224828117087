var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from 'react';
import { LDDict, ldBlueprint, VisualKeysDict, UserDefDict, gdsfpLD, initLDLocalState, fontIcon, isIcon, } from '@metaexplorer/core';
import { Component } from 'react';
import { Button } from '@material-ui/core';
import { copyToClipboard } from '../../../utils/copyToClipboard';
export const MDCopyButtonName = "metaexplorer.io/material-design/CopyButton";
let cfgRegularInputKeys = [
    UserDefDict.inputData,
    VisualKeysDict.copyTxt,
    fontIcon,
    isIcon,
];
let ownKVLs = [
    {
        key: UserDefDict.inputData,
        value: undefined,
        ldType: LDDict.Text
    },
    {
        key: VisualKeysDict.copyTxt,
        value: undefined,
        ldType: LDDict.Text
    },
    {
        key: fontIcon,
        value: undefined,
        ldType: LDDict.Text
    },
    {
        key: isIcon,
        value: undefined,
        ldType: LDDict.Boolean
    },
];
let bpCfg = {
    subItptOf: null,
    nameSelf: MDCopyButtonName,
    ownKVLs: ownKVLs,
    inKeys: cfgRegularInputKeys,
    crudSkills: "cRud"
};
let PureMDCopyButton = class PureMDCopyButton extends Component {
    constructor(props) {
        super(props);
        this.onCopyBtnClick = () => {
            const { localValues } = this.state;
            const inputData = localValues.get(UserDefDict.inputData);
            if (inputData) {
                copyToClipboard(inputData);
            }
        };
        this.cfg = this.constructor["cfg"];
        this.state = {
            ...initLDLocalState(this.cfg, props, [], [...cfgRegularInputKeys])
        };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        let rvLD = gdsfpLD(nextProps, prevState, [], [...cfgRegularInputKeys]);
        if (!rvLD) {
            return null;
        }
        return {
            ...prevState, ...rvLD
        };
    }
    render() {
        const { localValues } = this.state;
        const textToCopy = localValues.get(UserDefDict.inputData);
        const copyTxt = localValues.get(VisualKeysDict.copyTxt);
        const localFontIcon = localValues.get(fontIcon);
        const localIsIcon = localValues.get(isIcon);
        return this.renderButton(localIsIcon, localFontIcon, copyTxt, textToCopy);
    }
    renderButton(isIconVal, iconUrlVal, copyTxt, textToCopy) {
        return React.createElement(Button, { disabled: !textToCopy, onClick: () => this.onCopyBtnClick() },
            iconUrlVal ? React.createElement("img", { src: iconUrlVal }) : null,
            !isIconVal && copyTxt && "copy");
    }
};
PureMDCopyButton = __decorate([
    ldBlueprint(bpCfg)
], PureMDCopyButton);
export { PureMDCopyButton };
