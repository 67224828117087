import { initLDLocalState, gdsfpLD } from '../../../components/generic/generatorFns';
import { Component } from 'react';
import { LDUIDict, LDUIDictVerbs } from '../../../ldaccess/LDUIDict';
import { createLDUINSUrl, LDDict } from '../../../ldaccess';
const SimpleTextTableName = "metaexplorer.io/core/SimpleTextTable";
const viewSimpleTextTableAction = createLDUINSUrl(LDDict.ViewAction, LDDict.object, LDUIDict.TupleTextTable);
let simpleTextTableInputKeys = [LDUIDictVerbs.headings, LDUIDictVerbs.tuples];
let ownKVLs = [
    { key: LDUIDictVerbs.headings, value: undefined, ldType: undefined },
    { key: LDUIDictVerbs.tuples, value: undefined, ldType: undefined }
];
export const simpleTextTableCfg = {
    subItptOf: null,
    canInterpretType: viewSimpleTextTableAction,
    nameSelf: SimpleTextTableName,
    ownKVLs: ownKVLs,
    inKeys: simpleTextTableInputKeys,
    crudSkills: "cRud"
};
export class AbstractSimpleTextTable extends Component {
    constructor(props) {
        super(props);
        this.cfg = this.constructor["cfg"];
        const ldState = initLDLocalState(this.cfg, props, [], simpleTextTableInputKeys);
        this.state = { ...ldState };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        let rvLocal = gdsfpLD(nextProps, prevState, [], simpleTextTableInputKeys);
        if (!rvLocal) {
            return null;
        }
        let rvNew = { ...rvLocal };
        return { ...prevState, ...rvNew };
    }
    render() {
        throw new Error("Method not implemented in abstract class");
    }
}
