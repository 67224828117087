var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LDRetrieverSuperRewrite, itptKeysFromInputKvs, ldBlueprint, LDDict, UserDefDict, SideFXDict } from "@metaexplorer/core";
import { UserItptLoadApi } from "../apis/itpt-load-api";
export const RefMapBpCfgSenderName = "metaexplorer.io/server/RefMapBpCfgSender";
export const RefMapBpCfgSenderType = "metaexplorer.io/server/RefMapBpCfgSenderType";
export const inputRefMap = "inputRefMap";
let inputKVStores = [
    {
        key: SideFXDict.srvURL,
        value: undefined,
        ldType: LDDict.URL
    },
    {
        key: inputRefMap,
        value: undefined,
        ldType: UserDefDict.itptRefMapBpCfg
    }
];
let outputKVStores = [
    {
        key: UserDefDict.responseWrapperKey,
        value: undefined,
        ldType: UserDefDict.responseWrapperType
    },
];
let ownKVLs = [...inputKVStores, ...outputKVStores];
let inKeys = itptKeysFromInputKvs(inputKVStores);
let bpCfg = {
    subItptOf: null,
    nameSelf: RefMapBpCfgSenderName,
    ownKVLs: ownKVLs,
    inKeys,
    crudSkills: "cRud",
    canInterpretType: RefMapBpCfgSenderType
};
let RefMapBpCfgSender = class RefMapBpCfgSender extends LDRetrieverSuperRewrite {
    constructor(parameters) {
        super(parameters, inKeys);
        this.apiCallOverride = () => new Promise((resolve, reject) => {
            const srvUrl = this.state.localValues.get(SideFXDict.srvURL);
            const postBody = this.state.localValues.get(inputRefMap);
            const promise = UserItptLoadApi.getUserItptLoadApiSingleton().setItptsAt(srvUrl, postBody);
            promise().then(response => resolve(this.wrapOutputKv(response))).catch(reason => resolve(this.wrapOutputKv({
                status: 'error',
                message: reason,
                statusPayload: "error"
            })));
        });
    }
    wrapOutputKv(inputBody) {
        return {
            [UserDefDict.responseWrapperKey]: inputBody
        };
    }
};
RefMapBpCfgSender = __decorate([
    ldBlueprint(bpCfg)
], RefMapBpCfgSender);
export { RefMapBpCfgSender };
