import { OUTPUT_INFO_MODEL } from "../node-editor-consts";
import { ItptNodeModel } from "../_super/ItptNodeModel";
import { merge } from "lodash";
import { editorSpecificNodesColor } from "../consts";
export const OUTPUT_NODE_WIDTH = 275;
export class OutputInfoPartNodeModel extends ItptNodeModel {
    constructor(options) {
        options.type = OUTPUT_INFO_MODEL;
        options.color = options.color ? options.color : editorSpecificNodesColor,
            super(options);
        this.setItptName(null);
        this.width = OUTPUT_NODE_WIDTH;
    }
    static fromVars(nameSelf = "Untitled", subItptOf = null, canInterpretType = "", color = "rgb(0,192,255)", isCompound, type, userName, userProject) {
        return new this({
            nameSelf,
            subItptOf,
            canInterpretType,
            color,
            type,
            isCompound,
            itptName: null,
            itptUserName: userName,
            itptProjName: userProject,
            itptBlockName: ""
        });
    }
    deserialize(event) {
        super.deSerialize(event);
        this.setItptName(event.data.itptName);
    }
    serialize() {
        return merge(super.serialize(), {
            itptName: this.itptName
        });
    }
    getItptUserName() {
        return this.itptUserName;
    }
    getItptProjName() {
        return this.itptProjName;
    }
    getUserName() {
        return this.userName;
    }
    getUserProject() {
        return this.userProject;
    }
    setItptName(value) {
        if (value) {
            this.itptName = value;
            let splitValue = value.split("/");
            if (splitValue.length === 0) {
                this.itptUserName = null;
                this.itptProjName = null;
                this.itptBlockName = null;
            }
            if (splitValue.length === 1) {
                this.itptUserName = null;
                this.itptProjName = null;
                this.itptBlockName = splitValue[0];
            }
            if (splitValue.length === 2) {
                this.itptUserName = splitValue[0];
                this.itptProjName = null;
                this.itptBlockName = splitValue[1];
            }
            if (splitValue.length > 2) {
                this.itptUserName = splitValue[0];
                this.itptProjName = splitValue[1];
                splitValue = splitValue.slice(2);
                this.itptBlockName = splitValue.join("/");
            }
        }
    }
    getItptName() {
        return this.itptName;
    }
    getItptBlockName() {
        return this.itptBlockName;
    }
    setSelected(selected = true) {
        //catching this event on purpose, if
    }
    hasMainItpt() {
        const inP = this.getInPorts();
        if (inP.length > 0) {
            const links = inP[0].getLinks();
            let elemCounter = 0;
            for (const key in links) {
                if (links.hasOwnProperty(key)) {
                    elemCounter++;
                    const element = links[key];
                    if (!element.getSourcePort() || !element.getTargetPort())
                        return false;
                }
            }
            if (elemCounter !== 1)
                return false;
        }
        return true;
    }
    handleOutputInfoSaved() {
        const newItptName = this.itptName;
        this.fireEvent({
            itptName: newItptName
        }, 'outputInfoSaved');
    }
}
