var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ldBlueprint, AbstractNavSearchBar, NavSearchBarBpCfg, cleanRouteString, classNamesLD, VisualKeysDict } from '@metaexplorer/core';
import { Redirect } from 'react-router';
import { Toolbar, IconButton, AppBar, InputBase } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React from 'react';
export const NavSearchBarName = "metaexplorer.io/material-design/NavSearchBar";
let MDNavSearchBar = class MDNavSearchBar extends AbstractNavSearchBar {
    render() {
        const { isDoRedirect, routeSendBack, localValues } = this.state;
        if (isDoRedirect) {
            let route = cleanRouteString(routeSendBack, this.props.routes);
            this.setState({ ...this.state, isDoRedirect: false });
            return React.createElement(Redirect, { to: route });
        }
        const searchValue = localValues.get(VisualKeysDict.searchText);
        return (React.createElement(React.Fragment, null,
            React.createElement(AppBar, { position: "static", className: classNamesLD(null, localValues) },
                React.createElement(Toolbar, null,
                    React.createElement(IconButton, { edge: "start", color: "inherit", onClick: () => this.onBackBtnClick() },
                        React.createElement(ArrowBackIcon, null)),
                    React.createElement(InputBase, { placeholder: "Search\u2026", defaultValue: searchValue, inputProps: { onChange: (evt) => this.onSearchChange(evt.currentTarget.value) }, value: searchValue }))),
            this.renderInputContainer()));
    }
};
MDNavSearchBar = __decorate([
    ldBlueprint(NavSearchBarBpCfg)
], MDNavSearchBar);
export { MDNavSearchBar };
