import { LDDict, LDUIDict } from "../../../ldaccess";
import { Component } from "react";
import { gdsfpLD, initLDLocalState } from "../../generic";
let cfgType = LDDict.ChooseAction;
const singleValueSelectorInputKeys = [
    LDDict.description,
    LDDict.actionOption,
    LDDict.object
];
const ownKVLs = [
    {
        key: LDDict.description,
        value: undefined,
        ldType: LDDict.Text
    },
    {
        key: LDDict.actionOption,
        value: undefined,
        ldType: LDUIDict.NTuple
    },
    {
        key: LDDict.object,
        value: undefined,
        ldType: LDUIDict.OneTuple
    }
];
export const SingleValueSelectorBpCfg = {
    subItptOf: undefined,
    canInterpretType: cfgType,
    nameSelf: "metaexplorer.io/core/SingleValueSelector",
    ownKVLs: ownKVLs,
    inKeys: singleValueSelectorInputKeys,
    crudSkills: "CRUd"
};
export class AbstractSingleValueSelector extends Component {
    constructor(props) {
        super(props);
        this.cfg = this.constructor["cfg"];
        const ldState = initLDLocalState(this.cfg, props, [], singleValueSelectorInputKeys);
        this.state = { ...ldState };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        let rvLocal = gdsfpLD(nextProps, prevState, [], singleValueSelectorInputKeys);
        if (!rvLocal) {
            return null;
        }
        let rvNew = { ...rvLocal };
        return { ...prevState, ...rvNew };
    }
    render() {
        throw new Error("Method not implemented in abstract class");
    }
}
