import { ItptNodeModel } from "../_super/ItptNodeModel";
import { DECLARATION_MODEL } from "../node-editor-consts";
import { editorSpecificNodesColor } from "../consts";
export class DeclarationPartNodeModel extends ItptNodeModel {
    static fromVars(nameSelf = "Untitled", subItptOf = null, canInterpretType = "", color = "rgb(0,192,255)") {
        return new this({
            nameSelf,
            subItptOf,
            canInterpretType,
            color,
            isCompound: false,
        });
    }
    constructor(options) {
        options.type = DECLARATION_MODEL;
        options.color = options.color ? options.color : editorSpecificNodesColor;
        super(options);
    }
}
