import { BaseDataTypePortSelector } from "./BaseDataTypePortSelectorWidget";
import { createFactory } from "react";
import { map } from "lodash";
import React from "react";
/**
 * @author Jonathan Schneider
 */
export class BaseDataTypeNodeWidget extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    generatePort(port) {
        return React.createElement(BaseDataTypePortSelector, { model: port, key: port.getID(), ldTokenString: port.getID() });
    }
    render() {
        const className = `basic-node ${this.props.node.isSelected() ? 'selected' : ''}`;
        return (React.createElement("div", { className: className, style: { background: this.props.node.getOptions().color } },
            React.createElement("div", { className: "title" },
                React.createElement("div", { className: "name" }, this.props.node.getNameSelf())),
            React.createElement("div", { className: "ports" },
                React.createElement("div", { className: "out" }, map(this.props.node.getOutPorts(), this.generatePort.bind(this))))));
    }
}
export var BaseDataTypeNodeWidgetFactory = createFactory(BaseDataTypeNodeWidget);
