import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { BaseDataTypeNodeModel } from "./BaseDataTypeNodeModel";
import { BASEDATATYPE_MODEL } from "../node-editor-consts";
import * as React from "react";
import { BaseDataTypeNodeWidget } from "./BaseDataTypeWidget";
export class BaseDataTypeNodeFactory extends AbstractReactFactory {
    constructor() {
        super(BASEDATATYPE_MODEL);
    }
    generateReactWidget(event) {
        return React.createElement(BaseDataTypeNodeWidget, { engine: this.engine, node: event.model });
    }
    generateModel(event) {
        return BaseDataTypeNodeModel.fromVars();
    }
}
