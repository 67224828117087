import * as React from 'react';
import { Component } from "react";
import { Link } from "react-router-dom";
export class FourOhFournomatch extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return React.createElement("div", null,
            React.createElement("h2", null, "404"),
            React.createElement("p", null, "Sorry, but we didn't find anything here"),
            React.createElement("p", null,
                React.createElement("span", null, "Were you looking for a demo? Have a look at these two"),
                React.createElement("br", null),
                React.createElement(Link, { to: "/tinyentrepreneur" }, "Tiny entrepreneur"),
                React.createElement("br", null),
                React.createElement(Link, { to: "/generaldemo" }, "a general demo")));
    }
}
