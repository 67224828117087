import QrScanner from "qr-scanner";
export class QrCodeGenScanClientAPI {
    static async getScanner(video, onDecode, canvasSize) {
        let api = await QrCodeGenScanClientAPI.getQrCodeGenScanAPISingleton();
        if (!api.scanner) {
            try {
                await api.initScanScriptLoad();
            }
            catch (error) {
                return;
            }
        }
        QrScanner.WORKER_PATH = "lib/qr-scanner-worker.min.js@1.1.1.js";
        api.scanner = new QrScanner(video, onDecode, canvasSize);
        api.scanner.start();
    }
    static async destroyScanner() {
        let api = await QrCodeGenScanClientAPI.getQrCodeGenScanAPISingleton();
        if (api.scanner)
            api.scanner.destroy();
    }
    static async getQrCodeGenScanAPISingleton() {
        if (QrCodeGenScanClientAPI.genscanSingleton == null) {
            QrCodeGenScanClientAPI.genscanSingleton = await QrCodeGenScanClientAPI.initGenScan();
        }
        return QrCodeGenScanClientAPI.genscanSingleton;
    }
    static async initGenScan() {
        let rv = new QrCodeGenScanClientAPI();
        const genApi = (await rv.initGenScriptLoad());
        //await rv.initScanScriptLoad();
        //await rv.initQuaggaScriptLoad();
        rv.generator = genApi;
        //rv.scanner = scanApi;
        return rv;
    }
    initGenScriptLoad() {
        return new Promise((resolve, reject) => {
            const script = document.createElement("script");
            document.body.appendChild(script);
            script.onload = resolve;
            script.onerror = reject;
            script.async = true;
            script.src = "/lib/qrcode-generator@1.4.3.js";
        });
    }
    initScanScriptLoad() {
        return new Promise((resolve, reject) => {
            const script = document.createElement("script");
            document.body.appendChild(script);
            script.type = "module";
            script.onload = resolve;
            script.onerror = reject;
            script.async = true;
            script.src = "/lib/qr-scanner@1.1.1.js";
        });
    }
    initQuaggaScriptLoad() {
        return new Promise((resolve, reject) => {
            const script = document.createElement("script");
            document.body.appendChild(script);
            //script.type = "module"; module does not work for quagga!
            script.onload = resolve;
            script.onerror = reject;
            script.async = true;
            script.src = "/lib/quagga@0.12.1.js";
        });
    }
}
