import * as React from 'react';
import { DOMCamera, VideoRecorder, DOMCameraStateEnum } from '@metaexplorer/core';
import { Fab } from '@material-ui/core';
import Stop from '@material-ui/icons/Stop';
import CameraOff from '@material-ui/icons/VideocamOff';
import Camera from '@material-ui/icons/Videocam';
const cssClasses = {
    controlsContainer: "controls-container"
};
export class MDVideoRecorder extends DOMCamera {
    constructor() {
        super(...arguments);
        this.videoRecorder = new VideoRecorder();
    }
    startVideoRecording() {
        this.videoRecorder.startRecording(this.getStream());
        super.startVideoRecording();
    }
    stopVideoRecording() {
        const videoBlob = this.videoRecorder.stopRecording();
        this.getStream().getTracks().forEach((track) => {
            track.stop();
        });
        if (this.props.onVideoSrcReady)
            this.props.onVideoSrcReady(window.URL.createObjectURL(videoBlob));
        super.stopVideoRecording();
    }
    pauseVideoRecording() {
        if (this.videoRecorder.getRecordingState() === "recording") {
            this.videoRecorder.stopRecording();
            super.pauseVideoRecording();
        }
        else {
            this.videoRecorder.startRecording(this.getStream());
        }
    }
    onFabClick() {
        const { curStep } = this.state;
        if (curStep !== DOMCameraStateEnum.isVideoing)
            return;
        switch (this.videoRecorder.getRecordingState()) {
            case 'inactive':
                this.startVideoRecording();
                return;
            case 'recording':
                this.stopVideoRecording();
                return;
            default:
                break;
        }
    }
    getFabIcon() {
        const { curStep } = this.state;
        const { isRecording } = this.props;
        if (curStep !== DOMCameraStateEnum.isVideoing)
            return React.createElement(CameraOff, null);
        if (isRecording) {
            return React.createElement(Stop, null);
        }
        else {
            return React.createElement(Camera, null);
        }
    }
    renderControls() {
        const { curStep } = this.state;
        return React.createElement("div", { className: cssClasses.controlsContainer },
            React.createElement(Fab, { color: "secondary", disabled: curStep !== DOMCameraStateEnum.isVideoing, onClick: () => this.onFabClick() }, this.getFabIcon()));
    }
}
