import { ldBlueprint, UserDefDict, VisualKeysDict, gdsfpLD, generateItptFromCompInfo, initLDLocalState } from '@metaexplorer/core';
import { Component } from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core';
import React from 'react';
import * as darkThemeJson from '../../../themes/metaexplorer-mui-theme-dark.json';
import * as lightThemeJson from '../../../themes/metaexplorer-mui-theme-light.json';
/*import { ThemeProvider } from 'react-css-themr';
import { editorTheme } from 'styles/editor/editorTheme';
import { appTheme } from 'styles/appTheme/appTheme';*/
export const ThemeProviderDarkName = "metaexplorer.io/material-design/ThemeProvider-dark";
export const ThemeProviderLightName = "metaexplorer.io/material-design/ThemeProvider-light";
let cfgIntrprtKeys = [VisualKeysDict.inputContainer];
let ownKVLs = [
    {
        key: VisualKeysDict.inputContainer,
        value: undefined,
        ldType: UserDefDict.intrprtrClassType
    }
];
let darkBpCfg = {
    subItptOf: null,
    nameSelf: ThemeProviderDarkName,
    ownKVLs: ownKVLs,
    inKeys: cfgIntrprtKeys,
    crudSkills: "cRud"
};
let lightBpCfg = {
    subItptOf: null,
    nameSelf: ThemeProviderLightName,
    ownKVLs: ownKVLs,
    inKeys: cfgIntrprtKeys,
    crudSkills: "cRud"
};
const lightPalette = {
    primary: { main: '#FAFAFA', contrastText: '#2b2b2b' },
    secondary: { main: '#86c5f2', contrastText: '#ffffff' }
};
//const themeNameLight = 'MetaExplorer Material-UI Theme light';
const lightTheme = createMuiTheme({ ...lightThemeJson, palette: lightPalette });
const darkPalette = {
    primary: { main: '#00375f' },
    secondary: { main: '#86c5f2', contrastText: '#00375f' }
};
//const themeNameDark = 'MetaExplorer Material-UI Theme dark';
const darkTheme = createMuiTheme({
    ...darkThemeJson,
    palette: darkPalette
});
class PureThemeProviderDark extends Component {
    constructor(props) {
        super(props);
        this.renderInputContainer = generateItptFromCompInfo.bind(this, VisualKeysDict.inputContainer);
        this.cfg = this.constructor["cfg"];
        const ldState = initLDLocalState(this.cfg, props, [VisualKeysDict.inputContainer], []);
        this.state = {
            ...ldState,
        };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        let rvLD = gdsfpLD(nextProps, prevState, [VisualKeysDict.inputContainer], []);
        if (!rvLD) {
            return null;
        }
        let rvNew = { ...rvLD };
        return {
            ...rvNew
        };
    }
    render() {
        let renderFreeResult = this.renderInputContainer();
        if (!renderFreeResult)
            return null;
        return React.createElement(ThemeProvider, { theme: darkTheme }, renderFreeResult);
    }
}
class PureThemeProviderLight extends PureThemeProviderDark {
    render() {
        let renderFreeResult = this.renderInputContainer();
        if (!renderFreeResult)
            return null;
        return React.createElement(ThemeProvider, { theme: lightTheme }, renderFreeResult);
    }
}
export const ThemeProviderDark = ldBlueprint(darkBpCfg)(PureThemeProviderDark);
export const ThemeProviderLight = ldBlueprint(lightBpCfg)(PureThemeProviderLight);
