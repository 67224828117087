import { UserDefDict } from "../ldaccess/UserDefDict";
import { isObjPropertyRef, isOutputKVSame } from "../ldaccess/ldUtils";
import { getApplicationStore } from "../approot";
import { dispatchKvUpdateAction } from "../appstate/epicducks/ldOptions-duck";
import { LDError } from "../appstate/LDError";
export const AbstractDataTransformerItptKeys = [];
export const AbstractDataTransformerOutputKVs = [];
/**
 * abstract class to implement a data flow. Should simplify the setup to
 * the following steps:
 * a) set input Itpt-Keys in the constructor
 * b) set an Array of your output-KVLs in the constructor
 * c) override this.mappingFunction(...)
 */
export class AbstractDataTransformer {
    constructor(ldTkStr) {
        this.inputParams = new Map();
        this.isInputDirty = false;
        this.isOutputDirty = false;
        this.consumeLDOptions = (ldOptions) => {
            if (!ldOptions || !ldOptions.resource || !ldOptions.resource.kvStores)
                return;
            this.ldTkStr = ldOptions.ldToken.get();
            let kvs = ldOptions.resource.kvStores;
            let outputKVMap = kvs.find((val) => UserDefDict.outputKVMapKey === val.key);
            outputKVMap = outputKVMap ? outputKVMap : this.cfg.ownKVLs.find((val) => UserDefDict.outputKVMapKey === val.key);
            this.setOutputKVMap(outputKVMap && outputKVMap.value ? outputKVMap.value : this.outputKVMap);
            for (let inputidx = 0; inputidx < this.itptKeys.length; inputidx++) {
                const inputKey = this.itptKeys[inputidx];
                let param = kvs.find((val) => (val.key === inputKey && !isObjPropertyRef(val.value)));
                if (param && param.value !== null
                    && JSON.stringify(param) !== JSON.stringify(this.inputParams.get(inputKey))) {
                    this.inputParams.set(inputKey, param);
                    this.isInputDirty = true;
                }
            }
            this.evalDirtyInput();
            this.evalDirtyOutput();
        };
        this.setOutputKVMap = (value) => {
            if (!isOutputKVSame(value, this.outputKVMap))
                this.isOutputDirty = true;
            this.outputKVMap = value;
        };
        this.evalDirtyOutput = () => {
            if (this.isInputDirty)
                return;
            if (this.isOutputDirty && this.outputKVMap) {
                this.isOutputDirty = false;
                this.refreshOutput();
            }
        };
        this.evalDirtyInput = () => {
            if (this.isInputDirty) {
                this.isInputDirty = false;
                this.isOutputDirty = true;
            }
        };
        this.cfg = this.constructor["cfg"];
        this.ldTkStr = ldTkStr;
        this.itptKeys = AbstractDataTransformerItptKeys;
        this.outputKvStores = AbstractDataTransformerOutputKVs;
        const outputKVMap = this.cfg.ownKVLs.find((val) => UserDefDict.outputKVMapKey === val.key);
        this.setOutputKVMap(outputKVMap && outputKVMap.value ? outputKVMap.value : null);
    }
    mappingFunction(inputParams, outputKvStores) {
        let rv = [];
        return rv;
    }
    refreshOutput() {
        let newOutputKvStores = new Map();
        this.outputKvStores.
            forEach((kvStore) => {
            newOutputKvStores.set(kvStore.key, { key: kvStore.key, value: kvStore.value, ldType: kvStore.ldType });
        });
        const changedKvStores = this.mappingFunction(this.inputParams, newOutputKvStores);
        const thisLdTkStr = this.ldTkStr;
        if (!thisLdTkStr) {
            throw new LDError("ldTkStr hasn't been set, can't refresh output");
        }
        else {
            const updatedKvMap = this.outputKVMap;
            getApplicationStore().dispatch(dispatchKvUpdateAction(changedKvStores, thisLdTkStr, updatedKvMap));
        }
    }
}
