import { ApolloClient, InMemoryCache, HttpLink, } from "@apollo/client";
export class WPGraphQLAPI {
    static getAPISingleton(cfg) {
        if (WPGraphQLAPI.apiSingleton == null) {
            WPGraphQLAPI.apiSingleton = WPGraphQLAPI.init(cfg);
        }
        return WPGraphQLAPI.apiSingleton;
    }
    static isInitialized() {
        return !!WPGraphQLAPI.apiSingleton;
    }
    static init(cfg) {
        const rv = new WPGraphQLAPI();
        rv.apiClient = new ApolloClient({
            //graphql endpoint
            link: new HttpLink({ uri: cfg && cfg.uri ? cfg.uri : "/graphql" }),
            cache: new InMemoryCache(),
        });
        return rv;
    }
    getClient() {
        return this.apiClient;
    }
    // class methods
    setConfig(cfg) {
        if (cfg) {
            this.apiClient.setLink(new HttpLink({ uri: cfg.uri ? cfg.uri : "/graphql" }));
        }
    }
}
