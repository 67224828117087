export const DEFAULT_ITPT_RETRIEVER_NAME = "default";
export class DefaultItptRetriever {
    constructor(name = DEFAULT_ITPT_RETRIEVER_NAME) {
        this.name = DEFAULT_ITPT_RETRIEVER_NAME;
        this.itptCollection = [];
        this.derivedItptMap = new Map();
        this.name = name;
    }
    searchForObjItpt(term, crudSkills) {
        if (term === null)
            throw new Error("search term undefined");
        if (typeof term === "string") {
            return this.searchSingleObjItpt(term, crudSkills);
        }
        let multipleItpts = new Array();
        term.forEach((elem) => {
            multipleItpts.push(this.searchSingleObjItpt(elem, crudSkills));
        });
        if (multipleItpts.length === 0)
            return null;
        return multipleItpts;
    }
    searchForKVItpt(term, crudSkills) {
        throw new Error("Method not implemented.");
    }
    addItpt(typeName, itpt, crudSkills, tags) {
        let preExistingIdx = this.itptCollection.findIndex((curItm) => curItm.itpt["nameSelf"] === itpt["nameSelf"] && curItm.canInterpretType === typeName);
        if (itpt["cfg"]) {
            let cfg = itpt["cfg"];
            let newItm = {
                canInterpretType: typeName,
                subItptOf: null,
                nameSelf: cfg.nameSelf,
                itpt: itpt,
                crudSkills: crudSkills,
                baseType: this.getBaseTypeFromType(typeName),
                additionalTypes: this.getAdditionalTypesFromType(typeName),
                tags
            };
            if (preExistingIdx >= 0) {
                this.itptCollection[preExistingIdx] = newItm;
            }
            else {
                this.itptCollection.push(newItm);
            }
        }
    }
    getItptList() {
        return this.itptCollection;
    }
    getItptByNameSelf(nameSelf) {
        let candidates = this.itptCollection.filter((curItm) => curItm.nameSelf === nameSelf);
        if (candidates == null)
            return null;
        if (candidates.length === 1)
            return candidates[0].itpt;
        return null;
    }
    setDerivedItpt(ldTokenVal, itpt) {
        if (!itpt) {
            this.derivedItptMap.delete(ldTokenVal);
            return;
        }
        this.derivedItptMap.set(ldTokenVal, itpt);
    }
    hasDerivedItpt(ldTokenVal) {
        return this.derivedItptMap.has(ldTokenVal);
    }
    getDerivedItpt(ldTokenVal) {
        if (this.hasDerivedItpt(ldTokenVal)) {
            return this.derivedItptMap.get(ldTokenVal);
        }
        else {
            return null;
        }
    }
    /**
     * will combine two crudSkills by choosing the most permissive skills
     * @param crudSkillsA
     * @param crudSkillsB
     */
    /*	private extendCrudSkills(crudSkillsA: string, crudSkillsB: string): string {
            if (crudSkillsA === crudSkillsB) return crudSkillsA;
            let rv: string = "";
            for (var i = 0; i < crudSkillsA.length; i++) {
                let a = crudSkillsA[i];
                let b = crudSkillsB[i];
                rv += a < b ? a : b;
            }
            return rv;
        }*/
    searchSingleObjItpt(term, crudSkills) {
        let searchBaseType = this.getBaseTypeFromType(term);
        //let searchAdditionalTypes: string[] = this.getAdditionalTypesFromType(term);
        let candidates = this.itptCollection.filter((curItm) => curItm.baseType === searchBaseType);
        if (candidates == null)
            return null;
        if (candidates.length === 1)
            return candidates[0].itpt;
        let candidatesMatch2 = candidates.filter((curItm) => curItm.crudSkills === crudSkills);
        if (candidatesMatch2 !== null || candidatesMatch2.length > 0)
            candidates = candidatesMatch2;
        if (candidates.length === 1)
            return candidates[0].itpt;
        return null;
        //TODO: perform a mapping against the additionalTypes
    }
    getBaseTypeFromType(rawType) {
        return rawType;
    }
    getAdditionalTypesFromType(rawType) {
        return null;
    }
}
