import { Component } from 'react';
import { LDDict } from '../../../ldaccess/LDDict';
import { UserDefDict } from '../../../ldaccess/UserDefDict';
import { initLDLocalState, gdsfpLD } from '../../generic/generatorFns';
import { createLDUINSUrl } from '../../../ldaccess/ldUtils';
// TODO: drop file anim: https://css-tricks.com/examples/DragAndDropFileUploading/
export var SingleImageSelectorStateEnum;
(function (SingleImageSelectorStateEnum) {
    SingleImageSelectorStateEnum[SingleImageSelectorStateEnum["isSelectInputType"] = 2] = "isSelectInputType";
    SingleImageSelectorStateEnum[SingleImageSelectorStateEnum["isDragging"] = 3] = "isDragging";
    SingleImageSelectorStateEnum[SingleImageSelectorStateEnum["isCamShooting"] = 4] = "isCamShooting";
    SingleImageSelectorStateEnum[SingleImageSelectorStateEnum["isPreviewing"] = 5] = "isPreviewing";
    SingleImageSelectorStateEnum[SingleImageSelectorStateEnum["isError"] = 6] = "isError";
})(SingleImageSelectorStateEnum || (SingleImageSelectorStateEnum = {}));
const SingleImageSelectorName = "metaexplorer.io/core/SingleImageSelector";
let cfgType = createLDUINSUrl(LDDict.CreateAction, LDDict.result, LDDict.ImageObject);
let cfgIntrprtKeys = [];
const RESULT_KV = {
    key: LDDict.result,
    value: undefined,
    ldType: LDDict.URL
};
let ownKVLs = [
    RESULT_KV
];
export let SingleImageSelectorBpCfg = {
    subItptOf: null,
    canInterpretType: cfgType,
    nameSelf: SingleImageSelectorName,
    ownKVLs: ownKVLs,
    inKeys: cfgIntrprtKeys,
    crudSkills: "cRud"
};
export class AbstractSingleImageSelector extends Component {
    constructor(props) {
        super(props);
        this.loadingImgLink = "/media/camera_negative_black.svg";
        this.errorImgLink = "/media/nocamera_negative_black.svg";
        this.draggingImgLink = "/media/dragndrop.svg";
        // tslint:disable-next-line:variable-name
        this._isMounted = false;
        this.consumeLDOptions = (ldOptions) => {
            /*if (ldOptions && ldOptions.resource && ldOptions.resource.kvStores) {
                let kvs = ldOptions.resource.kvStores;
                this.imgLink = getKVValue(getKVStoreByKey(kvs, LDDict.contentUrl));
            }*/
        };
        this.cfg = this.constructor["cfg"];
        const baseState = {
            curStep: SingleImageSelectorStateEnum.isSelectInputType,
            isCamAvailable: false,
            previewURL: null
        };
        this.state = {
            ...baseState,
            ...initLDLocalState(this.cfg, props, [], [UserDefDict.outputKVMapKey])
        };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        let rvLD = gdsfpLD(nextProps, prevState, [], [UserDefDict.outputKVMapKey]);
        if (rvLD === null || rvLD === prevState)
            return null;
        return { ...prevState, ...rvLD };
    }
    /**
     * called when the URL/blob of the image URL changes, dispatches KV-Output
     */
    onResultChanged(url) {
        const outputKV = { ...RESULT_KV, value: url };
        const outputKVMap = this.state.localValues.get(UserDefDict.outputKVMapKey);
        if (!outputKVMap)
            return;
        this.props.dispatchKvOutput([outputKV], this.props.ldTokenString, outputKVMap);
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidMount() {
        this._isMounted = true;
        if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
            this.setState({ ...this.state, isCamAvailable: false });
            return;
        }
        else {
            navigator.mediaDevices.enumerateDevices()
                .then((devices) => {
                let vidInputList = [];
                devices.forEach((device) => {
                    if (device.kind === "videoinput")
                        vidInputList.push(device);
                });
                if (this._isMounted) {
                    if (vidInputList.length === 0) {
                        this.setState({ ...this.state, isCamAvailable: false });
                    }
                    else {
                        this.setState({ ...this.state, isCamAvailable: true });
                    }
                }
            });
        }
    }
    setStateToError() {
        this.setState({ ...this.state, curStep: SingleImageSelectorStateEnum.isError });
    }
    startDrag() {
        if (this.state.curStep !== SingleImageSelectorStateEnum.isPreviewing) {
            this.setState({ ...this.state, curStep: SingleImageSelectorStateEnum.isDragging });
        }
    }
    onDropSuccess(imgFile, previewURL) {
        this.setState({ ...this.state, curStep: SingleImageSelectorStateEnum.isPreviewing, previewURL: previewURL });
        this.onResultChanged(previewURL);
    }
    onDropFailure() {
        if (this.state.curStep !== SingleImageSelectorStateEnum.isPreviewing) {
            this.setState({ ...this.state, curStep: SingleImageSelectorStateEnum.isSelectInputType });
        }
    }
    startCamera() {
        this.setState({ ...this.state, curStep: SingleImageSelectorStateEnum.isCamShooting });
    }
    destroyPreview() {
        if (this.state.previewURL) {
            window.URL.revokeObjectURL(this.state.previewURL);
        }
    }
    render() {
        throw new Error("Method not implemented in abstract class");
    }
}
