import { createStore, applyMiddleware, compose } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { rootEpic, rootReducer } from './rootduck';
import { ImageUploadAPI } from '../apis/image-upload';
import { LDOptionsAPI } from '../apis/ldoptions-api';
import { ModAPI } from '../apis/mod-api';
const imgUploader = new ImageUploadAPI();
const ldOptionsAPI = new LDOptionsAPI();
export const modAPI = new ModAPI();
const epicMiddleware = createEpicMiddleware({
    dependencies: {
        imgULAPI: imgUploader,
        ldOptionsAPI,
        modAPI
    }
});
export const isProduction = process.env.NODE_ENV === 'production';
export const isDemo = process.env.METAEXPLORER_MODE === 'demo';
export const isStateDebug = process.env.METAEXPLORER_MODE === 'statedebug';
let composeEnhancers = null;
if (typeof window !== "undefined") {
    const chromeReduxDevtools = window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'];
    composeEnhancers = chromeReduxDevtools ? chromeReduxDevtools : compose;
}
else {
    composeEnhancers = compose;
}
let middleWare = composeEnhancers(applyMiddleware(epicMiddleware)); // /*isProduction ?*/ applyMiddleware(epicMiddleware); /* :
export function configureStore(initialState) {
    const store = createStore(rootReducer, initialState, middleWare);
    epicMiddleware.run(rootEpic);
    return store;
}
