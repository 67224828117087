import React from 'react';
import { VisualKeysDict, VisualTypesDict } from '../../visualcomposition/visualDict';
import { initLDLocalState, gdsfpLD, generateItptFromCompInfo } from '../../generic/generatorFns';
import { Redirect } from 'react-router';
import { Component } from 'react';
import { cleanRouteString } from '../../routing/route-helper-fns';
import { LDDict } from '../../../ldaccess/LDDict';
import { UserDefDict } from '../../../ldaccess/UserDefDict';
export const NavBarWActionsName = "metaexplorer.io/material-design/NavBarWActions";
let cfgIntrprtItptKeys = [
    VisualKeysDict.inputContainer,
    VisualKeysDict.popOverContent
];
let cfgIntrprtValueKeys = [
    VisualKeysDict.headerTxt,
    VisualKeysDict.routeSend_search,
    VisualKeysDict.iconName,
    VisualKeysDict.routeSend_cancel,
    VisualKeysDict.cssClassName
];
let cfgIntrprtKeys = [...cfgIntrprtItptKeys, ...cfgIntrprtValueKeys];
let ownKVLs = [
    {
        key: VisualKeysDict.inputContainer,
        value: undefined,
        ldType: UserDefDict.intrprtrClassType
    },
    {
        key: VisualKeysDict.headerTxt,
        value: undefined,
        ldType: LDDict.Text
    },
    {
        key: VisualKeysDict.routeSend_search,
        value: undefined,
        ldType: VisualTypesDict.route_added,
    },
    {
        key: VisualKeysDict.popOverContent,
        value: undefined,
        ldType: UserDefDict.intrprtrClassType
    },
    {
        key: VisualKeysDict.iconName,
        value: undefined,
        ldType: LDDict.Text
    },
    {
        key: VisualKeysDict.routeSend_cancel,
        value: undefined,
        ldType: VisualTypesDict.route_added,
    },
    {
        key: VisualKeysDict.cssClassName,
        value: undefined,
        ldType: LDDict.Text
    }
];
export const NavBarWActionsBpCfg = {
    subItptOf: null,
    nameSelf: NavBarWActionsName,
    ownKVLs: ownKVLs,
    inKeys: cfgIntrprtKeys,
    crudSkills: "cRud"
};
export class AbstractNavBarWActions extends Component {
    constructor(props, stateExtensionInit) {
        super(props);
        this.renderSub = generateItptFromCompInfo.bind(this);
        this.onAppBarRightIconMenuClick = () => {
            //will be called on opening and clicking inside the menu
        };
        this.onAppBarSearchBtnClick = () => {
            this.setState({
                ...this.state,
                isDoRedirect: true,
                isRightMenuOpen: false
            });
        };
        this.onCancelClick = () => {
            this.setState({
                ...this.state,
                isDoRedirectCancel: true,
                isRightMenuOpen: false
            });
        };
        this.cfg = this.constructor["cfg"];
        let navBarStatePart = {
            isDoRedirect: false,
            isDoRedirectCancel: false,
            isRightMenuOpen: false,
        };
        this.state = {
            ...stateExtensionInit,
            ...navBarStatePart,
            ...initLDLocalState(this.cfg, props, cfgIntrprtItptKeys, cfgIntrprtValueKeys)
        };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        let rvLD = gdsfpLD(nextProps, prevState, cfgIntrprtItptKeys, cfgIntrprtValueKeys);
        if (!rvLD) {
            return null;
        }
        return {
            ...prevState, ...rvLD
        };
    }
    render() {
        const { isDoRedirect, isDoRedirectCancel, localValues } = this.state;
        const routeSendSearch = localValues.get(VisualKeysDict.routeSend_search);
        let routeSendCancel = localValues.get(VisualKeysDict.routeSend_cancel);
        if (isDoRedirectCancel && routeSendCancel) {
            routeSendCancel = cleanRouteString(routeSendCancel, this.props.routes);
            this.setState({ ...this.state, isDoRedirect: false, isDoRedirectCancel: false });
            return React.createElement(Redirect, { to: routeSendCancel });
        }
        if (isDoRedirect && routeSendSearch) {
            let route = cleanRouteString(routeSendSearch, this.props.routes);
            //if (match.params.nextPath === undefined) match.params.nextPath = route;
            this.setState({ ...this.state, isDoRedirect: false, isDoRedirectCancel: false });
            return React.createElement(Redirect, { to: route });
        }
        return this.renderCore();
    }
    renderCore() {
        throw new Error("Method not implemented in abstract class");
    }
}
