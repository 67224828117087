import { UserDefDict } from "../ldaccess/UserDefDict";
import { SideFXDict } from "../sidefx/SideFXDict";
import { ldOptionsRequestAction, ldOptionsClientSideUpdateAction } from "../appstate/epicducks/ldOptions-duck";
import { getApplicationStore } from "../approot";
import { isOutputKVSame, ldOptionsDeepCopy, isObjPropertyRef } from "../ldaccess/ldUtils";
import { getKVStoreByKey } from "../ldaccess/kvConvenienceFns";
import { nameSpaceMap } from "../ldaccess/ns/nameSpaceMap";
import { ldRetrCfgIntrprtKeys } from "./LDRetrieverSuper-rewrite";
//export let ldRetrCfgIntrprtKeys: string[] =
//	[SideFXDict.srvURL, SideFXDict.identifier];
export class LDRetrieverSuper {
    constructor() {
        //srvUrl: string;
        //identifier: string | number;
        this.isInputDirty = false;
        this.isOutputDirty = false;
        this.apiCallOverride = null;
        this.consumeLDOptions = (ldOptions) => {
            if (!ldOptions || !ldOptions.resource || !ldOptions.resource.kvStores)
                return;
            this.retrieverStoreKey = ldOptions.ldToken.get();
            let kvs = ldOptions.resource.kvStores;
            let outputKVMap = kvs.find((val) => UserDefDict.outputKVMapKey === val.key);
            outputKVMap = outputKVMap ? outputKVMap : this.cfg.ownKVLs.find((val) => UserDefDict.outputKVMapKey === val.key);
            this.setOutputKVMap(outputKVMap && outputKVMap.value ? outputKVMap.value : this.outputKVMap);
            for (let idx = 0; idx < ldRetrCfgIntrprtKeys.length; idx++) {
                const inputKey = ldRetrCfgIntrprtKeys[idx];
                let param = kvs.find((val) => val.key === inputKey);
                if (param && param.value !== null) {
                    this.inputParams.set(inputKey, param);
                    this.isInputDirty = true;
                }
            }
            //this.setSrvUrl(srvUrlKv && srvUrlKv.value ? srvUrlKv.value : this.srvUrl);
            //this.setIdentifier(identifier && identifier.value !== null ? identifier : this.identifier);
            this.setWebContent(ldOptions);
            this.evalDirtyInput();
            this.evalDirtyOutput();
        };
        this.setOutputKVMap = (value) => {
            if (!isOutputKVSame(value, this.outputKVMap))
                this.isOutputDirty = true;
            this.outputKVMap = value;
        };
        /*setIdentifier = (value: KVL | string | number) => {
            if (getKVValue(value) !== this.identifier) this.isInputDirty = true;
            this.identifier = getKVValue(value);
        }
        setSrvUrl = (value: string) => {
            if (value !== this.srvUrl) this.isInputDirty = true;
            this.srvUrl = value;
        }*/
        this.setWebContent = (value) => {
            if (value.isLoading)
                return;
            if (value.resource.webInResource && value.resource.webInResource !== this.webContent) {
                this.webContent = value.resource.webInResource;
                this.isOutputDirty = true;
            }
        };
        this.evalDirtyOutput = () => {
            if (this.isInputDirty)
                return;
            if (this.isOutputDirty && this.outputKVMap && this.webContent) {
                this.isOutputDirty = false;
                this.refreshOutput();
            }
        };
        this.evalDirtyInput = () => {
            if (this.isInputDirty) {
                if (!this.apiCallOverride) {
                    //if it's an jsonld-request
                    let srvUrl = this.inputParams.get(ldRetrCfgIntrprtKeys[0]);
                    let identifier = this.inputParams.get(ldRetrCfgIntrprtKeys[1]);
                    if (srvUrl && srvUrl.value && srvUrl.value.length > 0
                        && identifier
                        && identifier.value !== null && identifier.value !== undefined) {
                        this.isInputDirty = false;
                        let idStr = identifier.toString();
                        let idSplitIdx = idStr.indexOf('/');
                        let requestURL;
                        if (idSplitIdx !== -1) {
                            let nsMHasValue = false;
                            let nsMSearchVal = idStr.slice(0, idSplitIdx);
                            for (const nsMEntry of nameSpaceMap.values()) {
                                if (nsMEntry === nsMSearchVal) {
                                    nsMHasValue = true;
                                    break;
                                }
                            }
                            if (nsMHasValue) {
                                let idNS = idStr.slice(0, idSplitIdx);
                                let idId = idStr.slice(idSplitIdx + 1, idStr.length);
                                /*let reqSplitString = srvUrl.value.replace('{' + SideFXDict.identifier + '}',
                                    '{namespace}/' + '{' + SideFXDict.identifier + '}');
                                requestURL = URI.expand(reqSplitString, {
                                    namespace: idNS,
                                    identifier: idId
                                });
                                requestURL = srvUrl.value;*/
                                requestURL = requestURL.replace('{' + SideFXDict.identifier + '}', idNS + '/' + idId);
                            }
                            else {
                                //TODO: enter error state
                                return;
                            }
                        }
                        else {
                            const idVal = identifier.value;
                            if (!idVal || isObjPropertyRef(idVal)) {
                                return;
                            }
                            requestURL = srvUrl.value + idVal;
                            /*let test = URI;
                            requestURL = test.expand(srvUrl.value, {
                                identifier: identifier.value
                            });*/
                        }
                        this.isInputDirty = false;
                        let reqAsString = requestURL; // requestURL.valueOf();
                        this.callToAPI(null, reqAsString, this.retrieverStoreKey);
                    }
                }
                else {
                    this.isInputDirty = false;
                    this.callToAPI(null, null, this.retrieverStoreKey);
                }
            }
        };
        this.cfg = this.constructor["cfg"];
        this.inputParams = new Map();
        //this.retrieverStoreKey = this.cfg.nameSelf;
        /*if (this.cfg.ownKVLs) {
            let extRefKey = this.cfg.ownKVLs.find(
                (val) => val.key === UserDefDict.externalReferenceKey);
            this.retrieverStoreKey = extRefKey.value ? extRefKey.value : this.retrieverStoreKey;
        }*/
    }
    callToAPI(uploadData, targetUrl, targetReceiverLnk) {
        getApplicationStore().dispatch(ldOptionsRequestAction(this.apiCallOverride, uploadData, targetUrl, targetReceiverLnk));
    }
    refreshOutput() {
        let okvmPNs = Object.getOwnPropertyNames(this.outputKVMap);
        let webObj = this.webContent;
        let statePart = {};
        okvmPNs.forEach((pn) => {
            let fillValue = webObj[pn];
            let outputElems = this.outputKVMap[pn];
            for (let i = 0; i < outputElems.length; i++) {
                const outputElem = outputElems[i];
                let targetTokenLnk = outputElem.targetLDToken.get();
                let targetProp = outputElem.targetProperty;
                let newLDOptions;
                if (statePart[targetTokenLnk]) {
                    newLDOptions = statePart[targetTokenLnk];
                }
                else {
                    newLDOptions = getApplicationStore().getState().ldoptionsMap[targetTokenLnk];
                    newLDOptions = ldOptionsDeepCopy(newLDOptions);
                    statePart[targetTokenLnk] = newLDOptions;
                }
                let targetKVStore = getKVStoreByKey(newLDOptions.resource.kvStores, targetProp);
                if (targetKVStore) {
                    targetKVStore.value = fillValue;
                    targetKVStore.ldType = null;
                }
                else {
                    targetKVStore = { key: targetProp, value: fillValue, ldType: null };
                    newLDOptions.resource.kvStores.push(targetKVStore);
                }
            }
        });
        for (const key in statePart) {
            if (statePart.hasOwnProperty(key)) {
                const element = statePart[key];
                getApplicationStore().dispatch(ldOptionsClientSideUpdateAction(element));
            }
        }
    }
}
