import * as React from 'react';
import { TransitComponent } from 'metaexplorer-react-components';
import MultiBackend from 'react-dnd-multi-backend';
//those are options:
import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch';
//import HTML5Backend from 'react-dnd-html5-backend';
//import TouchBackend from 'react-dnd-touch-backend';
import { DndProvider } from 'react-dnd';
const DNDBackend = MultiBackend;
const TRANSIT_CLASS = 'editor-transit';
export function DNDEnabler(props) {
    const transitCompProps = {
        className: TRANSIT_CLASS,
        transitComponents: props.transitComponents
    };
    return (React.createElement("div", { className: props.className },
        React.createElement(DndProvider, { backend: DNDBackend, options: HTML5toTouch },
            React.createElement("div", { className: `${props.className}-inner` },
                props.children,
                React.createElement(TransitComponent, Object.assign({}, transitCompProps))))));
}
