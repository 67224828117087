import { LDPortModel } from "./LDPortModel";
import { UserDefDict } from "@metaexplorer/core";
import { LD_PORTMODEL, LINK_SETTINGS_MODEL } from "../node-editor-consts";
import { AbstractModelFactory } from "@projectstorm/react-canvas-core";
export class LDPortInstanceFactory extends AbstractModelFactory {
    constructor() {
        super(LD_PORTMODEL);
    }
    getNewInstance() {
        var baseDataTypeKVStore = {
            key: UserDefDict.outputSelfKey,
            value: undefined,
            ldType: undefined
        };
        return new LDPortModel({
            in: true,
            name: "unknown",
            kv: baseDataTypeKVStore
        });
    }
    generateModel(event) {
        return this.getNewInstance();
    }
    createLinkModel() {
        return this.engine.getLinkFactories().getFactory(LINK_SETTINGS_MODEL).generateModel({});
    }
}
