import React, { Component } from 'react';
export var DOMMicrophoneErrorTypes;
(function (DOMMicrophoneErrorTypes) {
    DOMMicrophoneErrorTypes[DOMMicrophoneErrorTypes["streamError"] = 2] = "streamError";
    DOMMicrophoneErrorTypes[DOMMicrophoneErrorTypes["mediaDevicesAccessFail"] = 3] = "mediaDevicesAccessFail";
    DOMMicrophoneErrorTypes[DOMMicrophoneErrorTypes["noVideoInputs"] = 4] = "noVideoInputs";
    DOMMicrophoneErrorTypes[DOMMicrophoneErrorTypes["enumerateDevicesFail"] = 5] = "enumerateDevicesFail";
})(DOMMicrophoneErrorTypes || (DOMMicrophoneErrorTypes = {}));
export var DOMMicrophoneStateEnum;
(function (DOMMicrophoneStateEnum) {
    DOMMicrophoneStateEnum[DOMMicrophoneStateEnum["isLoading"] = 2] = "isLoading";
    DOMMicrophoneStateEnum[DOMMicrophoneStateEnum["isError"] = 3] = "isError";
    DOMMicrophoneStateEnum[DOMMicrophoneStateEnum["isListening"] = 4] = "isListening";
})(DOMMicrophoneStateEnum || (DOMMicrophoneStateEnum = {}));
export class DOMMicrophone extends Component {
    constructor(props) {
        super(props);
        this.state = { curStep: DOMMicrophoneStateEnum.isLoading, audioInputDeviceList: null, curId: null };
    }
    getStream() {
        return this.stream;
    }
    startStream(strDeviceId) {
        if (!this.audioVisualization || !strDeviceId)
            return;
        navigator.mediaDevices.getUserMedia({ audio: { deviceId: strDeviceId } })
            .then((stream) => {
            if (!this.audioVisualization) {
                this.stream.getTracks().forEach((track) => {
                    track.stop();
                });
                return;
            }
            this.stream = stream;
        })
            .catch(() => {
            this.setStateToError(DOMMicrophoneErrorTypes.streamError);
            return;
        });
    }
    componentWillUnmount() {
        if (this.state.curStep !== DOMMicrophoneStateEnum.isError)
            this.setState({ curStep: DOMMicrophoneStateEnum.isLoading, audioInputDeviceList: null, curId: null });
        if (this.stream && this.stream.active) {
            this.stream.getTracks().forEach((track) => {
                track.stop();
            });
        }
        if (this.props.onAudioVisualizationRemoved) {
            this.props.onAudioVisualizationRemoved();
        }
        this.audioVisualization = null;
    }
    setStateToError(errorType) {
        this.setState({ ...this.state, curStep: DOMMicrophoneStateEnum.isError });
        if (this.props.onAudioVisualizationRemoved) {
            this.props.onAudioVisualizationRemoved();
        }
        if (this.props.onError) {
            this.props.onError(errorType);
        }
    }
    componentDidMount() {
        if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
            this.setStateToError(DOMMicrophoneErrorTypes.mediaDevicesAccessFail);
            return;
        }
        navigator.mediaDevices.enumerateDevices()
            .then((devices) => {
            let audioInputList = [];
            devices.forEach((device) => {
                if (device.kind === "audioinput")
                    audioInputList.push(device);
            });
            if (audioInputList.length === 0) {
                this.setStateToError(DOMMicrophoneErrorTypes.noVideoInputs);
                return;
            }
            else {
                const deviceId = audioInputList[0].deviceId;
                this.setState({
                    curId: deviceId,
                    curStep: DOMMicrophoneStateEnum.isListening,
                    audioInputDeviceList: audioInputList
                });
                this.startStream(deviceId);
                return;
            }
        })
            .catch(() => {
            this.setStateToError(DOMMicrophoneErrorTypes.enumerateDevicesFail);
            return;
        });
    }
    startAudioRecording() {
        console.log("started audio recording");
        if (this.props.onAudioRecordingStarted)
            this.props.onAudioRecordingStarted();
    }
    stopAudioRecording() {
        console.log("stopped audio recording");
        if (this.props.onAudioRecordingStopped)
            this.props.onAudioRecordingStopped();
    }
    pauseAudioRecording() {
        console.log("paused audio recording");
        if (this.props.onAudioRecordingPaused)
            this.props.onAudioRecordingPaused();
    }
    renderError() {
        return React.createElement("span", null, "error opening microphone");
    }
    renderLoading() {
        return React.createElement("span", null, "loading");
    }
    renderVisualization() {
        return React.createElement("canvas", { ref: (canvas) => {
                this.audioVisualization = canvas;
                //this.startStream(curId);
            } });
    }
    renderControls() {
        return React.createElement("div", { className: "controls-container" });
    }
    render() {
        const { curStep } = this.state;
        return (React.createElement("div", { className: "dom-microphone" }, (() => {
            switch (curStep) {
                case DOMMicrophoneStateEnum.isError:
                    return this.renderError();
                case DOMMicrophoneStateEnum.isLoading:
                    return this.renderLoading();
                case DOMMicrophoneStateEnum.isListening:
                    return React.createElement(React.Fragment, null,
                        this.renderVisualization(),
                        this.renderControls());
                default:
                    return null;
            }
        })()));
    }
}
