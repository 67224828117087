var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { appAnalyticsAPIFn } from "../../apis/analytics-api";
import { ldBlueprint } from "../../ldaccess/ldBlueprint";
import { LDDict } from "../../ldaccess/LDDict";
import { itptKeysFromInputKvs } from "../../ldaccess/ldUtils";
import { UserDefDict } from "../../ldaccess/UserDefDict";
import { LDRetrieverSuperRewrite } from "../../sidefx/LDRetrieverSuper-rewrite";
import { SideFXDict } from "../../sidefx/SideFXDict";
export const BeaconSenderName = "metaexplorer.io/analytics/BeaconSender";
export const BeaconSenderType = "metaexplorer.io/analytics/BeaconSenderType";
const TRIGGER = "trigger";
let inputKVStores = [
    {
        key: SideFXDict.srvURL,
        value: undefined,
        ldType: LDDict.URL,
    },
    {
        key: UserDefDict.inputData,
        value: undefined,
        ldType: undefined,
    },
    {
        key: TRIGGER,
        value: undefined,
        ldType: undefined,
    },
];
let outputKVStores = [];
let ownKVLs = [...inputKVStores, ...outputKVStores];
let inKeys = itptKeysFromInputKvs(inputKVStores);
let bpCfg = {
    subItptOf: null,
    nameSelf: BeaconSenderName,
    ownKVLs: ownKVLs,
    inKeys,
    crudSkills: "cRud",
    canInterpretType: BeaconSenderType,
};
let BeaconSender = class BeaconSender extends LDRetrieverSuperRewrite {
    constructor(parameters) {
        super(parameters, inKeys);
        this.lastStringified = "";
        this.apiCallOverride = () => new Promise((resolve, reject) => {
            let srvUrl = this.state.localValues.get(SideFXDict.srvURL);
            srvUrl = srvUrl ? srvUrl : "/api/log";
            const postBody = this.state.localValues.get(UserDefDict.inputData);
            appAnalyticsAPIFn(srvUrl).logBeacon(postBody);
        });
    }
    evalDirtyInput() {
        let triggerVal = this.state.localValues.get(TRIGGER);
        if (triggerVal) {
            const stringified = JSON.stringify(triggerVal);
            if (stringified === "{}" || stringified === this.lastStringified) {
                this.lastStringified = stringified;
                return;
            }
            this.lastStringified = stringified;
            this.setState({ ...this.state, isInputDirty: true });
            super.evalDirtyInput();
        }
    }
};
BeaconSender = __decorate([
    ldBlueprint(bpCfg)
], BeaconSender);
export { BeaconSender };
