var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LDDict } from '../../ldaccess/LDDict';
import { ldBlueprint } from '../../ldaccess/ldBlueprint';
import { Component } from 'react';
import { gdsfpLD, initLDLocalState } from '../generic/generatorFns';
import { VisualKeysDict } from './visualDict';
import React from 'react';
let cfgType = LDDict.ImageObject;
export const CSS_OBJECT_FIT = "CSSObjectFit";
let cfgIntrprtKeys = [LDDict.name, LDDict.fileFormat, LDDict.contentUrl, CSS_OBJECT_FIT, VisualKeysDict.cssClassName];
let ownKVLs = [];
let bpCfg = {
    subItptOf: null,
    canInterpretType: cfgType,
    nameSelf: "metaexplorer.io/imageDisplay",
    ownKVLs: ownKVLs,
    inKeys: cfgIntrprtKeys,
    crudSkills: "cRud"
};
let PureImgDisplay = class PureImgDisplay extends Component {
    constructor(props) {
        super(props);
        this.cfg = this.constructor["cfg"];
        this.state = initLDLocalState(this.cfg, props, [], [LDDict.name, LDDict.fileFormat, LDDict.contentUrl, CSS_OBJECT_FIT, VisualKeysDict.cssClassName]);
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        let rvLD = gdsfpLD(nextProps, prevState, [], [LDDict.name, LDDict.fileFormat, LDDict.contentUrl, CSS_OBJECT_FIT, VisualKeysDict.cssClassName], cfgType);
        if (!rvLD) {
            return null;
        }
        let rvNew = { ...rvLD, };
        return { ...rvNew };
    }
    render() {
        const { ldOptions } = this.props;
        const { localValues } = this.state;
        let objFit = localValues.get(CSS_OBJECT_FIT);
        objFit = objFit ? objFit : "unset";
        let cssClassName = localValues.get(VisualKeysDict.cssClassName);
        cssClassName = cssClassName ? cssClassName : "";
        let imgLnk = localValues.get(LDDict.contentUrl);
        if (!ldOptions)
            return React.createElement("div", null, "no Image data");
        return React.createElement("div", { className: "imgdisplay " + cssClassName },
            React.createElement("img", { alt: "", src: imgLnk, className: "is-loading", onLoad: (ev) => {
                    ev.currentTarget.classList.remove("is-loading");
                }, style: { objectFit: objFit } }));
    }
};
PureImgDisplay = __decorate([
    ldBlueprint(bpCfg)
], PureImgDisplay);
export { PureImgDisplay };
